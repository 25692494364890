import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAll(currentPage, itemsPerPage, filters) {
  return request('v1/crud/descontos', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters || {})
  })
}

export function fetchById(id) {
  return request(`v1/crud/descontos/${id}`)
}

export function add(formData) {
  return request(`v1/services/descontos`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function edit(formData) {
  return request(`v1/services/descontos/${formData.id}`, {
    method: 'PUT',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/crud/descontos/${id}`, {
    method: 'DELETE'
  })
}
export function fetchCourseClassByCourseIdValidSell(tag_curso) {
  return request(`v1/services/turmas/findAll`, {
    qsParams: {
      valid_sell: true,
      tag_curso,
    }
  })
}
