import config from '../config'
import store from '../redux/store'
import queryString from 'query-string'
import React from 'react'
import ApiErrors from '../components/layout/ApiErrors'

// const isLoggedIn = () => {
//   const state = store.getState()
//   return !!(state.account && state.account.user)
// }

const getAuthorization = () => {
  const state = store.getState()
  const token = state.auth && state.auth.token
  return token ? `Bearer ${token}` : void 0
}

const jsonBody = response => {
  try {
    return response.json()
  } catch (err) {
    console.warn('The server did not send a JSON response', err)
    return {}
  }
}

const request = async (path, options) => {
  options = Object.assign({}, options, {
    headers: {
      Authorization: getAuthorization()
    }
  })
  const { body, qsParams, filters } = options
  options.body = body
  options.headers = Object.assign(options.headers || {}, {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
  options.mode = 'cors'
  let qs = qsParams ? `?${queryString.stringify(qsParams, { arrayFormat: 'bracket' })}` : ''
  qs += filters ? filters : ''
  const response = await fetch(`${config.apiUrl}/${path}${qs}`, options)
  const json = await jsonBody(response)
  const createError = () => {
    //
    // login errors
    if (path === 'login') {
      return new Error(json.message)
    }

    if (json.error && Array.isArray(json.error) && json.error.length) {
      const error = {
        ...(<ApiErrors errors={json.error} />),
        message: (<ApiErrors errors={json.error} />)
      }

      throw error
    }

    return new Error(json.error || response.statusText || 'Unexpected error')
  }
  if (!response.ok) {
    throw new Error(createError())
  }
  return json
}


export default request
