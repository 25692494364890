require('dotenv').config()

// TODO: Load from .env file
const config = {
  apiUrl: process.env.REACT_APP_API_URL ?? 'https://api.academiarafaeltoro.com.br',
  s3Url: 'https://arquivos.academiarafaeltoro.com.br/',
  hiddenMenu: ['scoreToroV2'],
  currencyConfig: {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  }
}

export default config
