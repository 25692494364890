import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/discount'
import { constants } from '../modules/discount'
import { actions as discountActions } from '../modules/discount'

function* fetchDiscounts(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.DISCOUNT_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.DISCOUNT_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchDiscountsById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.DISCOUNT_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.DISCOUNT_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addDiscount(action) {
  try {
    const discountData = {
      ...action.formData,
      scope: 'admin',
      active: 1
    }

    //
    // notifications
    notification.info({
      key: 'discount_add',
      message: 'Adicionando desconto'
    })

    const payload = yield call(api.add, discountData)
    yield put({ type: constants.DISCOUNT_ADD.SUCCESS, payload })

    // notifications
    notification.close('discount_add')
    notification.success({
      message: 'Desconto adicionado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('discount_add')
    notification.error({
      message: 'Problemas ao adicionar o desconto',
      description: e.message || e
    })
    yield put({
      type: constants.DISCOUNT_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editDiscount(action) {
  try {
    const discountData = {
      ...action.formData,
      scope: 'admin',
      active: 1
    }

    //
    // notifications
    notification.info({
      key: 'discount_edit',
      message: 'Editando desconto'
    })

    const payload = yield call(api.edit, discountData)
    yield put({ type: constants.DISCOUNT_EDIT.SUCCESS, payload })

    // notifications
    notification.close('discount_edit')
    notification.success({
      message: 'Desconto editado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('discount_edit')
    notification.error({
      message: 'Problemas ao editar o desconto',
      description: e.message || e
    })
    yield put({
      type: constants.DISCOUNT_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeDiscount(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'discount_remove',
      message: 'Deletando desconto'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.DISCOUNT_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('discount_remove')
    notification.success({
      message: 'Desconto deletado com sucesso'
    })
    yield put(discountActions.fetchDiscounts())
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('discount_remove')
    notification.error({
      message: 'Problemas ao deletar o desconto',
      description: e.message || e
    })
    yield put({
      type: constants.DISCOUNT_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchCourseClassByCourseIdValidSell(action) {
  try {
    const payload = yield call(api.fetchCourseClassByCourseIdValidSell, action.id_curso)
    yield put({ type: constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    //notifications
    notification.error({
      message: 'Problemas ao salvar!',
      description: e.message || e
    })
    yield put({
      type: constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


/**
 * Saga
 */
function* watchFetchDiscounts() {
  yield takeLatest(constants.DISCOUNT_FETCH.ACTION, fetchDiscounts)
}

function* watchFetchDiscountsById() {
  yield takeLatest(constants.DISCOUNT_FETCH_BY_ID.ACTION, fetchDiscountsById)
}

function* watchAddDiscount() {
  yield takeLatest(constants.DISCOUNT_ADD.ACTION, addDiscount)
}

function* watchEditDiscount() {
  yield takeLatest(constants.DISCOUNT_EDIT.ACTION, editDiscount)
}

function* watchRemoveDiscount() {
  yield takeLatest(constants.DISCOUNT_REMOVE.ACTION, removeDiscount)
}

function* watchfetchCourseClassByCourseIdValidSell() {
  yield takeLatest(constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.ACTION, fetchCourseClassByCourseIdValidSell)
}




/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchDiscounts),
    fork(watchFetchDiscountsById),
    fork(watchAddDiscount),
    fork(watchEditDiscount),
    fork(watchRemoveDiscount),
    fork(watchfetchCourseClassByCourseIdValidSell),
  ])
}
