import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAll(currentPage, itemsPerPage, filters) {
  return request('v1/crud/user', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters || {}, 'allor')
  })
}

export function fetchById(id) {
  return request(`v1/user/${id}`)
}

export function add(formData) {
  return request(`v1/user`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function edit(formData) {
  return request(`v1/user/${formData.id}`, {
    method: 'PUT',
    body: JSON.stringify(formData)
  })
}

export function activeToggle(id, formData) {
  return request(`v1/crud/user/${id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/crud/user/${id}`, {
    method: 'DELETE'
  })
}

export function attachRole(id, formData) {
  return request(`v1/crud/user/${id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

