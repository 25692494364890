import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);


// TODO: mudar para register e permitir que o app trabalhe "offline"
serviceWorker.unregister();
