import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  DISCOUNT_FETCH: createSagaAction('DISCOUNT_FETCH'),
  DISCOUNT_FETCH_BY_ID: createSagaAction('DISCOUNT_FETCH_BY_ID'),
  DISCOUNT_ADD: createSagaAction('DISCOUNT_ADD'),
  DISCOUNT_EDIT: createSagaAction('DISCOUNT_EDIT'),
  DISCOUNT_REMOVE: createSagaAction('DISCOUNT_REMOVE'),
  DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID: createSagaAction('DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID')
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchDiscounts: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.DISCOUNT_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchDiscountsById: (id, next) => ({
    type: constants.DISCOUNT_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  addDiscount: (formData, next) => ({
    type: constants.DISCOUNT_ADD.ACTION,
    formData,
    next
  }),
  editDiscount: (formData, next) => ({
    type: constants.DISCOUNT_EDIT.ACTION,
    formData,
    next
  }),
  removeDiscount: (id, next) => ({
    type: constants.DISCOUNT_REMOVE.ACTION,
    id,
    next
  }),
  fetchCourseClassByCourseIdValidSell: (id_curso, next) => ({
    type:constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.ACTION,
    id_curso,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // DISCOUNT_FETCH
  [constants.DISCOUNT_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.DISCOUNT_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // DISCOUNT_FETCH_BY_ID
  [constants.DISCOUNT_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      discount: { ...action.payload },
      isLoading: false
    }
  },
  [constants.DISCOUNT_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // DISCOUNT_ADD
  [constants.DISCOUNT_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.discountServiceReturn,
      isLoading: false
    }
  },
  [constants.DISCOUNT_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // DISCOUNT_EDIT
  [constants.DISCOUNT_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.discountServiceReturn,
      isLoading: false
    }
  },
  [constants.DISCOUNT_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // DISCOUNT_REMOVE
  [constants.DISCOUNT_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.DISCOUNT_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  //
  //
  [constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      turmas: action.payload
    }
  },
  [constants.DISCOUNT_FETCH_COURSECLASS_BY_COURSE_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
