import moment from "moment";
import request from "../../shared/request";
import "moment/locale/pt-br";

moment.locale("pt-br");

export const fetchAllDescriptions = async (id) => {
  const result = await request(`v1/services/notes/list/${id}`);

  const data = [];

  result.map(function (res) {
    data.push({
      id: res.id,
      date: moment(res.updated_at).format("DD [de] MMM [de] YYYY"),
      observations: res.note,
    });

    return data;
  });

  return data;
};

export const setDataDescription = async (id, textArea) => {
  const body = { note: textArea };

  await request(`v1/services/notes/${id}`, {
    method: "POST",
    body: JSON.stringify(body),
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const deleteDataDescription = async (id) => {
  await request(`v1/services/notes/${id}`, {
    method: "DELETE",
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const putDataDescription = async (id, textArea) => {
  const body = { note: textArea };

  await request(`v1/services/notes/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
