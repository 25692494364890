import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";

import _ from "lodash";

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  CLASS_FETCH: createSagaAction("CLASS_FETCH"),
  CLASS_FETCH_SIMPLE: createSagaAction("CLASS_FETCH_SIMPLE"),
  CLASS_FETCH_BY_ID: createSagaAction("CLASS_FETCH_BY_ID"),
  CLASS_ADD: createSagaAction("CLASS_ADD"),
  CLASS_EDIT: createSagaAction("CLASS_EDIT"),
  CLASS_REMOVE: createSagaAction("CLASS_REMOVE"),
  CLASS_FETCH_BY_COURSE_ID: createSagaAction("CLASS_FETCH_BY_COURSE_ID"),
  CLASS_FETCH_USAGE_DETAILS_BY_ID: createSagaAction(
    "CLASS_FETCH_USAGE_DETAILS_BY_ID"
  ),
  CLASS_CLONE: createSagaAction("CLASS_CLONE"),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchClasses: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.CLASS_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next,
  }),
  fetchClassesSimple: (filters = {}, next) => ({
    type: constants.CLASS_FETCH_SIMPLE.ACTION,
    filters,
    next,
  }),
  fetchClassesById: (id, next) => ({
    type: constants.CLASS_FETCH_BY_ID.ACTION,
    id,
    next,
  }),
  addClass: (formData, next) => ({
    type: constants.CLASS_ADD.ACTION,
    formData,
    next,
  }),
  editClass: (formData, next) => ({
    type: constants.CLASS_EDIT.ACTION,
    formData,
    next,
  }),
  removeClass: (id, next) => ({
    type: constants.CLASS_REMOVE.ACTION,
    id,
    next,
  }),
  fetchClassesByCourseId: (courseId, next) => ({
    type: constants.CLASS_FETCH_BY_COURSE_ID.ACTION,
    courseId,
    next,
  }),
  getClassUsageDetailsById: (classId, next) => ({
    type: constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.ACTION,
    classId,
    next,
  }),
  cloneClass: (id, next) => ({
    type: constants.CLASS_CLONE.ACTION,
    id,
    next,
  }),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // CLASS_FETCH
  [constants.CLASS_FETCH.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false,
    };
  },
  [constants.CLASS_FETCH.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_FETCH
  [constants.CLASS_FETCH_SIMPLE.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_FETCH_SIMPLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  },
  [constants.CLASS_FETCH_SIMPLE.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_FETCH_BY_ID
  [constants.CLASS_FETCH_BY_ID.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      class: { ...action.payload },
      isLoading: false,
    };
  },
  [constants.CLASS_FETCH_BY_ID.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_ADD
  [constants.CLASS_ADD.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.classServiceReturn,
      isLoading: false,
    };
  },
  [constants.CLASS_ADD.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_EDIT
  [constants.CLASS_EDIT.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.classServiceReturn,
      isLoading: false,
    };
  },
  [constants.CLASS_EDIT.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_REMOVE
  [constants.CLASS_REMOVE.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [constants.CLASS_REMOVE.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_FETCH_BY_COURSE_ID
  [constants.CLASS_FETCH_BY_COURSE_ID.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_FETCH_BY_COURSE_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      courseClasses: action.payload,
      isLoading: false,
    };
  },
  [constants.CLASS_FETCH_BY_COURSE_ID.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_FETCH_BY_COURSE_ID
  [constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      classesUsage: action.payload,
      isLoading: false,
    };
  },
  [constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // CLASS_CLONE
  [constants.CLASS_CLONE.ACTION]: (state, action) => {
    const newClass = _.cloneDeep(state.newClasses);
    newClass.push({
      id_aula: action.id.id_aula,
      cod_aula: action.id.cod_aula,
      titulo: action.id.titulo,
      questoes: action.id.questoes.length,
      description: "duplicando aula, aguarde alguns instantes...",
      ready: false,
    });

    return {
      ...state,
      error: false,
      newClasses: newClass,
      newClassIsLoading: true,
    };
  },
  [constants.CLASS_CLONE.SUCCESS]: (state, action) => {
    let newClass = _.cloneDeep(state.newClasses);
    _.remove(
      newClass,
      (newClass) => newClass.id_aula === action.payload.id_aula
    );

    newClass.push({
      id_aula: action.payload.id_aula,
      cod_aula: action.payload.cod_aula,
      titulo: action.payload.titulo,
      questoes: action.payload.questoes,
      description: "aula duplicada.",
      ready: true,
    });

    return {
      ...state,
      newClassIsLoading: false,
      newClasses: newClass,
    };
  },
  [constants.CLASS_CLONE.FAILED]: (state, action) => {
    console.log(action);
    let newClasses = _.cloneDeep(state.newClasses);
    _.remove(
      newClasses,
      (newClass) => newClass.id_aula === action.class.id_aula
    );
    return {
      ...state,
      error: true,
      newClasses: newClasses,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
  newClasses: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : { ...state, isLoading: false };
});
