import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/files'
import { constants } from '../modules/files'

function* addFiles(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'file_add',
      message: 'Adicionando Arquivo'
    })

    const payload = yield call(api.add, action.formData)
    yield put({ type: constants.FILES_ADD.SUCCESS, payload })

    // notifications
    notification.close('file_add')
    notification.success({
      message: 'Arquivo adicionado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('file_add')
    notification.error({
      message: 'Problemas ao adicionar o arquivo',
      description: e.message || e
    })
    yield put({
      type: constants.FILES_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}



/**
 * Saga
 */
function* watchAddFiles() {
  yield takeLatest(constants.FILES_ADD.ACTION, addFiles)
}


/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchAddFiles)
  ])
}
