import { all, fork } from 'redux-saga/effects'

import { rootSaga as auth } from './auth'
import { rootSaga as files } from './files'
import { rootSaga as users } from './users'
import { rootSaga as access } from './access'
import { rootSaga as course } from './course'
import { rootSaga as labels } from './labels'
import { rootSaga as classes } from './classes'
import { rootSaga as saleLink} from './saleLink'
import { rootSaga as filters } from './filters'
import { rootSaga as student } from './student'
import { rootSaga as payment } from './payment'
import { rootSaga as question } from './question'
import { rootSaga as discount } from './discount'
import { rootSaga as affiliate } from './affiliate'
import { rootSaga as dashbaord } from './dashboard'
import { rootSaga as courseClass } from './courseClass'
import { rootSaga as mentorships } from './mentorships'
import { rootSaga as teacherArea } from './teacherArea'
import { rootSaga as massiveDelete } from './massiveDelete'
import { rootSaga as certification } from './certification'
import { rootSaga as crm } from './crm'
import { rootSaga as gabarito } from './gabarito';
import { rootSaga as lead } from './lead';
import { rootSaga as quizQuestions } from './quizQuestions';
import { rootSaga as reportGenerator } from './reportGenerator';
import { rootSaga as descriptionsStudent } from './descriptionsStudent';


function* rootSaga() {
  yield all([
    fork(auth),
    fork(files),
    fork(users),
    fork(course),
    fork(labels),
    fork(classes),
    fork(filters),
    fork(student),
    fork(payment),
    fork(question),
    fork(saleLink),
    fork(discount),
    fork(affiliate),
    fork(dashbaord),
    fork(courseClass),
    fork(mentorships),
    fork(teacherArea),
    fork(massiveDelete),
    fork(certification),
    fork(access),
    fork(crm),
    fork(gabarito),
    fork(lead),
    fork(quizQuestions),
    fork(reportGenerator),
    fork(descriptionsStudent)
  ])
}

export default rootSaga
