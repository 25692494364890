import React, { Component } from "react";
import { Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { history } from "./helpers";
import AppRoutes from "./AppRoutes";

import theme from "./shared/styled/theme";
import "./assets/scss/style.scss";

import { ConfigProvider } from "antd";
import ptBr from 'antd/lib/locale/pt_BR';


class App extends Component {
  render() {
    return (
      <ConfigProvider locale={ptBr}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <AppRoutes />
            </Switch>
          </Router>
        </ThemeProvider>
      </ConfigProvider>
    );
  }
}

export default App;
