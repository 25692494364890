import request from '../../shared/request'
import { generateFilters } from '../../helpers'


export function fetchGoals(currentPage, itemsPerPage, filters = {}) {
  return request('v1/services/goals/index', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters, 'allor')
  })
}
export function storeGoal(formData) {
  return request(`v1/services/goals/store`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}
export function removeGoal(id) {
  return request(`v1/services/goals/delete`, {
    method: 'DELETE',
    body: JSON.stringify({ id })
  })
}