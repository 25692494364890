import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { constants } from '../modules/auth'
import * as api from '../api/auth'

function* login(action) {
  try {
    const payload = yield call(api.login, action.username, action.password)
    payload.roles = payload.role
    yield put({ type: constants.AUTH_LOGIN.SUCCESS, payload })
  } catch (e) {
    yield put({
      type: constants.AUTH_LOGIN.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* resetPasswordRequest(action) {
  try {
    const payload = yield call(api.resetPasswordRequest, action.email)
    yield put({ type: constants.AUTH_RESET_PASSWORD_REQUEST.SUCCESS, payload })
  } catch (e) {
    yield put({
      type: constants.AUTH_RESET_PASSWORD_REQUEST.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


/**
 * Saga
 */
function* watchLogin() {
  yield takeLatest(constants.AUTH_LOGIN.ACTION, login)
}

function* watchrResetPasswordRequest() {
  yield takeLatest(constants.AUTH_RESET_PASSWORD_REQUEST.ACTION, resetPasswordRequest)
}


/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchLogin),
    fork(watchrResetPasswordRequest)
  ])
}
