import _ from 'lodash'
import { createSelector } from 'reselect'

export const users = state => _.sortBy(_.get(state, 'users.data', []), 'name')
export const pagination = state => _.get(state, 'users.pagination', [])
export const getUserById = (state, props) => id =>
  createSelector(
    [users],
    users => {
      return _.find(users, { id: parseInt(id) })
    }
  )(state)
export const isLoading = state => _.get(state, 'users.isLoading', [])
export const error = state => _.get(state, 'users.error', [])
export const errorMessage = state => _.get(state, 'users.errorMessage', [])

