import { all, fork, call, put, takeLatest } from 'redux-saga/effects'
import * as api from '../api/access'
import { constants } from '../modules/access'


function* fetchAccess(action) {
  try {
    const items = yield call(api.fetchAll, action.items)
    yield put({ type: constants.ACCESS_FETCH.SUCCESS, items });
  } catch (e) {
    yield put({
      type: constants.ACCESS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
function* watchFetchaccess() {
  yield takeLatest(constants.ACCESS_FETCH.ACTION, fetchAccess)
}


/**
 * Export the root saga by forking all available sagas.
 */
 export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchaccess)
  ])
}
