import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  CERTIFICATION_GET_ALL: createSagaAction('CERTIFICATION_GET_ALL'),
  CERTIFICATION_FETCH_BY_ID: createSagaAction('CERTIFICATION_FETCH_BY_ID'),
  CERTIFICATION_ADD_AVALIATION: createSagaAction('CERTIFICATION_ADD_AVALIATION')
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAllStudentsCertification: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.CERTIFICATION_GET_ALL.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchCertificationsById: (id, next) => ({
    type: constants.CERTIFICATION_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  addStudentCertification: (payload, next) => ({
    type: constants.CERTIFICATION_ADD_AVALIATION.ACTION,
    payload,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // CERTIFICATION_GET_ALL
  [constants.CERTIFICATION_GET_ALL.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.CERTIFICATION_GET_ALL.SUCCESS]: (state, action) => {
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.CERTIFICATION_GET_ALL.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // CERTIFICATION_FIND_BY_ID
  [constants.CERTIFICATION_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.CERTIFICATION_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      item: action.payload,
      isLoading: false
    }
  },
  [constants.CERTIFICATION_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // CERTIFICATION_ADD_AVALIATION
  [constants.CERTIFICATION_ADD_AVALIATION.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.CERTIFICATION_ADD_AVALIATION.SUCCESS]: (state) => {
    return {
      ...state,
      isLoading: false
    }
  },
  [constants.CERTIFICATION_ADD_AVALIATION.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
