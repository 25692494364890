import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";

import _ from "lodash";

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  COURSE_FETCH_LESSONS_BY_ID: createSagaAction("COURSE_FETCH_LESSONS_BY_ID"),
  COURSES_FETCH_ALL: createSagaAction("COURSES_FETCH_ALL"),
  COURSES_FETCH: createSagaAction("COURSES_FETCH"),
  COURSES_FETCH_BY_ID: createSagaAction("COURSES_FETCH_BY_ID"),
  COURSES_ADD: createSagaAction("COURSES_ADD"),
  COURSES_EDIT: createSagaAction("COURSES_EDIT"),
  COURSES_REMOVE: createSagaAction("COURSES_REMOVE"),
  COURSES_ENROLL: createSagaAction("COURSES_ENROLL"),
  COURSES_UNENROLL: createSagaAction("COURSES_UNENROLL"),
  COURSES_BULK_LINK_CLASS: createSagaAction("COURSES_BULK_LINK_CLASS"),
  COURSES_PRINT_BOOK: createSagaAction("COURSES_PRINT_BOOK"),
  COURSES_CLONE: createSagaAction("COURSES_CLONE"),
  COURSES_FETCH_FAMILIES: createSagaAction("COURSES_FETCH_FAMILIES"),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchLessonsById: (idCourse, filters = {}, next) => ({
    type: constants.COURSE_FETCH_LESSONS_BY_ID.ACTION,
    idCourse,
    filters,
    next
  }),
  fetchAllCourses: (filters = {}, next) => ({
    type: constants.COURSES_FETCH_ALL.ACTION,
    filters,
    next,
  }),
  fetchCourses: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.COURSES_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next,
  }),
  fetchCoursesById: (id, next) => ({
    type: constants.COURSES_FETCH_BY_ID.ACTION,
    id,
    next,
  }),
  addCourse: (formData, next) => ({
    type: constants.COURSES_ADD.ACTION,
    formData,
    next,
  }),
  editCourse: (formData, next) => ({
    type: constants.COURSES_EDIT.ACTION,
    formData,
    next,
  }),
  removeCourse: (id, next) => ({
    type: constants.COURSES_REMOVE.ACTION,
    id,
    next,
  }),
  enroll: (enrollments, next) => ({
    type: constants.COURSES_ENROLL.ACTION,
    enrollments,
    next,
  }),
  unEnroll: (courseId, studentId, next) => ({
    type: constants.COURSES_UNENROLL.ACTION,
    courseId,
    studentId,
    next,
  }),
  linkToCourseToClass: (courseId, classes, next) => ({
    type: constants.COURSES_BULK_LINK_CLASS.ACTION,
    courseId,
    classes,
    next,
  }),
  printBook: (course, next) => ({
    type: constants.COURSES_PRINT_BOOK.ACTION,
    course,
    next,
  }),
  cloneCourse: (course, next) => ({
    type: constants.COURSES_CLONE.ACTION,
    course,
    next,
  }),
  fetchCourseFamilies: (next) => ({
    type: constants.COURSES_FETCH_FAMILIES.ACTION,
    next,
  }),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // COURSES_FETCH_ALL
  [constants.COURSES_FETCH_ALL.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_FETCH_ALL.SUCCESS]: (state, action) => {
    return {
      ...state,
      allCourse: { ...action.payload },
      isLoading: false,
    };
  },
  [constants.COURSES_FETCH_ALL.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_FETCH
  [constants.COURSES_FETCH.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false,
    };
  },
  [constants.COURSES_FETCH.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_FETCH_BY_ID
  [constants.COURSES_FETCH_BY_ID.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      course: { ...action.payload },
      isLoading: false,
    };
  },
  [constants.COURSES_FETCH_BY_ID.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_ADD
  [constants.COURSES_ADD.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.courseServiceReturn,
      isLoading: false,
    };
  },
  [constants.COURSES_ADD.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_EDIT
  [constants.COURSES_EDIT.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.courseServiceReturn,
      isLoading: false,
    };
  },
  [constants.COURSES_EDIT.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_REMOVE
  [constants.COURSES_REMOVE.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [constants.COURSES_REMOVE.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_ENROLL
  [constants.COURSES_ENROLL.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_ENROLL.SUCCESS]: (state, action) => {
    return {
      ...state,
      enrolled: { ...action.payload },
      isLoading: false,
    };
  },
  [constants.COURSES_ENROLL.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_UNENROLL
  [constants.COURSES_UNENROLL.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_UNENROLL.SUCCESS]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [constants.COURSES_UNENROLL.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_BULK_LINK_CLASS
  [constants.COURSES_BULK_LINK_CLASS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSES_BULK_LINK_CLASS.SUCCESS]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [constants.COURSES_BULK_LINK_CLASS.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_PRINT_BOOK
  [constants.COURSES_PRINT_BOOK.ACTION]: (state, action) => {
    const newBooks = _.cloneDeep(state.books);
    newBooks.push({
      id_curso: action.course.id_curso,
      title: action.course.nome,
      description: "gerando o livro, aguarde alguns instantes...",
      filename: "#",
      ready: false,
    });

    return {
      ...state,
      error: false,
      books: newBooks,
      booksIsLoading: true,
    };
  },
  [constants.COURSES_PRINT_BOOK.SUCCESS]: (state, action) => {
    let newBooks = _.cloneDeep(state.books);
    _.remove(newBooks, (book) => book.id_curso === action.payload.id_curso);

    newBooks.push({
      id_curso: action.payload.id_curso,
      title: action.payload.nome,
      description: "livro pronto para download",
      filename: action.payload.filename,
      ready: true,
    });

    return {
      ...state,
      booksIsLoading: false,
      books: newBooks,
    };
  },
  [constants.COURSES_PRINT_BOOK.FAILED]: (state, action) => {
    let newBooks = _.cloneDeep(state.books);
    _.remove(newBooks, (book) => book.id_curso === action.course.id_curso);
    return {
      ...state,
      error: true,
      books: newBooks,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_CLONE
  [constants.COURSES_CLONE.ACTION]: (state, action) => {
    const newCourse = _.cloneDeep(state.newCourses);
    newCourse.push({
      id_curso: action.course.id_curso,
      title: action.course.nome,
      description: "duplicando curso, aguarde alguns instantes...",
      filename: "#",
      ready: false,
    });

    return {
      ...state,
      error: false,
      newCourses: newCourse,
      newCourseIsLoading: true,
    };
  },
  [constants.COURSES_CLONE.SUCCESS]: (state, action) => {
    let newCourse = _.cloneDeep(state.newCourses);
    _.remove(
      newCourse,
      (newCourse) => newCourse.id_curso === action.payload.id_curso
    );

    newCourse.push({
      id_curso: action.payload.id_curso,
      title: action.payload.nome,
      description: "curso duplicado.",
      filename: action.payload.filename,
      ready: true,
    });

    return {
      ...state,
      newCourseIsLoading: false,
      newCourses: newCourse,
    };
  },
  [constants.COURSES_CLONE.FAILED]: (state, action) => {
    let newCourses = _.cloneDeep(state.newCourses);
    _.remove(
      newCourses,
      (newCourse) => newCourse.id_curso === action.course.id_curso
    );
    return {
      ...state,
      error: true,
      newCourses: newCourses,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },

  // COURSES_FETCH_FAMILIES
  [constants.COURSES_FETCH_FAMILIES.ACTION]: (state) => {
    return { ...state, error: false, isLoadingFamilies: true };
  },
  [constants.COURSES_FETCH_FAMILIES.SUCCESS]: (state, action) => {
    return {
      ...state,
      families: [...action.payload],
      isLoadingFamilies: false,
    };
  },
  [constants.COURSES_FETCH_FAMILIES.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingFamilies: false,
    };
  },

  // COURSE_FETCH_LESSONS_BY_ID
  [constants.COURSE_FETCH_LESSONS_BY_ID.ACTION]: (state) => {
    return {
      ...state
    };
  },
  [constants.COURSE_FETCH_LESSONS_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      classes: action.payload
    };
  },
  [constants.COURSE_FETCH_LESSONS_BY_ID.FAILED]: (state) => {
    return {
      ...state
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
  isLoadingFamilies: false,
  books: [],
  newCourses: [],
  families: [],
  classes: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : { ...state, isLoading: false };
});
