import { createReducer } from "../../shared/reducers";
import { createSagaAction } from "../../shared/sagas";

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  GET_DATA_DESCRIPTION_FETCH: createSagaAction("GET_DATA_DESCRIPTION_FETCH"),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAllDescriptions: (items) => ({
    type: constants.GET_DATA_DESCRIPTION_FETCH.ACTION,
    items,
  }),
};

// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // AFFILIATE_FETCH
  [constants.GET_DATA_DESCRIPTION_FETCH.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.GET_DATA_DESCRIPTION_FETCH.SUCCESS]: (state, payload) => {
    return {
      ...state,
      isLoading: false,
      data: payload.items,
    };
  },
  [constants.GET_DATA_DESCRIPTION_FETCH.FAILED]: (state) => {
    return { ...state, error: true, isLoading: false, data: [] };
  },
};

export const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
  data: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
