import { all, fork, call, put, takeLatest } from 'redux-saga/effects'
import moment from 'moment'

import { notification } from 'antd'
import * as api from '../api/mentorships'
import { constants } from '../modules/mentorships'

function* getTeacherAvailability(action) {
  try {
    const payload = yield call(api.getTeacherAvailability, action.id)
    yield put({ type: constants.GET_TEACHER_AVAILABILITY.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_TEACHER_AVAILABILITY.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* getClassesByTeacherId(action) {
  try {
    const payload = yield call(api.getClassesByTeacherId)
    yield put({ type: constants.GET_CLASSES_BY_TEACHER_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_CLASSES_BY_TEACHER_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* getMyMentorship(action) {
  try {
    const payload = yield call(api.getMyMentorship)

    if (payload.mentoriaprofessor) {
      const { mentoriaprofessor } = payload
      mentoriaprofessor.map(el => el.hora_inicio = el.hora_inicio ? (el.hora_inicio).slice(0, 2) : '')
      mentoriaprofessor.map(el => el.hora_fim = el.hora_inicio ? (el.hora_fim).slice(0, 2) : '')
      mentoriaprofessor.map(el => el.dia_mentoria = el.hora_inicio ? moment(el.dia_mentoria).format('DD/MM/YYYY') : '')
    }

    yield put({ type: constants.GET_MY_MENTORSHIP.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_MY_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* getAllMentorship(action) {
  try {
    const payload = yield call(api.getAllMentorship)
    yield put({ type: constants.GET_ALL_MENTORSHIP.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_ALL_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* editTeacherAvailability(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'edit_availability',
      message: 'Editando disponibilidade'
    })

    const payload = yield call(api.editTeacherAvailability, action.formData)
    yield put({ type: constants.EDIT_TEACHER_AVAILABILITY.SUCCESS, payload })

    // notifications
    notification.close('edit_availability')
    notification.success({
      message: 'Aluno adicionado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('edit_availability')
    notification.error({
      message: 'Problemas ao editar disponibilidade',
      description: e.message || e
    })
    yield put({
      type: constants.EDIT_TEACHER_AVAILABILITY.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editTeacherClassesList(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'edit_teacher_class',
      message: 'Editando aulas do professor'
    })

    const payload = yield call(api.editTeacherClassesList, action.formData)
    yield put({ type: constants.EDIT_TEACHER_CLASSES.SUCCESS, payload })

    // notifications
    notification.close('edit_teacher_class')
    notification.success({
      message: 'Aulas editadas com sucesso'
    })

    action.next && action.next(false)
  } catch (e) {
    // notifications
    notification.close('edit_teacher_class')
    notification.error({
      message: 'Problemas ao editar as aulas',
      description: e.message || e
    })
    yield put({
      type: constants.EDIT_TEACHER_CLASSES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
    action.next && action.next(true)
  }
}

function* acceptMentorship(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'accept_mentorship',
      message: 'Aceitando mentoria'
    })

    const payload = yield call(api.acceptMentorship, action.id)
    yield put({ type: constants.ACCEPT_MENTORSHIP.SUCCESS, payload })

    // notifications
    notification.close('accept_mentorship')
    notification.success({
      message: 'Mentoria aceita com sucesso'
    })
    action.next && action.next(true)
  } catch (e) {
    // notifications
    notification.close('student_edit_active')
    notification.error({
      message: 'Problemas ao aceitar mentoria',
      description: e.message || e
    })
    yield put({
      type: constants.ACCEPT_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
  action.next && action.next(false)
}

function* cancelMentorship(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'cancel_mentorship',
      message: 'Cancelando mentoria'
    })

    const payload = yield call(api.cancelMentorship, action.id)
    yield put({ type: constants.CANCEL_MENTORSHIP.SUCCESS, payload })

    // notifications
    notification.close('cancel_mentorship')
    notification.success({
      message: 'Mentoria cancelada com sucesso'
    })
    action.next && action.next(true)
  } catch (e) {
    // notifications
    notification.close('cancel_mentorship')
    notification.error({
      message: 'Problemas ao cancelar mentoria',
      description: e.message || e
    })
    yield put({
      type: constants.CANCEL_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e
    })
    action.next && action.next(false)
  }
}



/**
 * Saga
 */
function* watchGetTecherAvalibility() {
  yield takeLatest(constants.GET_TEACHER_AVAILABILITY.ACTION, getTeacherAvailability)
}

function* watchGetClassesByTeacherId() {
  yield takeLatest(constants.GET_CLASSES_BY_TEACHER_ID.ACTION, getClassesByTeacherId)
}

function* watchGetAllMentorship() {
  yield takeLatest(constants.GET_ALL_MENTORSHIP.ACTION, getAllMentorship)
}

function* watchGetMyMentorship() {
  yield takeLatest(constants.GET_MY_MENTORSHIP.ACTION, getMyMentorship)
}

function* watchEditAvailability() {
  yield takeLatest(constants.EDIT_TEACHER_AVAILABILITY.ACTION, editTeacherAvailability)
}

function* watchEditTeacherClass() {
  yield takeLatest(constants.EDIT_TEACHER_CLASSES.ACTION, editTeacherClassesList)
}

function* watchAcceptMentorship() {
  yield takeLatest(constants.ACCEPT_MENTORSHIP.ACTION, acceptMentorship)
}

function* watchCancelMentorship() {
  yield takeLatest(constants.CANCEL_MENTORSHIP.ACTION, cancelMentorship)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchGetTecherAvalibility),
    fork(watchGetClassesByTeacherId),
    fork(watchGetAllMentorship),
    fork(watchGetMyMentorship),
    fork(watchEditAvailability),
    fork(watchEditTeacherClass),
    fork(watchAcceptMentorship),
    fork(watchCancelMentorship),
  ])
}