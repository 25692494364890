import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import * as api from '../api/lead';
import { constants, TotalConvertedType } from '../modules/lead';
import { IPaginationData } from '../../shared/models/lead/IPaginationData';
import { ITypeLead } from '../../shared/models/lead/ITypeLead';

function* fetchAll(action: any) {
  try {
    const payload: IPaginationData = yield call(api.fetchAll, action.params);
    yield put({ type: constants.LEAD_FETCH_ALL.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.LEAD_FETCH_ALL.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* store(action: {
  type: string;
  formData: {};
  callback?: (payload: any) => void;
}) {
  try {
    notification.info({
      key: "lead_add",
      message: "Adicionando Lead...",
    });

    const payload = yield call(api.store, action.formData);
    yield put({ type: constants.LEAD_STORE.SUCCESS, payload });

    notification.close('lead_add');
    notification.success({
      message: "O Lead foi adicionado com sucesso!",
    });

    action.callback && action.callback(payload);
  } catch (e) {
    yield put({
      type: constants.LEAD_STORE.FAILED,
      error: true
    });

    notification.close('lead_add');
    notification.error({
      message: "Houve um erro inesperado ao adicionar o Lead.",
    });

  }
};

function* fetchTypeLeads(action: {
  type: string;
  callback: (payload: ITypeLead[]) => void
}) {
  try {
    const payload = yield call(api.fetchTypeLeads);
    yield put({ type: constants.LEAD_FETCH_TYPE_LEAD.SUCCESS, payload });

    action.callback && action.callback(payload);
  } catch (e) {
    yield put({
      type: constants.LEAD_FETCH_TYPE_LEAD.FAILED,
      error: true
    });
  }
};

function* fetchAvailableTypesLeads(action: {
  type: string;
  filters: {},
  pagination: {}
}) {
  try {
    const payload = yield call(api.fetchAvailableTypesLeads);
    yield put({ type: constants.LEAD_FETCH_AVAILABLE_TYPES.SUCCESS, payload });
  } catch (e) {
    yield put({
      type: constants.LEAD_FETCH_AVAILABLE_TYPES.FAILED,
      error: true
    });
  }
};

function* fetchConvertedLeads(action: {
  type: string;
  filters: {},
  pagination: {}
}) {
  try {
    const payload = yield call(api.fetchConvertedLeads, action.filters, action.pagination);
    yield put({ type: constants.LEAD_FETCH_CONVERTED_LEAD.SUCCESS, payload });
  } catch (e) {
    yield put({
      type: constants.LEAD_FETCH_CONVERTED_LEAD.FAILED,
      error: true
    });
  }
};

function* fetchTotalsConverted(action: {
  type: string;
  filters: TotalConvertedType;
}) {
  try {
    const payload = yield call(api.fetchTotalsConverted, action.filters);
    yield put({ type: constants.LEAD_FETCH_TOTALS_CONVERTED.SUCCESS, payload });
  } catch (e) {
    yield put({
      type: constants.LEAD_FETCH_TOTALS_CONVERTED.FAILED,
      error: true
    });
  }
};

/**
 * Saga
 */
function* watchFetchAll() {
  yield takeLatest(constants.LEAD_FETCH_ALL.ACTION, fetchAll);
};

function* watchFetchAvailableTypes() {
  yield takeLatest(constants.LEAD_FETCH_AVAILABLE_TYPES.ACTION, fetchAvailableTypesLeads);
};

function* watchFetchConvertedLeads() {
  yield takeLatest(constants.LEAD_FETCH_CONVERTED_LEAD.ACTION, fetchConvertedLeads);
};

function* watchFetchTotalsConverted() {
  yield takeLatest(constants.LEAD_FETCH_TOTALS_CONVERTED.ACTION, fetchTotalsConverted);
};

function* watchStore() {
  yield takeLatest(constants.LEAD_STORE.ACTION, store);
};

function* watchTypeLeads() {
  yield takeLatest(constants.LEAD_FETCH_TYPE_LEAD.ACTION, fetchTypeLeads);
};

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchFetchAll),
    fork(watchFetchAvailableTypes),
    fork(watchFetchConvertedLeads),
    fork(watchFetchTotalsConverted),
    fork(watchStore),
    fork(watchTypeLeads)
  ]);
};
