import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  SET_FILTERS: createSagaAction('SET_FILTERS'),
  REMOVE_FILTERS: createSagaAction('REMOVE_FILTERS'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  addFilters: (filters) => ({
    type: constants.SET_FILTERS.ACTION,
    filters,
  }),
  removeFilters: () => ({
    type: constants.REMOVE_FILTERS.ACTION,
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // SET_FILTERS
  [constants.SET_FILTERS.ACTION]: state => {
    return { ...state }
  },
  [constants.SET_FILTERS.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.filtros,
    }
  },
  [constants.SET_FILTERS.FAILED]: state => {
    return { ...state, error: true }
  },
  
  // REMOVE_FILTERS
  [constants.REMOVE_FILTERS.ACTION]: state => {
    return { ...state }
  },
  [constants.REMOVE_FILTERS.SUCCESS]: () => {
    return {}
  },
  [constants.REMOVE_FILTERS.FAILED]: state => {
    return { ...state, error: true }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state }
})
