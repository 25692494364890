import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  LABELS_FETCH: createSagaAction('LABELS_FETCH'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchLabels: (next) => ({
    type: constants.LABELS_FETCH.ACTION,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // LABELS_FETCH
  [constants.LABELS_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.LABELS_FETCH.SUCCESS]: (state, action) => {

    return {
      ...state,
      data: action.payload,
      isLoading: false
    }
  },
  [constants.LABELS_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
