import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAll(currentPage, itemsPerPage, filters) {
  return request('v1/crud/afiliados', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters || {}, 'allor')
  })
}

export function fetchAllProfessors() {
  return request('v1/services/affiliates/professors/all');
}

export function fetchById(id) {
  return request(`v1/crud/afiliados/${id}`)
}

export function add(formData) {
  return request(`v1/crud/afiliados`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function edit(formData) {
  return request(`v1/crud/afiliados/${formData.id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/crud/afiliados/${id}`, {
    method: 'DELETE'
  })
}



