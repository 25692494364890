import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  STUDENT_FETCH: createSagaAction('STUDENT_FETCH'),
  STUDENT_FETCH_SIMPLE: createSagaAction('STUDENT_FETCH_SIMPLE'),
  STUDENT_FETCH_BY_ID: createSagaAction('STUDENT_FETCH_BY_ID'),
  STUDENT_ADD: createSagaAction('STUDENT_ADD'),
  STUDENT_EDIT: createSagaAction('STUDENT_EDIT'),
  STUDENT_ACTIVE_TOGGLE: createSagaAction('STUDENT_ACTIVE_TOGGLE'),
  STUDENT_REMOVE: createSagaAction('STUDENT_REMOVE'),
  STUDENT_FETCH_ENROLLED_BY_STUDENT_ID: createSagaAction('STUDENT_FETCH_ENROLLED_BY_STUDENT_ID'),

  STUDENT_FETCH_SCHEDULED_TESTS: createSagaAction('STUDENT_FETCH_SCHEDULED_TESTS'),
  STUDENT_CONTACTED_SCHEDULED_TESTS: createSagaAction('STUDENT_CONTACTED_SCHEDULED_TESTS'),
  STUDENT_FETCH_METRICS: createSagaAction('STUDENT_FETCH_METRICS'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchStudents: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.STUDENT_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchStudentsSimple: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.STUDENT_FETCH_SIMPLE.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchStudentsById: (id, next) => ({
    type: constants.STUDENT_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  fetchScheduledTests: (currentPage=1, itemsPerPage=15, filters, next) => ({
    type: constants.STUDENT_FETCH_SCHEDULED_TESTS.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  addStudent: (formData, next) => ({
    type: constants.STUDENT_ADD.ACTION,
    formData,
    next
  }),
  editStudent: (formData, next) => ({
    type: constants.STUDENT_EDIT.ACTION,
    formData,
    next
  }),
  activeStudentToggle: (id, active, next) => ({
    type: constants.STUDENT_ACTIVE_TOGGLE.ACTION,
    id,
    active,
    next
  }),
  removeStudent: (id, next) => ({
    type: constants.STUDENT_REMOVE.ACTION,
    id,
    next
  }),
  fetchEnrolledByStudentId: (id, next) => ({
    type: constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.ACTION,
    id,
    next
  }),
  updateScheduledToggleContact: (scheduledTestId, callback) => ({
    type: constants.STUDENT_CONTACTED_SCHEDULED_TESTS.ACTION,
    scheduledTestId,
    callback
  }),
  fetchMetrics: (studentScheduledId, callback) => ({
    type: constants.STUDENT_FETCH_METRICS.ACTION,
    studentScheduledId,
    callback
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // STUDENT_FETCH
  [constants.STUDENT_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.STUDENT_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_FETCH_SIMPLE
  [constants.STUDENT_FETCH_SIMPLE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_FETCH_SIMPLE.SUCCESS]: (state, action) => {
    //
    return {
      ...state,
      data: action.payload,
      isLoading: false
    }
  },
  [constants.STUDENT_FETCH_SIMPLE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // STUDENT_FETCH_BY_ID
  [constants.STUDENT_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      user: { ...action.payload },
      isLoading: false
    }
  },
  [constants.STUDENT_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  // STUDENT_ADD
  [constants.STUDENT_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.STUDENT_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // STUDENT_EDIT
  [constants.STUDENT_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.STUDENT_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_ACTIVE_TOGGLE
  [constants.STUDENT_ACTIVE_TOGGLE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_ACTIVE_TOGGLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.STUDENT_ACTIVE_TOGGLE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_REMOVE
  [constants.STUDENT_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.STUDENT_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_FETCH_ENROLLED_BY_STUDENT_ID
  [constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      courses: { ...action.payload },
      isLoading: false
    }
  },
  [constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_FETCH_SCHEDULED_TESTS
  [constants.STUDENT_FETCH_SCHEDULED_TESTS.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isSearchingScheduledTests: false }
  },
  [constants.STUDENT_FETCH_SCHEDULED_TESTS.ACTION]: state => {
    return { ...state, error: false, isSearchingScheduledTests: true }
  },
  [constants.STUDENT_FETCH_SCHEDULED_TESTS.SUCCESS]: (state, action) => {
    const scheduledPagination = Object.assign({}, action.payload);
    delete scheduledPagination.data;

    return {
      ...state,
      scheduledTests: [ ...action.payload.data ],
      scheduledPagination,
      isSearchingScheduledTests: false
    }
  },

  //STUDENT_CONTACTED_SCHEDULED_TESTS
  [constants.STUDENT_CONTACTED_SCHEDULED_TESTS.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage }
  },
  [constants.STUDENT_CONTACTED_SCHEDULED_TESTS.ACTION]: state => {
    return { ...state, error: false }
  },
  [constants.STUDENT_CONTACTED_SCHEDULED_TESTS.SUCCESS]: (state, action) => {

    const updatedScheduledTests = state.scheduledTests.map(scheduled => {
      if (scheduled.id_student_scheduled_test === action.payload) {
        return {
          ...scheduled,
          contacted: !scheduled.contacted
        };
      }

      return scheduled;
    });

    return {
      ...state,
      error: false,
      scheduledTests: [...updatedScheduledTests]
    }
  },

  //STUDENT_FETCH_METRICS
  [constants.STUDENT_FETCH_METRICS.ACTION]: state => {
    return { ...state, error: false, isSearchingMetrics: true }
  },
  [constants.STUDENT_FETCH_METRICS.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isSearchingMetrics: false
    }
  },
  [constants.STUDENT_FETCH_METRICS.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isSearchingMetrics: false,
      metricsByStudent: { ...action.payload },
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  scheduledTests: [],
  scheduledPagination: {},
  isSearchingScheduledTests: false,
  metricsByStudent: {},
  isSearchingMetrics: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
