import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import { notification } from 'antd';
import { constants } from '../modules/reportGenerator';

import * as api from '../api/reportGenerator';


import { FormattedSendReportType, FormType, FormTypeText } from "../../pages/reportGeneratorCFP/common";


function* createReport(action: {
  type: string;
  formData: FormattedSendReportType;
  next: (error?: unknown, payload?: unknown) => void;
}) {
  try {
    notification.info({
      key: 'report_waiting',
      message: 'Gerando relatórios... Por favor aguarde...',
    });

    const payload = yield call(api.createReport, action.formData);
    yield put({
      type: constants.REPORT_GENERATE_CREATE.SUCCESS,
      payload
    });

    notification.close('report_waiting');
    notification.success({
      message: 'O Relatório foi gerado som sucesso!'
    });
    action?.next && action.next();
  } catch (e: unknown) {
    yield put({
      type: constants.REPORT_GENERATE_CREATE.FAILED,
      error: true,
    });

    notification.close('report_waiting');
    notification.error({
      message: 'Houve um erro ao gerar o relatório, tente novamente.'
    })
    action?.next && action.next(e);
  }
};

function* fetchScoreInformation(action: {
  type: string;
  filters: {
    courseCategoryId: number;
    typeReportId: number;
    categoryReportId: number;
  }
}) {
  try {
    const payload = yield call(api.fetchScoreInformation, action.filters);
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.SUCCESS,
      payload
    });
  } catch (e: unknown) {
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.FAILED
    })
  }
};

function* fetchGeneralText(action: {
  type: string;
  filters: {
    typeReportId: number;
    categoryReportId: number;
  }
  next: (error?: unknown, values?: unknown) => void
}) {
  try {
    const payload = yield call(api.fetchGeneralText, action.filters);
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.SUCCESS,
      payload
    });

    action.next && action.next(undefined, payload);
  } catch (e: unknown) {
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.FAILED
    })

    action.next && action.next(e);
  }
};

function* updateGeneralText(action: {
  type: string;
  formData: FormTypeText,
  id: number;
  next?: (error?: unknown, values?: unknown) => void;
}) {
  try {
    yield call(api.updateGeneralText, action.id, action.formData.content);
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.SUCCESS
    });

    action.next && action.next();
  } catch (e) {
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.FAILED
    });
    action.next(e);
  }
}

function* fetchScoreInformationById(action: {
  type: string;
  id: number;
  next: (error?: unknown, values?: unknown) => void
}) {
  try {
    const payload = yield call(api.fetchScoreInformationById, action.id);
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.SUCCESS,
      payload
    });

    action.next && action.next(undefined, payload);
  } catch (e: unknown) {
    yield put({
      type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.FAILED
    })

    action.next && action.next(e);
  }
};

function* createScoreInformation(action: {
  type: string;
  formData: FormType;
}) {
  try {
    yield call(api.createScoreInformation, action.formData);
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.SUCCESS
    });
  } catch (e) {
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.FAILED
    });
  }
}

function* updateScoreInformation(action: {
  type: string;
  formData: FormType,
  id: number;
  next?: (error?: unknown, values?: unknown) => void;
}) {
  try {
    yield call(api.updateScoreInformation, action.id, action.formData);
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.SUCCESS
    });

    action.next && action.next();
  } catch (e) {
    yield put({
      type: constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.FAILED
    });
    action.next(e);
  }
}

function* watchCreateReport() {
  yield takeLatest(constants.REPORT_GENERATE_CREATE.ACTION, createReport);
}

function* watchFetchScoreInformation() {
  yield takeLatest(constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.ACTION, fetchScoreInformation);
}

function* watchFetchScoreInformationById() {
  yield takeLatest(constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.ACTION, fetchScoreInformationById);
}

function* watchCreateScoreInformation() {
  yield takeLatest(constants.REPORT_GENERATOR_CREATE_SCORE_INFORMATION.ACTION, createScoreInformation);
}

function* watchUpdateScoreInformation() {
  yield takeLatest(constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.ACTION, updateScoreInformation);
}

function* watchFetchGeneralText() {
  yield takeLatest(constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.ACTION, fetchGeneralText);
}

function* watchUpdateGeneralText() {
  yield takeLatest(constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.ACTION, updateGeneralText);
}

export function* rootSaga() {
  yield all([
    fork(watchCreateReport),
    fork(watchFetchScoreInformation),
    fork(watchCreateScoreInformation),
    fork(watchFetchScoreInformationById),
    fork(watchUpdateScoreInformation),
    fork(watchFetchGeneralText),
    fork(watchUpdateGeneralText)
  ])
}
