import request from '../../shared/request';
import { generateFilters } from '../../helpers';
import { PaginationProps } from '../../shared/models/crm/types';
import { FilterProps } from '../../pages/crm/shared/types';
import { AddContactProps, LinkSelectedStudentsProps, LinkStudentsToSalesmanProps } from '../modules/crm';
import { IStudent } from '../../shared/models/crm/IStudent';
import { IContact } from '../../shared/models/crm/IContact';
import { ITypeContact } from '../../shared/models/crm/ITypeContact';

// Types
type FetchStudentProps = {
  filters: FilterProps;
  pagination: PaginationProps;
}

type FetchStudentBySalesmanProps = {
  filters: FilterProps;
  pagination: PaginationProps;
};

type FetchHistoricProps = {
  filters: { id_student: number };
};

export const fetchStudents = ({ filters, pagination }: FetchStudentProps): Promise<IStudent[]> => {
  return request('v1/services/crm/admin/students', {
    qsParams: {
      per_page: pagination.itemsPerPage,
      page: pagination.currentPage
    },
    filters: generateFilters(filters, 'service')
  });
};

export const fetchStudentsBySalesman = ({ filters, pagination }: FetchStudentBySalesmanProps): Promise<IStudent[]> => {
  return request('v1/services/crm/students', {
    qsParams: {
      per_page: pagination.itemsPerPage,
      page: pagination.currentPage
    },
    filters: generateFilters(filters, 'service')
  });
};

export const fetchSalesmans = () => request('v1/services/crm/admin/salesmans');

export const fetchHistoric = ({ filters }: FetchHistoricProps): Promise<IContact> => {
  return request('v1/services/crm/contacts', {
    qsParams: {
      ...filters
    }
  });
};

export const fetchTypeContacts = (): Promise<ITypeContact> => {
  return request('v1/crud/typecrmcontact?get=all&order[id_type_crm_contact]=ASC');
}

export const findStudent = (id: number): Promise<IStudent> => request(`v1/services/crm/students/${id}`);

export const addContact = (formData: AddContactProps): Promise<IContact> => {
  return request('v1/services/crm/contacts', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
};

export const linkStudentsBySalesman = (formData: LinkStudentsToSalesmanProps): Promise<IStudent[]> => {
  return request('v1/services/crm/admin/students', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
};

export const removeLinkByStudent = (studentId: number): Promise<boolean> => (
  request(`v1/services/crm/admin/students/${studentId}`, {
    method: 'DELETE'
  })
);

export const linkSelectedStudents = (items: LinkSelectedStudentsProps[], eventType: 'store' | 'update'): Promise<unknown[]> => {
  return request(`v1/services/crm/admin/link-selected-students`, {
    method: 'POST',
    body: JSON.stringify({ items, eventType })
  });
};
