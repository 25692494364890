import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";
import { IQuestion } from "../../shared/models/quiz/IQuestion";
import { FormikValues } from "formik";
import { ICertification } from "../../shared/models/quiz/ICertification";
import {IFamily} from "../../shared/models/quiz/IFamily";


export type SuccessCallback = ((payload?: unknown) => void) | null;
export type ErrorCallback = ((payload?: unknown) => void) | null;

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  QUIZ_QUESTIONS_FETCH_ALL: createSagaAction('QUIZ_QUESTIONS_FETCH_ALL'),
  QUIZ_QUESTIONS_FETCH_TOPICS: createSagaAction('QUIZ_QUESTIONS_FETCH_TOPICS'),
  QUIZ_QUESTIONS_FETCH_FAMILIES: createSagaAction('QUIZ_QUESTIONS_FETCH_FAMILIES'),
  QUIZ_QUESTIONS_FIND: createSagaAction('QUIZ_QUESTIONS_FIND'),
  QUIZ_QUESTIONS_STORE: createSagaAction('QUIZ_QUESTIONS_STORE'),
  QUIZ_QUESTIONS_UPDATE: createSagaAction('QUIZ_QUESTIONS_UPDATE'),
  QUIZ_QUESTIONS_DESTROY: createSagaAction('QUIZ_QUESTIONS_DESTROY'),
  QUIZ_QUESTION_CLEAR: 'QUIZ_QUESTION_CLEAR',
};

export type StateProps = {
  isLoading: boolean;
  error: boolean;
  questions: Array<IQuestion>;
  topics: Array<ICertification>;
  families: Array<IFamily>;
  pagination: {
    current_page: number;
    last_page: number;
  };
  question: IQuestion;
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAll: (
    filters = {},
    success?: SuccessCallback,
    error?: ErrorCallback
  ) => ({
    type: constants.QUIZ_QUESTIONS_FETCH_ALL.ACTION,
    filters,
    success,
    error,
  }),
  fetchTopics: (success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_FETCH_TOPICS.ACTION,
    success,
    error
  }),
  find: (id: number, success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_FIND.ACTION,
    id,
    success,
    error
  }),
  store: (formData: FormikValues, success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_STORE.ACTION,
    formData,
    success,
    error
  }),
  update: (formData: FormikValues, id: number, success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_UPDATE.ACTION,
    formData,
    id,
    success,
    error
  }),
  destroy: (id: number, success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_DESTROY.ACTION,
    id,
    success,
    error
  }),
  clearQuestion: () => ({
    type: constants.QUIZ_QUESTION_CLEAR,
  }),
  fetchFamilies: (success?: SuccessCallback, error?: ErrorCallback) => ({
    type: constants.QUIZ_QUESTIONS_FETCH_FAMILIES.ACTION,
    success,
    error
  })
};


const ACTION_HANDLERS = {
  // QUIZ_QUESTIONS_FETCH_ALL
  [constants.QUIZ_QUESTIONS_FETCH_ALL.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_FETCH_ALL.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_FETCH_ALL.SUCCESS]: (state: StateProps, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      questions: [...action.payload.data],
      pagination: {
        current_page: action.payload?.current_page,
        last_page: action.payload?.last_page
      }
    };
  },
  // QUIZ_QUESTIONS_FIND
  [constants.QUIZ_QUESTIONS_FIND.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_FIND.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_FIND.SUCCESS]: (state: StateProps, action: { payload: IQuestion }) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      question: { ...action.payload }
    };
  },
  // QUIZ_QUESTIONS_STORE
  [constants.QUIZ_QUESTIONS_STORE.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_STORE.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_STORE.SUCCESS]: (state: StateProps) => {
    return {
      ...state,
      error: false,
      isLoading: false,
    };
  },
  // QUIZ_QUESTIONS_UPDATE
  [constants.QUIZ_QUESTIONS_UPDATE.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_UPDATE.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_UPDATE.SUCCESS]: (state: StateProps) => {
    return {
      ...state,
      error: false,
      isLoading: false,
    };
  },
  [constants.QUIZ_QUESTIONS_FETCH_TOPICS.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_FETCH_TOPICS.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_FETCH_TOPICS.SUCCESS]: (state: StateProps, action: { payload: Array<ICertification> }) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      topics: [...action.payload]
    };
  },
  // QUIZ_QUESTIONS_DESTROY
  [constants.QUIZ_QUESTIONS_DESTROY.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_DESTROY.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_DESTROY.SUCCESS]: (state: StateProps) => {
    return {
      ...state,
      error: false,
      isLoading: false,
    };
  },
  [constants.QUIZ_QUESTION_CLEAR]: (state: StateProps) => {
    return {
      ...state,
      question: {}
    };
  },
  // FAMILY
  [constants.QUIZ_QUESTIONS_FETCH_FAMILIES.ACTION]: (state: StateProps) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUIZ_QUESTIONS_FETCH_FAMILIES.FAILED]: (state: StateProps) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUIZ_QUESTIONS_FETCH_FAMILIES.SUCCESS]: (state: StateProps, action: { payload: Array<IFamily> }) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      families: [...action.payload]
    };
  }
};


export const initialState: StateProps = {
  isLoading: false,
  error: false,
  questions: [] as IQuestion[],
  pagination: {
    current_page: 1,
    last_page: 1,
  },
  question: {} as IQuestion,
  topics: [] as ICertification[],
  families: [] as IFamily[]
};

export default createReducer(
  initialState,
  (state: StateProps, action: any) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : { ...state, isLoading: false };
  }
);
