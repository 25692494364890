import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import store from '../store'
import { notification } from 'antd'
import * as api from '../api/student'
import { constants } from '../modules/student'
import { actions as studentActions } from '../modules/student'

//
// selectors
import * as studentsSelectors from '../selectors/student'

function* fetchStudents(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.STUDENT_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchStudentsSimple(action) {
  try {
    const payload = yield call(api.fetchAllSimple, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.STUDENT_FETCH_SIMPLE.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchStudentsById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.STUDENT_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addStudent(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'student_add',
      message: 'Adicionando Aluno'
    })

    const payload = yield call(api.add, action.formData)
    yield put({ type: constants.STUDENT_ADD.SUCCESS, payload })

    // notifications
    notification.close('student_add')
    notification.success({
      message: 'Aluno adicionado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('student_add')
    notification.error({
      message: 'Problemas ao adicionar o aluno',
      description: e.message || e
    })
    yield put({
      type: constants.STUDENT_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editStudent(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'student_edit',
      message: 'Editando Aluno'
    })

    const payload = yield call(api.edit, action.formData)
    yield put({ type: constants.STUDENT_EDIT.SUCCESS, payload })

    // notifications
    notification.close('student_edit')
    notification.success({
      message: 'Aluno editado com sucesso'
    })

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('student_edit')
    notification.error({
      message: 'Problemas ao editar o aluno',
      description: e.message || e
    })
    yield put({
      type: constants.STUDENT_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* activeToggleStudent(action) {
  try {
    const studentData = {
      active: action.active
    }

    //
    // notifications
    notification.info({
      key: 'student_edit_active',
      message: action.active ? 'Ativando Aluno' : 'Desativando Aluno'
    })

    const payload = yield call(api.activeToggle, action.id, studentData)
    yield put({ type: constants.STUDENT_ACTIVE_TOGGLE.SUCCESS, payload })

    // notifications
    notification.close('student_edit_active')
    notification.success({
      message: action.active ? 'Aluno ativado com sucesso' : 'Aluno desativado com sucesso'
    })

    //
    // get pagination data to refresh the list corretctly
    const state = store.getState()
    const pagination = studentsSelectors.pagination(state)
    yield put(studentActions.fetchStudents(pagination.current_page, pagination.per_page))

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('student_edit_active')
    notification.error({
      message: action.active ? 'Problemas ao ativar o aluno' : 'Problemas ao desativar o aluno',
      description: e.message || e
    })
    yield put({
      type: constants.STUDENT_ACTIVE_TOGGLE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeStudent(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'student_remove',
      message: 'Deletando Aluno'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.STUDENT_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('student_remove')
    notification.success({
      message: 'Aluno deletado com sucesso'
    })
    yield put(studentActions.fetchStudents())
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('student_remove')
    notification.error({
      message: 'Problemas ao deletar o aluno',
      description: e.message || e
    })
    yield put({
      type: constants.STUDENT_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchCoursesByStudentId(action) {
  try {
    const payload = yield call(api.fetchByStudentId, action.id)
    yield put({ type: constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchScheduledTests(action) {
  try {
    const payload = yield call(api.fetchScheduledTests, action.currentPage, action.itemsPerPage, action.filters);
    yield put({
      type: constants.STUDENT_FETCH_SCHEDULED_TESTS.SUCCESS,
      payload
    });
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_SCHEDULED_TESTS.FAILED,
      error: true,
      errorMessage: e.message | e
    });
  }
};

function* updateScheduledToggleContact(action) {
  try {
    notification.info({
      key: 'student_scheduled_contacted',
      message: 'Atualizando informação'
    });

    yield call(api.updateScheduledToggleContact, action.scheduledTestId);
    yield put({
      type: constants.STUDENT_CONTACTED_SCHEDULED_TESTS.SUCCESS,
      payload: action.scheduledTestId,
    });

    notification.close('student_scheduled_contacted')
    notification.success({
      message: 'Informação atualizada com sucesso'
    })
  } catch (e) {
    notification.close('student_scheduled_contacted')
    notification.error({
      message: 'Houve um erro ao atualizar a informação, tente novamente.'
    })

    yield put({
      type: constants.STUDENT_CONTACTED_SCHEDULED_TESTS.FAILED,
      error: true,
      errorMessage: e.message | e
    });
  }
};

function* fetchMetrics(action) {
  try {
    const payload = yield call(api.fetchMetrics, action.studentScheduledId);
    yield put({
      type: constants.STUDENT_FETCH_METRICS.SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_METRICS.FAILED,
      error: true,
      errorMessage: e.message | e
    });
  }
};

/**
 * Saga
 */
function* watchFetchStudents() {
  yield takeLatest(constants.STUDENT_FETCH.ACTION, fetchStudents)
}

function* watchFetchStudentsSimple() {
  yield takeLatest(constants.STUDENT_FETCH_SIMPLE.ACTION, fetchStudentsSimple)
}

function* watchFetchStudentsById() {
  yield takeLatest(constants.STUDENT_FETCH_BY_ID.ACTION, fetchStudentsById)
}

function* watchFetchStudentScheduleTest() {
  yield takeLatest(constants.STUDENT_FETCH_SCHEDULED_TESTS.ACTION, fetchScheduledTests)
}

function* watchAddStudent() {
  yield takeLatest(constants.STUDENT_ADD.ACTION, addStudent)
}

function* watchEditStudent() {
  yield takeLatest(constants.STUDENT_EDIT.ACTION, editStudent)
}

function* watchActiveToggleStudent() {
  yield takeLatest(constants.STUDENT_ACTIVE_TOGGLE.ACTION, activeToggleStudent)
}

function* watchRemoveStudent() {
  yield takeLatest(constants.STUDENT_REMOVE.ACTION, removeStudent)
}

function* watchFetchCoursesByStudentId() {
  yield takeLatest(constants.STUDENT_FETCH_ENROLLED_BY_STUDENT_ID.ACTION, fetchCoursesByStudentId)
}

function* watchUpdateScheduledToggleContact() {
  yield takeLatest(constants.STUDENT_CONTACTED_SCHEDULED_TESTS.ACTION, updateScheduledToggleContact)
}

function* watchFetchMetris() {
  yield takeLatest(constants.STUDENT_FETCH_METRICS.ACTION, fetchMetrics)
}


/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchStudents),
    fork(watchFetchStudentsSimple),
    fork(watchFetchStudentsById),
    fork(watchAddStudent),
    fork(watchEditStudent),
    fork(watchActiveToggleStudent),
    fork(watchRemoveStudent),
    fork(watchFetchCoursesByStudentId),
    fork(watchFetchStudentScheduleTest),
    fork(watchUpdateScheduledToggleContact),
    fork(watchFetchMetris)
  ])
}
