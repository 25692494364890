export default {
  // fill in when to use
  colors: {
    white: '#FFF',
    transparent: '',
    primary: '#000A66',
    xprimary: '#5768FF',
    warning: '',
    default: '#6c757d',
    success: '#2eb85c',
    danger: '#ff4d4f',


    blue_150: '#cce5ff',
    gray_500: '#333',
  },
  media: {
    table: 768,
    tablet: 768,
    notebook: 1500,
  }
};
