import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'


import ProtectedRoute from './helpers/ProtectedRoute.component'
import { actions as filtersActions } from './redux/modules/filters'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Components
const BaseLayout = React.lazy(() => import('./components/layout/BaseLayout'))

// Pages
const Login = React.lazy(() => import('./pages/auth/Login'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

function AppRoutes() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ local, setLocal ] = useState('')

  useEffect(() => {
    const currentLocal = location.pathname.split('/')[1]
    setLocal(currentLocal)
  }, [location.pathname])

  useEffect(() => {
    dispatch(filtersActions.removeFilters())
    //eslint-disable-next-line
  }, [local])

  return (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route path="/login" name="Login Page" render={props => <Login {...props} />} />
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
        <ProtectedRoute
          allowedRoles={['admin','professor', 'salesman']}
          path="/"
          name="Home"
          component={BaseLayout}
        />
        <Route path="*" render={props => <Page404 {...props} />} />
      </Switch>
    </React.Suspense>
  )
}

export default AppRoutes
