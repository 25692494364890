export const dateFormatBR = (value) => {
  return Intl.DateTimeFormat("pt-BR").format(new Date(value));
};

export const dateFormatSQL = (value) => {
  return new Date(value).toISOString().split("T")[0];
};
export const allMonths = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
