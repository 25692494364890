import { all, fork, call, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as api from '../api/payment'
import { constants } from '../modules/payment'

function* updateNFs(action) {
  try {
    const payload = yield call(api.updateNFs, action.id_order, action.nf_numero, action.nf_url)
    yield put({ type: constants.PAYMENT_UPDATE_NF.SUCCESS, payload })
    action.next && action.next(payload)
    //notifications
    notification.success({
      message: 'Alterações salvas com sucesso!'
    })
  } catch (e) {
    //notifications
    notification.error({
      message: 'Problemas ao salvar!',
      description: e.message || e
    })
    yield put({
      type: constants.PAYMENT_UPDATE_NF.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchPayments(action) {
  try {
    const payload = yield call(api.fetchOrders, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.PAYMENT_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.PAYMENT_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchCourseClassByIdCourse(action) {
  try {
    const payload = yield call(api.fetchByIdCurso, action.id_curso)
    yield put({ type: constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    //notifications
    notification.error({
      message: 'Problemas ao salvar!',
      description: e.message || e
    })
    yield put({
      type: constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


/**
 * Saga
 */

function* watchUpdateNFs() {
  yield takeLatest(constants.PAYMENT_UPDATE_NF.ACTION, updateNFs)
}

function* watchFetchPayments() {
  yield takeLatest(constants.PAYMENT_FETCH.ACTION, fetchPayments)
}

function* watchFetchCourseClassByIdCourse() {
  yield takeLatest(constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.ACTION, fetchCourseClassByIdCourse)
}



/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchUpdateNFs),
    fork(watchFetchPayments),
    fork(watchFetchCourseClassByIdCourse)
  ])
}
