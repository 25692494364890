import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";

//fetchAllSaleLink

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  FECH_ALL_SALE_LINK: createSagaAction("FECH_ALL_SALE_LINK"),
  FECH_SALES_DETAILS: createSagaAction("FECH_SALES_DETAILS"),
};

export const actions = {
  fetchAllSaleLink: (next) => ({
    type: constants.FECH_ALL_SALE_LINK.ACTION,
    next,
  }),
  fetchSalesDetails: (next) => ({
    type: constants.FECH_SALES_DETAILS.ACTION,
    next,
  }),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // FECH_ALL_SALE_LINK
  [constants.FECH_ALL_SALE_LINK.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.FECH_ALL_SALE_LINK.SUCCESS]: (state, action) => {
    //
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  },
  [constants.FECH_ALL_SALE_LINK.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },
  // FECH_SALES_DETAILS
  [constants.FECH_SALES_DETAILS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.FECH_SALES_DETAILS.SUCCESS]: (state, action) => {
    //
    return {
      ...state,
      details: action.payload,
      isLoading: false,
    };
  },
  [constants.FECH_SALES_DETAILS.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : { ...state, isLoading: false };
});
