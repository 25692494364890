import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  EDIT_TEACHER_CLASSES: createSagaAction('EDIT_TEACHER_CLASSES'),
  GET_TEACHER_AVAILABILITY: createSagaAction('GET_TEACHER_AVAILABILITY'),
  EDIT_TEACHER_AVAILABILITY: createSagaAction('EDIT_TEACHER_AVAILABILITY'),
  GET_CLASSES_BY_TEACHER_ID: createSagaAction('GET_CLASSES_BY_TEACHER_ID'),
  ACCEPT_MENTORSHIP: createSagaAction('ACCEPT_MENTORSHIP'),
  CANCEL_MENTORSHIP: createSagaAction('CANCEL_MENTORSHIP'),
  GET_MY_MENTORSHIP: createSagaAction('GET_MY_MENTORSHIP'),
  GET_ALL_MENTORSHIP: createSagaAction('GET_ALL_MENTORSHIP'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  editTeacherClassesList: (formData, next) => ({
    type: constants.EDIT_TEACHER_CLASSES.ACTION,
    formData,
    next
  }),
  getTeacherAvailability: (id, next) => ({
    type: constants.GET_TEACHER_AVAILABILITY.ACTION,
    id,
    next
  }),
  editTeacherAvailability: (formData, next) => ({
    type: constants.EDIT_TEACHER_AVAILABILITY.ACTION,
    formData,
    next
  }),
  getClassesByTeacherId: (id, next) => ({
    type: constants.GET_CLASSES_BY_TEACHER_ID.ACTION,
    id,
    next
  }),
  acceptMentorship: (id, next) => ({
    type: constants.ACCEPT_MENTORSHIP.ACTION,
    id,
    next
  }),
  cancelMentorship: (id, next) => ({
    type: constants.CANCEL_MENTORSHIP.ACTION,
    id,
    next
  }),
  getMyMentorship: (next) => ({
    type: constants.GET_MY_MENTORSHIP.ACTION,
    next
  }),
  getAllMentorship: (next) => ({
    type: constants.GET_ALL_MENTORSHIP.ACTION,
    next
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // EDIT_TEACHER_CLASSES
  [constants.EDIT_TEACHER_CLASSES.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.EDIT_TEACHER_CLASSES.SUCCESS]: (state, action) => {

    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.EDIT_TEACHER_CLASSES.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  
  // GET_TEACHER_AVAILABILITY
  [constants.GET_TEACHER_AVAILABILITY.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.GET_TEACHER_AVAILABILITY.SUCCESS]: (state, action) => {
    //
    return {
      ...state,
      data: action.payload,
      isLoading: false
    }
  },
  [constants.GET_TEACHER_AVAILABILITY.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // EDIT_TEACHER_AVAILABILITY
  [constants.EDIT_TEACHER_AVAILABILITY.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.EDIT_TEACHER_AVAILABILITY.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.EDIT_TEACHER_AVAILABILITY.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // ACCEPT_MENTORSHIP
  [constants.ACCEPT_MENTORSHIP.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.ACCEPT_MENTORSHIP.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.ACCEPT_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // CANCEL_MENTORSHIP
  [constants.CANCEL_MENTORSHIP.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.CANCEL_MENTORSHIP.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.CANCEL_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // GET_MY_MENTORSHIP
  [constants.GET_MY_MENTORSHIP.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.GET_MY_MENTORSHIP.SUCCESS]: (state, action) => {
    return {
      ...state,
      myMentorship: action.payload,
      isLoading: false
    }
  },
  [constants.GET_MY_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // GET_ALL_MENTORSHIP
  [constants.GET_ALL_MENTORSHIP.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.GET_ALL_MENTORSHIP.SUCCESS]: (state, action) => {
    return {
      ...state,
      allMentorship: [...action.payload],
      isLoading: false
    }
  },
  [constants.GET_ALL_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // GET_CLASSES_BY_TEACHER_ID
  [constants.GET_CLASSES_BY_TEACHER_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.GET_CLASSES_BY_TEACHER_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      teacherData: {...action.payload},
      isLoading: false
    }
  },
  [constants.GET_CLASSES_BY_TEACHER_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
}


// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
