import request from '../../shared/request'

export function login(username, password) {
  return request(`login`, {
    method: 'POST',
    body: JSON.stringify({ email: username, password })
  })
}


export function resetPasswordRequest(email) {
  return request(`v1/generate-password`, {
    method: 'POST',
    body: JSON.stringify({ email: email })
  })
}
