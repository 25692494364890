import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  DASHBOARD_FETCH: createSagaAction("DASHBOARD_FETCH"),
  REPORT_FETCH_SALES_BY_COURSE: createSagaAction(
    "REPORT_FETCH_SALES_BY_COURSE"
  ),
  REPORT_FETCH_LAST_SALES: createSagaAction("REPORT_FETCH_LAST_SALES"),
  REPORT_FETCH_COURSE_SALES_BY_MONTH: createSagaAction(
    "REPORT_FETCH_COURSE_SALES_BY_MONTH"
  ),
  REPORT_FETCH_COURSE_SALES_PERFORMANCE: createSagaAction(
    "REPORT_FETCH_COURSE_SALES_PERFORMANCE"
  ),
  REPORT_FETCH_COURSE_SALES_BY_DAY: createSagaAction(
    "REPORT_FETCH_COURSE_SALES_BY_DAY"
  ),
  GET_ALL_COURSES: createSagaAction("GET_ALL_COURSES"),
  GET_COURSECLASS_BY_COURSE_TAG: createSagaAction(
    "GET_COURSECLASS_BY_COURSE_TAG"
  ),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchDashboard: (next) => ({
    type: constants.DASHBOARD_FETCH.ACTION,
    next,
  }),
  fetchSalesByCourse: (filters = {}, next) => ({
    type: constants.REPORT_FETCH_SALES_BY_COURSE.ACTION,
    filters,
    next,
  }),
  fetchLastSales: (next) => ({
    type: constants.REPORT_FETCH_LAST_SALES.ACTION,
    next,
  }),
  fetchCourseSalesByMonth: (next) => ({
    type: constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.ACTION,
    next,
  }),
  fetchCourseSalesByDay: (next) => ({
    type: constants.REPORT_FETCH_COURSE_SALES_BY_DAY.ACTION,
    next,
  }),
  fetchSalesPerformance: (filters, next) => ({
    type: constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.ACTION,
    filters,
    next,
  }),
  fetchAllCourses: (next) => ({
    type: constants.GET_ALL_COURSES.ACTION,
    next,
  }),
  fetchCourseClassByCourseTag: (tag, next) => ({
    type: constants.GET_COURSECLASS_BY_COURSE_TAG.ACTION,
    tag,
    next,
  }),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // DASHBOARD_FETCH
  [constants.DASHBOARD_FETCH.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.DASHBOARD_FETCH.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  },
  [constants.DASHBOARD_FETCH.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },
  // SALES BY COURSE
  [constants.REPORT_FETCH_SALES_BY_COURSE.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.REPORT_FETCH_SALES_BY_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  },
  [constants.REPORT_FETCH_SALES_BY_COURSE.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoading: false,
    };
  },
  // LAST SALES
  [constants.REPORT_FETCH_LAST_SALES.ACTION]: (state) => {
    return { ...state, error: false, isLoadingLastSales: true };
  },
  [constants.REPORT_FETCH_LAST_SALES.SUCCESS]: (state, action) => {
    return {
      ...state,
      dataLastSales: action.payload,
      isLoadingLastSales: false,
    };
  },
  [constants.REPORT_FETCH_LAST_SALES.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingLastSales: false,
    };
  },
  // BY MONTH
  [constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.ACTION]: (state) => {
    return { ...state, error: false, isLoadingSalesByMonth: true };
  },
  [constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.SUCCESS]: (state, action) => {
    return {
      ...state,
      dataSalesByMonth: action.payload,
      isLoadingSalesByMonth: false,
    };
  },
  // BY DAY
  [constants.REPORT_FETCH_COURSE_SALES_BY_DAY.ACTION]: (state) => {
    return { ...state, error: false, isLoadingSalesByDay: true };
  },
  [constants.REPORT_FETCH_COURSE_SALES_BY_DAY.SUCCESS]: (state, action) => {
    return {
      ...state,
      dataSalesByDay: action.payload,
      isLoadingSalesByDay: false,
    };
  },
  [constants.REPORT_FETCH_COURSE_SALES_BY_DAY.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingSalesByDay: false,
    };
  },
  // BY PERFORMANCE
  [constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.ACTION]: (state) => {
    return { ...state, error: false, isLoadingSalesPerformance: true };
  },
  [constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.SUCCESS]: (
    state,
    action
  ) => {
    return {
      ...state,
      dataSalesPerformance: action.payload,
      isLoadingSalesPerformance: false,
    };
  },
  [constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingSalesPerformance: false,
    };
  },
  // GET_ALL_COURSES
  [constants.GET_ALL_COURSES.ACTION]: (state) => {
    return { ...state, error: false, isLoadingCourses: true };
  },
  [constants.GET_ALL_COURSES.SUCCESS]: (state, action) => {
    return {
      ...state,
      courses: action.payload,
      isLoadingCourses: false,
    };
  },
  [constants.GET_ALL_COURSES.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingCourses: false,
    };
  },
  // GET_COURSECLASS_BY_COURSE_TAG
  [constants.GET_COURSECLASS_BY_COURSE_TAG.ACTION]: (state) => {
    return { ...state, error: false, isLoadingCourseClasses: true };
  },
  [constants.GET_COURSECLASS_BY_COURSE_TAG.SUCCESS]: (state, action) => {
    return {
      ...state,
      courseClasses: action.payload,
      isLoadingCourseClasses: false,
    };
  },
  [constants.GET_COURSECLASS_BY_COURSE_TAG.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingCourseClasses: false,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
  isLoadingCourses: false,
  isLoadingCourseClasses: false,
  courses: [],
  courseClasses: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
