import { createReducer } from '../../shared/reducers';
import { createSagaAction } from '../../shared/sagas'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  ACCESS_FETCH: createSagaAction('ACCESS_FETCH'),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAll: (items) => ({
    type: constants.ACCESS_FETCH.ACTION,
    items
  })
};

// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // AFFILIATE_FETCH
  [constants.ACCESS_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.ACCESS_FETCH.SUCCESS]: (state, payload) => {
    return {
        ...state,
        isLoading: false,
        data: payload.items
    };
  },
  [constants.ACCESS_FETCH.FAILED]: state => {
    return { ...state, error: true, isLoading: false, data: [] };
  }
};

export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  data: []
};

export default createReducer(initialState, (state, action) => {
  const  handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, isLoading: false }
});

