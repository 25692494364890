import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  PAYMENT_UPDATE_NF: createSagaAction('PAYMENT_UPDATE_NF'),
  PAYMENT_FETCH: createSagaAction('PAYMENT_FETCH'),
  PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE: createSagaAction('PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  updateNFs: (id_order, nf_numero, nf_url, next) => ({
    type: constants.PAYMENT_UPDATE_NF.ACTION,
    id_order, 
    nf_numero, 
    nf_url,
    next
  }),
  fetchPayments: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.PAYMENT_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchCourseClassByIdCourse: (id_curso, next) => ({
    type:constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.ACTION,
    id_curso,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // Update NFS
  // PAYMENT_FETCH
  [constants.PAYMENT_UPDATE_NF.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.PAYMENT_UPDATE_NF.SUCCESS]: (state, action) => {
    return {
      ...state,
      // data: action.payload,
      isLoading: false
    }
  },
  [constants.PAYMENT_UPDATE_NF.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // PAYMENT_FETCH
  [constants.PAYMENT_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.PAYMENT_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.PAYMENT_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  //
  //
  [constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      turmas: action.payload
    }
  },
  [constants.PAYMENT_FETCH_COURSECLASS_BY_ID_COURSE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
