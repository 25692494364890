import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  FILES_ADD: createSagaAction('FILES_ADD')
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  addFiles: (formData, next) => ({
    type: constants.FILES_ADD.ACTION,
    formData,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // FILES_ADD
  [constants.FILES_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.FILES_ADD.SUCCESS]: state => {
    return {
      ...state,
      isLoading: false
    }
  },
  [constants.FILES_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
