import _ from 'lodash'
import { IGabaritoModule } from '../../shared/models/gabarito/IGabaritoModule';
export const getGabaritoState = state => _.get(state, 'gabarito', {});


export const makeSequencialNumberQuestion = (gabarito: IGabaritoModule) => {
  let index = 0;
  gabarito.modules.forEach(module => (
    module.questions.map(question => {
      index++;
      question.sequencial_number = index;
      return {
        ...question,
        sequencial_number: index
      };
    })
  ));

  return gabarito;
};
