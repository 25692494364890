import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'
import { PaginationProps } from '../../shared/models/crm/types';
import { IState } from '../../shared/models/crm/IState';
import { ContactProps } from '../../pages/crm/Prospection/components/Contact/types';
import { FilterProps } from '../../pages/crm/shared/types';
import { IStudent } from '../../shared/models/crm/IStudent';
import { IUser } from '../../shared/models/crm/IUser';
import { IContact } from '../../shared/models/crm/IContact';
import { SuccessCallback, ErrorCallback } from '../../shared/models/CallbackType';
import { ITypeContact } from '../../shared/models/crm/ITypeContact';


// Types;
export type FetchStudentsProps = {
  filters: FilterProps;
  pagination: PaginationProps;
};

export type FetchStudentBySalesManProps = {
  filters: FilterProps;
  pagination: PaginationProps;
};

export type FetchHistoric = {
  filters: { id_student: number }
};

export type AddContactProps = ContactProps;

export type LinkStudentsToSalesmanProps = {
  salesmanId: number;
  filters: FilterProps;
  eventType: 'store' | 'update';
};

export type LinkSelectedStudentsProps = {
  salesmanId: number;
  studentId: number;
};


// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  CRM_FETCH_STUDENTS: createSagaAction('CRM_FETCH_STUDENTS'),
  CRM_FETCH_STUDENTS_BY_SALESMAN: createSagaAction('CRM_FETCH_STUDENTS_BY_SALESMAN'),
  CRM_ADD_CONTACT: createSagaAction('CRM_ADD_CONTACT'),
  CRM_FETCH_HISTORIC: createSagaAction('CRM_FETCH_HISTORIC'),
  CRM_FIND_STUDENT: createSagaAction('CRM_FIND_STUDENT'),
  CRM_LINK_STUDENS_BY_SALESMAN: createSagaAction('CRM_LINK_STUDENS_BY_SALESMAN'),
  CRM_FETCH_SALESMANS: createSagaAction('CRM_FETCH_SALESMANS'),
  CRM_CLEAR_STUDENTS: 'CRM_CLEAR_STUDENTS',
  CRM_REMOVE_LINK_BY_STUDENT: createSagaAction('CRM_REMOVE_LINK_BY_STUDENT'),
  CRM_LINK_SELECTED_STUDENTS: createSagaAction('CRM_LINK_SELECTED_STUDENTS'),
  CRM_FETCH_TYPE_CONTACTS: createSagaAction('CRM_FETCH_TYPE_CONTACTS'),
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchStudents: (params: FetchStudentsProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_FETCH_STUDENTS.ACTION,
    params,
    success,
    error
  }),
  clearStudents: () => ({
    type: constants.CRM_CLEAR_STUDENTS
  }),
  fetchStudentsBySalesman: (params: FetchStudentBySalesManProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_FETCH_STUDENTS_BY_SALESMAN.ACTION,
    params,
    success,
    error
  }),
  fetchHistoric: (params: FetchHistoric, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_FETCH_HISTORIC.ACTION,
    params,
    success,
    error
  }),
  fetchSalesmans: (success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_FETCH_SALESMANS.ACTION,
    success,
    error
  }),
  fetchTypeContacts: (callback?: SuccessCallback) => ({
    type: constants.CRM_FETCH_TYPE_CONTACTS.ACTION,
    callback
  }),
  findStudent: (id: number, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_FIND_STUDENT.ACTION,
    id,
    success,
    error
  }),
  addContact:(formData: AddContactProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_ADD_CONTACT.ACTION,
    formData,
    success,
    error
  }),
  linkStudentsBySalesman: (formData: LinkStudentsToSalesmanProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_LINK_STUDENS_BY_SALESMAN.ACTION,
    formData,
    success,
    error
  }),
  linkSelectedStudents: (formData: LinkSelectedStudentsProps[], eventType: string, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_LINK_SELECTED_STUDENTS.ACTION,
    formData,
    eventType,
    success,
    error,
  }),
  removeLinkByStudent: (studentId: number, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.CRM_REMOVE_LINK_BY_STUDENT.ACTION,
    studentId,
    success,
    error
  })
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // CRM_FETCH_STUDENTS_BY_SALESMAN
  [constants.CRM_FETCH_STUDENTS_BY_SALESMAN.ACTION]: (state: IState) => {
    return { ...state, isLoading: true };
  },
  [constants.CRM_FETCH_STUDENTS_BY_SALESMAN.SUCCESS]: (state: IState, action) => {
    return {
      ...state,
      isLoading: false,
      students: action.payload?.data ?? [],
      pagination: {
        current_page: action.payload?.current_page,
        last_page: action.payload?.last_page
      }
    };
  },
  [constants.CRM_FETCH_STUDENTS_BY_SALESMAN.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoading: false, error: true, message };
  },

  // CRM_FETCH_STUDENTS
  [constants.CRM_FETCH_STUDENTS.ACTION]: (state: IState) => {
    return { ...state, isLoading: true };
  },
  [constants.CRM_FETCH_STUDENTS.SUCCESS]: (state: IState, action) => {
    return {
      ...state,
      isLoading: false,
      students: action.payload?.data ?? [],
      pagination: {
        current_page: action.payload?.current_page,
        last_page: action.payload?.last_page
      }
    };
  },
  [constants.CRM_FETCH_STUDENTS.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoading: false, error: true, message };
  },

  // CRM_FETCH_SALESMANS
  [constants.CRM_FETCH_SALESMANS.ACTION]: (state: IState) => {
    return { ...state, isLoading: true };
  },
  [constants.CRM_FETCH_SALESMANS.SUCCESS]: (state: IState, action) => {
    return {
      ...state,
      isLoading: false,
      salesmans: action.payload ?? [],
    };
  },
  [constants.CRM_FETCH_SALESMANS.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoading: false, error: true, message };
  },
  // CRM_FETCH_TYPE_CONTACTS
  [constants.CRM_FETCH_TYPE_CONTACTS.ACTION]: (state: IState) => {
    return { ...state, error: false };
  },
  [constants.CRM_FETCH_TYPE_CONTACTS.SUCCESS]: (state: IState, action: {
    payload: ITypeContact[]
  }) => {
    return { ...state, error: false, typeContacts: [ ...action.payload ] };
  },
  [constants.CRM_FETCH_TYPE_CONTACTS.FAILED]: (state: IState) => {
    return { ...state, error: true };
  },

  // CRM_ADD_CONTACT
  [constants.CRM_ADD_CONTACT.ACTION]: (state: IState) => {
    return { ...state, isLoadingAddContact: true };
  },
  [constants.CRM_ADD_CONTACT.SUCCESS]: (state: IState) => {
    return { ...state, isLoadingAddContact: false };
  },
  [constants.CRM_ADD_CONTACT.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoadingAddContact: false, error: true, message };
  },

  // CRM_FETCH_HISTORIC
  [constants.CRM_FETCH_HISTORIC.ACTION]: (state: IState) => {
    return { ...state, isLoadingHistoric: true };
  },
  [constants.CRM_FETCH_HISTORIC.SUCCESS]: (state: IState, action) => {
    return {
      ...state,
      isLoadingHistoric: false,
      historic: action.payload ?? []
    };
  },
  [constants.CRM_FETCH_HISTORIC.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoadingHistoric: false, error: true, message };
  },

  // CRM_FIND_STUDENT
  [constants.CRM_FIND_STUDENT.ACTION]: (state: IState) => {
    return { ...state, isLoading: true };
  },
  [constants.CRM_FIND_STUDENT.SUCCESS]: (state: IState, action) => {
    return {
      ...state,
      isLoading: false,
      student: action.payload ?? {}
    };
  },
  [constants.CRM_FIND_STUDENT.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoading: false, error: true, message };
  },

  // CRM_LINK_STUDENS_TO_SALESMAN
  [constants.CRM_LINK_STUDENS_BY_SALESMAN.ACTION]: (state: IState) => {
    return { ...state, isLoadingLink: true };
  },
  [constants.CRM_LINK_STUDENS_BY_SALESMAN.SUCCESS]: (state: IState) => {
    return {
      ...state,
      isLoadingLink: false
    };
  },
  [constants.CRM_LINK_STUDENS_BY_SALESMAN.FAILED]: (state: IState, action) => {
    const { message } = action;
    return { ...state, isLoadingLink: false, error: true, message };
  },
  [constants.CRM_CLEAR_STUDENTS]: (state: IState) => {
    return { ...state, students: [] };
  },
  // CRM_REMOVE_LINK_BY_STUDENT
  [constants.CRM_REMOVE_LINK_BY_STUDENT.ACTION]: (state: IState) => ({
    ...state
  }),
  [constants.CRM_REMOVE_LINK_BY_STUDENT.SUCCESS]: (state: IState, action: { studentId: number }) => {
    const students = state.students.map((student: IStudent) => {
      if (student.id_student === action.studentId) {
        return {
          ...student,
          crm_salesmen: []
        }
      }

      return student;
    });

    return {
      ...state,
      students
    };
  },
  //CRM_LINK_SELECTED_STUDENTS
  [constants.CRM_LINK_SELECTED_STUDENTS.ACTION]: (state: IState) => ({
    ...state,
    isLoadingLink: true
  }),
  [constants.CRM_LINK_SELECTED_STUDENTS.SUCCESS]: (state: IState) => {
    return {
      ...state,
      isLoadingLink: false
    };
  },
  [constants.CRM_LINK_SELECTED_STUDENTS.FAILED]: (state: IState) => ({
    ...state,
    isLoadingLink: false
  }),
};

// ------------------------------------
// INITIAL_STATE
// ------------------------------------
export const initialState: IState = {
  error: false,
  message: '',
  isLoading: false,
  isLoadingAddContact: false,
  isLoadingHistoric: false,
  isLoadingLink: false,
  pagination: {
    current_page: 1,
    last_page: 1,
  },
  student:  {} as IStudent,
  contact: {} as IContact,
  students: [] as IStudent[],
  salesmans: [] as IUser[],
  historic: [] as IContact[],
  typeContacts: [] as ITypeContact[],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, isLoading: false }
});
