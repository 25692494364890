import request from '../../shared/request';
import { generateFilters } from '../../helpers';
import { IQuestion } from '../../shared/models/quiz/IQuestion';
import { FormikValues } from 'formik';
import { ICertification } from '../../shared/models/quiz/ICertification';
import {IFamily} from "../../shared/models/quiz/IFamily";

export const fetchAll = (_filters: FormikValues = {}): Promise<IQuestion[]> => {
  const page = _filters.page;
  const per_page = _filters.per_page;

  delete _filters.page;
  delete _filters.per_page;

  const filters = JSON.stringify({}) !== JSON.stringify(_filters)
    ? generateFilters(_filters, 'service')
    : null;

  return request('v1/services/quiz/questions', {
    filters,
    qsParams: {
      page,
      per_page
    }
  });
};

export const fetchTopics = (): Promise<ICertification[]> => {
  return request('v1/services/quiz/topics/all');
};

export const fetchFamilies = (): Promise<IFamily[]> => {
  return request('v1/services/quiz/family/all');
};

export const find = (id: number): Promise<IQuestion> => {
  return request(`v1/services/quiz/questions/${id}`);
};

export const store = (formData: FormikValues): Promise<boolean> => {
  return request('v1/services/quiz/questions', {
    method: 'POST',
    body: JSON.stringify(formData)
  });
};

export const update = (formData: FormikValues, id: number): Promise<boolean> => {
  return request(`v1/services/quiz/questions/${id}`, {
    method: 'PUT',
    body: JSON.stringify(formData)
  });
};

export const destroy = (id: number): Promise<boolean> => {
  return request(`v1/services/quiz/questions/${id}`, {
    method: 'DELETE'
  });
};
