import request from '../../shared/request';

import { generateFilters } from '../../helpers';
import { FetchAllProps, TotalConvertedType } from '../modules/lead';
import { IPaginationData } from '../../shared/models/lead/IPaginationData';
import { ITypeLead } from '../../shared/models/lead/ITypeLead';
import { IConvertedLead } from '../../shared/models/lead/IConvertedLead';

export const fetchAll = (params: FetchAllProps): Promise<IPaginationData> => {
  const { pagination, filters } = params;

  return request('v1/services/leads', {
    qsParams: {
      per_page: pagination.itemsPerPage,
      page: pagination.currentPage
    },
    filters: generateFilters(filters, 'service')
  })
};

export const store = (formData: {}): Promise<void> => {
  return request('v1/services/leads', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
};

export const fetchTypeLeads = (): Promise<ITypeLead[]> => {
  return request('v1/crud/typestudent?get=all&order[name]=ASC');
};

export const fetchAvailableTypesLeads = () => {
  return request('v1/services/leads/panel-types');
};

export const fetchTotalsConverted = (filters: TotalConvertedType) => {
  return request(`v1/services/leads/panel-total/${filters.typeId}?_=`, {
    filters: generateFilters(filters, 'service')
  });
};

export const fetchConvertedLeads = (filters = {}, pagination: {
  itemsPerPage?: number;
  currentPage?: number;
} = {}): Promise<IConvertedLead> => {
  return request('v1/services/leads/panel', {
    qsParams: {
      per_page: pagination.itemsPerPage,
      page: pagination.currentPage
    },
    filters: generateFilters(filters, 'service')
  });
};
