import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import * as api from "../api/descriptionsStudent";
import { constants } from "../modules/descriptionStudent";

function* fetchAllData(action) {
  try {
    const items = yield call(api.fetchAllDescriptions, action.items);
    yield put({ type: constants.GET_DATA_DESCRIPTION_FETCH.SUCCESS, items });
  } catch (e) {
    yield put({
      type: constants.GET_DATA_DESCRIPTION_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

/**
 * Saga
 */
function* getDescriptionDataFecth() {
  yield takeLatest(constants.GET_DATA_DESCRIPTION_FETCH.ACTION, fetchAllData);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([fork(getDescriptionDataFecth)]);
}
