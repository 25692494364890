import { notification } from 'antd'
import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import * as api from '../api/teacherArea'
import { constants } from '../modules/teacherArea'

function* fetchGoals(action) {
  try {
    const payload = yield call(api.fetchGoals, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.TEACHER_AREA_FETCH_GOALS.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.TEACHER_AREA_FETCH_GOALS.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}
function* storeGoal(action) {
  try {
    const payload = yield call(api.storeGoal, action.url)
    yield put({ type: constants.TEACHER_AREA_STORE_GOAL.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.TEACHER_AREA_STORE_GOAL.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}
function* removeGoal(action) {
  try {
    notification.info({
      key: "course_remove",
      message: "Deletando meta",
    });

    const payload = yield call(api.removeGoal, action.id)
    yield put({ type: constants.TEACHER_AREA_REMOVE_GOAL.SUCCESS, payload })

    // notifications
    notification.close("course_remove");
    notification.success({
      message: "Meta deletada com sucesso",
    });

    action.next && action.next(payload)
  } catch (e) {
    notification.close("course_remove");
    notification.error({
      message: "Problemas ao deletar a meta",
      description: e.message || e,
    });

    yield put({
      type: constants.TEACHER_AREA_REMOVE_GOAL.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
function* watchFetchGoals() {
  yield takeLatest(constants.TEACHER_AREA_FETCH_GOALS.ACTION, fetchGoals)
}

function* watchStoreGoal() {
  yield takeLatest(constants.TEACHER_AREA_STORE_GOAL.ACTION, storeGoal)
}

function* watchRemoveGoal() {
  yield takeLatest(constants.TEACHER_AREA_REMOVE_GOAL.ACTION, removeGoal)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchGoals),
    fork(watchStoreGoal),
    fork(watchRemoveGoal)
  ])
}
