import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import { notification } from "antd";

import { constants, SuccessCallback, ErrorCallback } from '../modules/quizQuestions';
import * as api from '../api/quizQuestions';
import { FormikValues } from "formik";

function* fetchAll(action: {
  type: string,
  filters: FormikValues,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  try {
    const payload = yield call(api.fetchAll, action.filters);

    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_ALL.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_ALL.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchTopics(action: {
  type: string,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  try {
    const payload = yield call(api.fetchTopics);

    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_TOPICS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_TOPICS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchFamilies(action: {
  type: string,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  try {
    const payload = yield call(api.fetchFamilies);

    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_FAMILIES.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUIZ_QUESTIONS_FETCH_FAMILIES.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* find(action: {
  type: string,
  id: number,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  try {
    const payload = yield call(api.find, action.id);

    yield put({
      type: constants.QUIZ_QUESTIONS_FIND.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUIZ_QUESTIONS_FIND.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* store(action: {
  type: string,
  formData: FormikValues,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  notification.info({
    key: "quizQuestion_add",
    message: "Criando nova questão",
  });

  try {
    const payload = yield call(api.store, action.formData);
    yield put({
      type: constants.QUIZ_QUESTIONS_STORE.SUCCESS
    });

    notification.close('quizQuestion_add');
    notification.success({
      message: "A questão foi criado com sucesso !",
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close('quizQuestion_add');
    yield put({
      type: constants.QUIZ_QUESTIONS_STORE.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* update(action: {
  type: string,
  formData: FormikValues,
  id: number,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  notification.info({
    key: "quizQuestion_edit",
    message: "Atualizando questão...",
  });

  try {
    const payload = yield call(api.update, action.formData, action.id);
    yield put({
      type: constants.QUIZ_QUESTIONS_UPDATE.SUCCESS
    });

    notification.close('quizQuestion_edit');
    notification.success({
      message: "Questão atualizada com sucesso !",
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close('quizQuestion_edit');
    yield put({
      type: constants.QUIZ_QUESTIONS_UPDATE.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* destroy(action: {
  type: string,
  id: number,
  success: SuccessCallback,
  error: ErrorCallback
}) {
  notification.info({
    key: "quizQuestion_destroy",
    message: "Deletando questão",
  });

  try {
    const payload = yield call(api.destroy, action.id);
    yield put({
      type: constants.QUIZ_QUESTIONS_DESTROY.SUCCESS
    });

    notification.close('quizQuestion_destroy');
    notification.success({
      message: "Questão foi excluida com sucesso !",
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close('quizQuestion_destroy');
    yield put({
      type: constants.QUIZ_QUESTIONS_DESTROY.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}


function* watchFetchAll() {
  yield takeLatest(constants.QUIZ_QUESTIONS_FETCH_ALL.ACTION, fetchAll);
}

function* watchFetchTopics() {
  yield takeLatest(constants.QUIZ_QUESTIONS_FETCH_TOPICS.ACTION, fetchTopics);
}

function* watchFetchFamilies() {
  yield takeLatest(constants.QUIZ_QUESTIONS_FETCH_FAMILIES.ACTION, fetchFamilies);
}

function* watchFind() {
  yield takeLatest(constants.QUIZ_QUESTIONS_FIND.ACTION, find);
}

function* watchStore() {
  yield takeLatest(constants.QUIZ_QUESTIONS_STORE.ACTION, store);
}

function* watchUpdate() {
  yield takeLatest(constants.QUIZ_QUESTIONS_UPDATE.ACTION, update);
}

function* watchDestroy() {
  yield takeLatest(constants.QUIZ_QUESTIONS_DESTROY.ACTION, destroy);
}

export function* rootSaga() {
  yield all([
    fork(watchFetchAll),
    fork(watchFetchTopics),
    fork(watchFetchFamilies),
    fork(watchStore),
    fork(watchUpdate),
    fork(watchDestroy),
    fork(watchFind)
  ]);
}
