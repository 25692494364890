import request from "../../shared/request";
import { generateFilters } from "../../helpers";

// export function fetchAllCourses() {
//   return request('v1/services/cursos/findAll')
// }

export function fetchLessonsById(idCourse, qsParams = {}) {
  return request(`v1/services/aulas/cma/${idCourse}`, {
    qsParams
  });
}

export function fetchAllCourses(filters) {
  return request("v1/services/cursos/findAll", {
    qsParams: {
      ...filters,
    },
  });
}

export function fetchAll(currentPage, itemsPerPage, filters = {}) {
  return request("v1/crud/cursos", {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
      append: ["matriculados"],
    },
    filters: generateFilters(filters),
  });
}

export function fetchById(id) {
  return request(`v1/crud/cursos/${id}`);
}

export function add(formData) {
  return request(`v1/crud/cursos`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function edit(formData) {
  return request(`v1/crud/cursos/${formData.id}`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function activeToggle(id, formData) {
  return request(`v1/crud/cursos/${id}`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function remove(id) {
  return request(`v1/crud/cursos/${id}`, {
    method: "DELETE",
  });
}

export function enroll(enrollments) {
  return request(`v1/services/cursos/enroll`, {
    method: "POST",
    body: JSON.stringify(enrollments),
  });
}

export function unEnroll(courseId, studentId) {
  return request(`v1/services/cursos/unenroll`, {
    method: "POST",
    body: JSON.stringify({ id_aluno: studentId, id_curso: courseId }),
  });
}

export function linkCourseToClass(courseId, classes) {
  return request(`v1/services/cursos/lectures/link/${courseId}`, {
    method: "POST",
    body: JSON.stringify(classes),
  });
}

export function printBook(courseId) {
  return request(`v1/services/cursos/generatebook/${courseId}`);
}

export function cloneCourse(courseId) {
  return request(`v1/services/cursos/clone/${courseId}`);
}

export function fetchExportCourseById(id) {
  return request(`v1/services/cursos/exportaalunos/csv/${id}`);
}

export function fetchCourseFamilies() {
  return request(`v1/services/course-categories?_all=true`);
}
