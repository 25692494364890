import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/affiliate'
import { constants } from '../modules/affiliate'
import { actions as affiliateActions } from '../modules/affiliate'

function* fetchaffiliates(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.AFFILIATE_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.AFFILIATE_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchAllProfessors(action) {
  try {
    const payload = yield call(api.fetchAllProfessors);
    yield put({ type: constants.AFFILIATE_FETCH_ALL_PROFESSORS.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.AFFILIATE_FETCH_ALL_PROFESSORS.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
};

function* fetchaffiliatesById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.AFFILIATE_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.AFFILIATE_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addaffiliate(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'affiliate_add',
      message: 'Adicionando afiliado'
    })

    const payload = yield call(api.add, action.formData)
    yield put({ type: constants.AFFILIATE_ADD.SUCCESS, payload })

    // notifications
    notification.close('affiliate_add')
    notification.success({
      message: 'Afiliado adicionada com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('affiliate_add')
    notification.error({
      message: 'Problemas ao adicionar o afiliado',
      description: e.message || e
    })
    yield put({
      type: constants.AFFILIATE_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editaffiliate(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'affiliate_edit',
      message: 'Editando afiliado'
    })

    const payload = yield call(api.edit, action.formData)
    yield put({ type: constants.AFFILIATE_EDIT.SUCCESS, payload })

    // notifications
    notification.close('affiliate_edit')
    notification.success({
      message: 'Afiliado editada com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('affiliate_edit')
    notification.error({
      message: 'Problemas ao editar a afiliado',
      description: e.message || e
    })
    yield put({
      type: constants.AFFILIATE_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeaffiliate(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'affiliate_remove',
      message: 'Deletando afiliado'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.AFFILIATE_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('affiliate_remove')
    notification.success({
      message: 'Afiliado deletado com sucesso'
    })
    yield put(affiliateActions.fetchAffiliates())
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('affiliate_remove')
    notification.error({
      message: 'Problemas ao deletar o afiliado',
      description: e.message || e
    })
    yield put({
      type: constants.AFFILIATE_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


/**
 * Saga
 */
function* watchFetchaffiliates() {
  yield takeLatest(constants.AFFILIATE_FETCH.ACTION, fetchaffiliates)
}

function* watchFetchAllProfessors() {
  yield takeLatest(constants.AFFILIATE_FETCH_ALL_PROFESSORS.ACTION, fetchAllProfessors)
}

function* watchFetchaffiliatesById() {
  yield takeLatest(constants.AFFILIATE_FETCH_BY_ID.ACTION, fetchaffiliatesById)
}

function* watchAddaffiliate() {
  yield takeLatest(constants.AFFILIATE_ADD.ACTION, addaffiliate)
}

function* watchEditaffiliate() {
  yield takeLatest(constants.AFFILIATE_EDIT.ACTION, editaffiliate)
}

function* watchRemoveaffiliate() {
  yield takeLatest(constants.AFFILIATE_REMOVE.ACTION, removeaffiliate)
}


/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchaffiliates),
    fork(watchFetchAllProfessors),
    fork(watchFetchaffiliatesById),
    fork(watchAddaffiliate),
    fork(watchEditaffiliate),
    fork(watchRemoveaffiliate)
  ])
}
