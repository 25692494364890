import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  AFFILIATE_FETCH: createSagaAction('AFFILIATE_FETCH'),
  AFFILIATE_FETCH_BY_ID: createSagaAction('AFFILIATE_FETCH_BY_ID'),
  AFFILIATE_ADD: createSagaAction('AFFILIATE_ADD'),
  AFFILIATE_EDIT: createSagaAction('AFFILIATE_EDIT'),
  AFFILIATE_REMOVE: createSagaAction('AFFILIATE_REMOVE'),
  AFFILIATE_FETCH_ALL_PROFESSORS: createSagaAction('AFFILIATE_FETCH_ALL_PROFESSORS')
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAffiliates: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.AFFILIATE_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchAffiliatesById: (id, next) => ({
    type: constants.AFFILIATE_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  addAffiliate: (formData, next) => ({
    type: constants.AFFILIATE_ADD.ACTION,
    formData,
    next
  }),
  editAffiliate: (formData, next) => ({
    type: constants.AFFILIATE_EDIT.ACTION,
    formData,
    next
  }),
  removeAffiliate: (id, next) => ({
    type: constants.AFFILIATE_REMOVE.ACTION,
    id,
    next
  }),
  fetchAllProfessors: (next) => ({
    type: constants.AFFILIATE_FETCH_ALL_PROFESSORS.ACTION,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // AFFILIATE_FETCH
  [constants.AFFILIATE_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.AFFILIATE_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // AFFILIATE_FETCH_BY_ID
  [constants.AFFILIATE_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      affiliate: { ...action.payload },
      isLoading: false
    }
  },
  [constants.AFFILIATE_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // AFFILIATE_ADD
  [constants.AFFILIATE_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.affiliateServiceReturn,
      isLoading: false
    }
  },
  [constants.AFFILIATE_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // AFFILIATE_EDIT
  [constants.AFFILIATE_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.affiliateServiceReturn,
      isLoading: false
    }
  },
  [constants.AFFILIATE_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // AFFILIATE_REMOVE
  [constants.AFFILIATE_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.AFFILIATE_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  [constants.AFFILIATE_FETCH_ALL_PROFESSORS.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AFFILIATE_FETCH_ALL_PROFESSORS.SUCCESS]: (state, action) => {
    return {
      ...state,
      professors: action.payload,
      isLoading: false
    }
  },
  [constants.AFFILIATE_FETCH_ALL_PROFESSORS.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  professors: []
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
