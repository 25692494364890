import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";
import { IGabaritoModule } from "../../shared/models/gabarito/IGabaritoModule";
import { IGabaritosState } from "../../shared/models/gabarito/IState";
import { AnyAction } from "redux";
import { makeSequencialNumberQuestion } from '../selectors/gabarito';

// ------------------------------------
// Types
// ------------------------------------

export type SuccessCallback = ((payload: unknown) => void) | null;
export type ErrorCallback = (() => void) | null;

export type FormDataProps = {
  id?: number;
  name: string;
  exam_date: string;
};
export type FormDataAnswers = {
  id_gabarito_question: number;
  id_gabarito_question_answer: number | null;
};

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  GABARITO_FETCH_ALL: createSagaAction("GABARITO_FETCH_ALL"),
  GABARITO_FIND_BY_ID: createSagaAction("GABARITO_FIND_BY_ID"),
  GABARITO_STORE: createSagaAction("GABARITO_STORE"),
  GABARITO_UPDATE: createSagaAction("GABARITO_UPDATE"),
  GABARITO_SEND_EMAIL: createSagaAction("GABARITO_SEND_EMAIL"),
  GABARITO_UPDATE_ANSWERS: createSagaAction("GABARITO_UPDATE_ANSWERS"),
  GABARITO_DELETE: createSagaAction("GABARITO_DELETE"),
  GABARITO_CHANGED_NULLED: createSagaAction("GABARITO_CHANGED_NULLED")
};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAll: (
    filters = {},
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_FETCH_ALL.ACTION,
    filters,
    success,
    error,
  }),
  findById: (
    id: number,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_FIND_BY_ID.ACTION,
    id,
    success,
    error,
  }),
  store: (
    formData: FormDataProps,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_STORE.ACTION,
    formData,
    success,
    error,
  }),
  update: (
    formData: FormDataProps,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_UPDATE.ACTION,
    formData,
    success,
    error,
  }),
  sendEmail: (
    gabaritoId: number,
    emailType: string,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_SEND_EMAIL.ACTION,
    emailType,
    gabaritoId,
    success,
    error,
  }),
  updateAnswers: (
    gabaritoId: number,
    withoutFeedback: boolean,
    formData: FormDataAnswers[],
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_UPDATE_ANSWERS.ACTION,
    gabaritoId,
    formData,
    withoutFeedback,
    success,
    error,
  }),
  delete: (
    gabaritoId: number,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_DELETE.ACTION,
    gabaritoId,
    success,
    error,
  }),
  changedNulled: (
    questionId: number,
    success: SuccessCallback = null,
    error: ErrorCallback = null
  ) => ({
    type: constants.GABARITO_CHANGED_NULLED.ACTION,
    questionId,
    success,
    error,
  })
};

const updateAfterDelete = (state: IGabaritosState, action: AnyAction) => {
  return state.gabaritos.filter(
    (gabarito) => gabarito.id_gabarito !== action.gabaritoId
  );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // GABARITO_FETCH_ALL
  [constants.GABARITO_FETCH_ALL.ACTION]: (state: IGabaritosState) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.GABARITO_FETCH_ALL.FAILED]: (state: IGabaritosState) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.GABARITO_FETCH_ALL.SUCCESS]: (state: IGabaritosState, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      gabaritos: [...action.payload],
    };
  },

  // GABARITO_FIND_BY_ID
  [constants.GABARITO_FIND_BY_ID.ACTION]: (state: IGabaritosState) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.GABARITO_FIND_BY_ID.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, isLoading: false, message };
  },
  [constants.GABARITO_FIND_BY_ID.SUCCESS]: (state: IGabaritosState, action: { payload: IGabaritoModule }) => {
    const gabarito = makeSequencialNumberQuestion(action.payload);

    return {
      ...state,
      error: false,
      isLoading: false,
      gabarito,
    };
  },

  //GABARITO_STORE
  [constants.GABARITO_STORE.ACTION]: (state) => {
    return { ...state, error: false, saveLoading: true };
  },
  [constants.GABARITO_STORE.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, saveLoading: false, message };
  },
  [constants.GABARITO_STORE.SUCCESS]: (state: IGabaritosState) => {
    return {
      ...state,
      error: false,
      saveLoading: false,
    };
  },

  //GABARITO_UPDATE
  [constants.GABARITO_UPDATE.ACTION]: (state) => {
    return { ...state, error: false, saveLoading: true };
  },
  [constants.GABARITO_UPDATE.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, saveLoading: false, message };
  },
  [constants.GABARITO_UPDATE.SUCCESS]: (state: IGabaritosState) => {
    return {
      ...state,
      error: false,
      saveLoading: false,
    };
  },

  // GABARITO_SEND_EMAIL
  [constants.GABARITO_SEND_EMAIL.ACTION]: (state) => {
    return { ...state, error: false, sendEmailLoading: true };
  },
  [constants.GABARITO_SEND_EMAIL.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, sendEmailLoading: false, message };
  },
  [constants.GABARITO_SEND_EMAIL.SUCCESS]: (state: IGabaritosState) => {
    return {
      ...state,
      error: false,
      sendEmailLoading: false,
    };
  },
  // GABARITO_UPDATE_ANSWERS
  [constants.GABARITO_UPDATE_ANSWERS.ACTION]: (state) => {
    return { ...state, error: false, updateAnswersLoading: true };
  },
  [constants.GABARITO_UPDATE_ANSWERS.FAILED]: (
    state: IGabaritosState,
    action
  ) => {
    const { message } = action;
    return { ...state, error: true, updateAnswersLoading: false, message };
  },
  [constants.GABARITO_UPDATE_ANSWERS.SUCCESS]: (state: IGabaritosState) => {
    return {
      ...state,
      error: false,
      updateAnswersLoading: false,
    };
  },
  // GABARITO_DELETE
  [constants.GABARITO_DELETE.ACTION]: (state: IGabaritosState) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.GABARITO_DELETE.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, isLoading: false, message };
  },
  [constants.GABARITO_DELETE.SUCCESS]: (state: IGabaritosState, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      gabaritos: updateAfterDelete(state, action),
    };
  },
  //GABARITO_CHANGED_NULLED
  [constants.GABARITO_CHANGED_NULLED.ACTION]: (state: IGabaritosState) => {
    return { ...state, error: false };
  },
  [constants.GABARITO_CHANGED_NULLED.FAILED]: (state: IGabaritosState, action) => {
    const { message } = action;
    return { ...state, error: true, message };
  },
  [constants.GABARITO_CHANGED_NULLED.SUCCESS]: (state: IGabaritosState, action: { gabarito: IGabaritoModule }) => {
    const gabarito = makeSequencialNumberQuestion(action.gabarito);
    return {
      ...state,
      error: false,
      gabarito
    };
  },
};

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState: IGabaritosState = {
  error: false,
  message: "",
  isLoading: false,
  saveLoading: false,
  sendEmailLoading: false,
  updateAnswersLoading: false,
  gabaritos: [],
  gabarito: {} as IGabaritoModule,
};

export default createReducer(
  initialState,
  (state: IGabaritosState, action: any) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : { ...state, isLoading: false };
  }
);
