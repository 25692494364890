import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import store from '../store'
import { notification } from 'antd'
import * as api from '../api/users'
import { constants } from '../modules/users'
import { actions as userActions } from '../modules/users'

//
// selectors
import * as usersSelectors from '../selectors/users'

function* fetchUsers(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.USERS_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.USERS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchUsersById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.USERS_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.USERS_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addUser(action) {
  try {
    const userData = {
      ...action.formData,
      active: 1
    }

    //
    // notifications
    notification.info({
      key: 'user_add',
      message: 'Adicionando Usuário'
    })

    const payload = yield call(api.add, userData)
    yield put({ type: constants.USERS_ADD.SUCCESS, payload })

    // notifications
    notification.close('user_add')
    notification.success({
      message: 'Usuário adicionado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('user_add')
    notification.error({
      message: 'Problemas ao adicionar o usuário',
      description: e.message || e
    })
    yield put({
      type: constants.USERS_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editUser(action) {
  try {
    const userData = {
      ...action.formData,
      active: 1
    }

    //
    // notifications
    notification.info({
      key: 'user_edit',
      message: 'Editando Usuário'
    })

    const payload = yield call(api.edit, userData)
    yield put({ type: constants.USERS_EDIT.SUCCESS, payload })

    // notifications
    notification.close('user_edit')
    notification.success({
      message: 'Usuário editado com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('user_edit')
    notification.error({
      message: 'Problemas ao editar o usuário',
      description: e.message || e
    })
    yield put({
      type: constants.USERS_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* activeToggleUser(action) {
  try {
    const userData = {
      active: action.active
    }

    //
    // notifications
    notification.info({
      key: 'user_edit_active',
      message: action.active ? 'Ativando Usuário' : 'Desativando Usuário'
    })

    const payload = yield call(api.activeToggle, action.id, userData)
    yield put({ type: constants.USERS_ACTIVE_TOGGLE.SUCCESS, payload })

    // notifications
    notification.close('user_edit_active')
    notification.success({
      message: action.active ? 'Usuário ativado com sucesso' : 'Usuário desativado com sucesso'
    })

    //
    // get pagination data to refresh the list corretctly
    const state = store.getState()
    const pagination = usersSelectors.pagination(state)
    yield put(userActions.fetchUsers(pagination.current_page, pagination.per_page))

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('user_edit_active')
    notification.error({
      message: action.active ? 'Problemas ao ativar o usuário' : 'Problemas ao desativar o usuário',
      description: e.message || e
    })
    yield put({
      type: constants.USERS_ACTIVE_TOGGLE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeUser(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'user_remove',
      message: 'Deletando Usuário'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.USERS_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('user_remove')
    notification.success({
      message: 'Usuário deletado com sucesso'
    })

    //
    // get pagination data to refresh the list corretctly
    const state = store.getState()
    const pagination = usersSelectors.pagination(state)
    yield put(userActions.fetchUsers(pagination.current_page, pagination.per_page))

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('user_remove')
    notification.error({
      message: 'Problemas ao deletar o usuário',
      description: e.message || e
    })
    yield put({
      type: constants.USERS_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


/**
 * Saga
 */
function* watchFetchUsers() {
  yield takeLatest(constants.USERS_FETCH.ACTION, fetchUsers)
}

function* watchFetchUsersById() {
  yield takeLatest(constants.USERS_FETCH_BY_ID.ACTION, fetchUsersById)
}

function* watchAddUser() {
  yield takeLatest(constants.USERS_ADD.ACTION, addUser)
}

function* watchEditUser() {
  yield takeLatest(constants.USERS_EDIT.ACTION, editUser)
}

function* watchActiveToggleUser() {
  yield takeLatest(constants.USERS_ACTIVE_TOGGLE.ACTION, activeToggleUser)
}

function* watchRemoveUser() {
  yield takeLatest(constants.USERS_REMOVE.ACTION, removeUser)
}




/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchUsers),
    fork(watchFetchUsersById),
    fork(watchAddUser),
    fork(watchEditUser),
    fork(watchActiveToggleUser),
    fork(watchRemoveUser)
  ])
}
