import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import * as api from "../api/dashboard";
import { constants } from "../modules/dashboard";

function* fetchDashboard(action) {
  try {
    const payload = yield call(api.fetchAll);
    yield put({ type: constants.DASHBOARD_FETCH.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.DASHBOARD_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchSalesByCourse(action) {
  try {
    const payload = yield call(api.fetchSalesByCourse, action.filters);
    yield put({
      type: constants.REPORT_FETCH_SALES_BY_COURSE.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.REPORT_FETCH_SALES_BY_COURSE.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchLastSales(action) {
  try {
    const payload = yield call(api.fetchLastSales);
    yield put({ type: constants.REPORT_FETCH_LAST_SALES.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.REPORT_FETCH_LAST_SALES.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchCourseSalesByMonth(action) {
  try {
    const payload = yield call(api.fetchCourseSalesByMonth);
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchCourseSalesByDay(action) {
  try {
    const payload = yield call(api.fetchCourseSalesByDay);
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_BY_DAY.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_BY_DAY.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchSalesPerformance(action) {
  try {
    const payload = yield call(api.fetchSalesPerformance, action.filters);
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchAllCourses(action) {
  try {
    const payload = yield call(api.fetchAllCourses);
    yield put({
      type: constants.GET_ALL_COURSES.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.GET_ALL_COURSES.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchCourseClassesByCourseTag(action) {
  try {
    const payload = yield call(api.fetchCourseClassByCourseTag, action.tag);
    yield put({
      type: constants.GET_COURSECLASS_BY_COURSE_TAG.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.GET_COURSECLASS_BY_COURSE_TAG.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

/**
 * Saga
 */
function* watchFetchSalesByCourse() {
  yield takeLatest(
    constants.REPORT_FETCH_SALES_BY_COURSE.ACTION,
    fetchSalesByCourse
  );
}

function* watchFetchdashboard() {
  yield takeLatest(constants.DASHBOARD_FETCH.ACTION, fetchDashboard);
}

function* watchFetchLastSales() {
  yield takeLatest(constants.REPORT_FETCH_LAST_SALES.ACTION, fetchLastSales);
}

function* watchFetchCourseSalesByMonth() {
  yield takeLatest(
    constants.REPORT_FETCH_COURSE_SALES_BY_MONTH.ACTION,
    fetchCourseSalesByMonth
  );
}

function* watchFetchSalesPerformance() {
  yield takeLatest(
    constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.ACTION,
    fetchSalesPerformance
  );
}

function* watchFetchCourseSalesByDay() {
  yield takeLatest(
    constants.REPORT_FETCH_COURSE_SALES_PERFORMANCE.ACTION,
    fetchCourseSalesByDay
  );
}

function* watchFetchAllCourses() {
  yield takeLatest(constants.GET_ALL_COURSES.ACTION, fetchAllCourses);
}

function* watchFetchAllCourseClassesByCourseTag() {
  yield takeLatest(
    constants.GET_COURSECLASS_BY_COURSE_TAG.ACTION,
    fetchCourseClassesByCourseTag
  );
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchSalesByCourse),
    fork(watchFetchLastSales),
    fork(watchFetchdashboard),
    fork(watchFetchCourseSalesByMonth),
    fork(watchFetchCourseSalesByDay),
    fork(watchFetchSalesPerformance),
    fork(watchFetchAllCourses),
    fork(watchFetchAllCourseClassesByCourseTag),
  ]);
}
