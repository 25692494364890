import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  TEACHER_AREA_FETCH_GOALS: createSagaAction('TEACHER_AREA_FETCH_GOALS'),
  TEACHER_AREA_STORE_GOAL: createSagaAction('TEACHER_AREA_STORE_GOAL'),
  TEACHER_AREA_REMOVE_GOAL: createSagaAction('TEACHER_AREA_REMOVE_GOAL'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchGoals: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.TEACHER_AREA_FETCH_GOALS.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  storeGoal: (url, next) => ({
    type: constants.TEACHER_AREA_STORE_GOAL.ACTION,
    url,
    next,
  }),
  removeGoal: (id, next) => ({
    type: constants.TEACHER_AREA_REMOVE_GOAL.ACTION,
    id,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // TEACHER_AREA_FETCH_GOALS
  [constants.TEACHER_AREA_FETCH_GOALS.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.TEACHER_AREA_FETCH_GOALS.SUCCESS]: (state, action) => {
    return {
      ...state,
      goals: action.payload,
      isLoading: false
    }
  },
  [constants.TEACHER_AREA_FETCH_GOALS.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // TEACHER_AREA_STORE_GOAL
  [constants.TEACHER_AREA_STORE_GOAL.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.TEACHER_AREA_STORE_GOAL.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.TEACHER_AREA_STORE_GOAL.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // TEACHER_AREA_REMOVE_GOAL
  [constants.TEACHER_AREA_REMOVE_GOAL.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.TEACHER_AREA_REMOVE_GOAL.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.TEACHER_AREA_REMOVE_GOAL.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
