import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/question'
import { constants } from '../modules/question'
import { actions as questionActions } from '../modules/question'
import { actions as classActions } from '../modules/classes'

function* fetchQuestions(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.QUESTION_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchQuestionsTwo(action) {
  try {
    const payload = yield call(api.fetchAllTwo, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.QUESTION_FETCH_TWO.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_TWO.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* fetchQuestionsById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.QUESTION_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchQuestionsByCourse(action) {
  try {
    const payload = yield call(api.fetchByCourse, action.id)
    yield put({ type: constants.QUESTION_FETCH_BY_COURSE.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_BY_COURSE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addQuestion(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_add',
      message: 'Adicionando questão'
    })

    const questionPayload = {
      ativa: action.formData.ativa,
      codigo: action.formData.codigo,
      enunciado: action.formData.enunciado,
      img_enunciado: action.formData.img_enunciado,
      video_embed: action.formData.video_embed,
      audio_embed: action.formData.audio_embed,
      questao_comentada: action.formData.questao_comentada,
      formula: action.formData.formula,
      formula_imagem: action.formData.formula_imagem,
      families: action.formData.families,
      modules_id: action.formData.modules_id,
      chapters_id: action.formData.chapters_id,
      exams_id: action.formData.exams_id,
      video_quality: action.formData.video_quality,
      difficulty_level: action.formData.difficulty_level,
    }

    //
    // adding question main data
    const question = yield call(api.add, questionPayload)

    //
    // adding answers
    for (let answer of action.formData.respostas) {
      answer.id_questao = question.id_questao
      yield call(api.addAnswer, answer)
    }

    yield put({ type: constants.QUESTION_ADD.SUCCESS, question })

    // notifications
    notification.close('question_add')
    notification.success({
      message: 'Questão adicionada com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_add')
    notification.error({
      message: 'Problemas ao adicionar o questão',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editQuestion(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_edit',
      message: 'Editando questão'
    })

    const questionPayload = {
      id: action.formData.id,
      ativa: action.formData.ativa,
      codigo: action.formData.codigo,
      enunciado: action.formData.enunciado,
      img_enunciado: action.formData.img_enunciado,
      video_embed: action.formData.video_embed,
      audio_embed: action.formData.audio_embed,
      questao_comentada: action.formData.questao_comentada,
      formula: action.formData.formula,
      formula_imagem: action.formData.formula_imagem,
      families: action.formData.families,
      modules_id: action.formData.modules_id,
      chapters_id: action.formData.chapters_id,
      exams_id: action.formData.exams_id,
      video_quality: action.formData.video_quality,
      difficulty_level: action.formData.difficulty_level
    }

    //
    // adding question main data
    yield call(api.edit, questionPayload)

    //
    // adding answers
    for (let answer of action.formData.respostas) {
      answer.id_questao = action.formData.id
      yield call(api.editAnswer, answer)
    }

    const payload = yield call(api.edit, action.formData)
    yield put({ type: constants.QUESTION_EDIT.SUCCESS, payload })

    // notifications
    notification.close('question_edit')
    notification.success({
      message: 'Questão editada com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_edit')
    notification.error({
      message: 'Problemas ao editar a questão',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeQuestion(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_remove',
      message: 'Deletando Questão'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.QUESTION_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('question_remove')
    notification.success({
      message: 'Questão deletada com sucesso'
    })
    yield put(questionActions.fetchQuestions())
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_remove')
    notification.error({
      message: 'Problemas ao deletar a questão',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* linkToClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_link',
      message: 'Adicionando questões'
    })

    const payload = yield call(api.linkToClass, action.classId, action.questions)
    yield put({ type: constants.QUESTION_BULK_LINK_TO_CLASS.SUCCESS, payload })

    //
    // refresh current class
    yield put(classActions.fetchClassesById(action.classId))

    // notifications
    notification.close('question_link')
    notification.success({
      message: 'Questões adicionadas com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_link')
    notification.error({
      message: 'Problemas ao adicionar as questões',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_BULK_LINK_TO_CLASS.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* verifyNewQuestionCode(action) {
  try {
      const payload = yield call(api.verifyCodeNewQuestion, action.code)

      yield put({ type: constants.QUESTION_VERIFY_NEW_CODE.SUCCESS, payload })

      action.next && action.next(true)
  } catch(e) {
    yield put({
      type: constants.QUESTION_VERIFY_NEW_CODE.FAILED,
      error: true,
      errorMessage: e.message || e.errorMessage || e
    })

    action.next && action.next(false)
  }
}

//families
function* fetchAllFamiliesQuestions(action) {
  try {
    const payload = yield call(api.fetchAllFamiliesQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

//tags
function* fetchAllTagsQuestions(action) {
  try {
    const payload = yield call(api.fetchAllTagsQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

//tags
function* fetchAllExamsQuestions(action) {
  try {
    const payload = yield call(api.fetchAllExamsQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

//modules
function* fetchModulesByFamilies(action) {
  try {
    const payload = yield call(api.fetchModulesByFamilies, action.families)
    yield put({ type: constants.MODULES_FETCH_BY_FAMILIES, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.MODULES_FETCH_BY_FAMILIES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

//modules
function* fetchChaptersByModules(action) {
  try {
    const payload = yield call(api.fetchChaptersByModules, action.modules)
    yield put({ type: constants.CHAPTERS_FETCH_BY_MODULES, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.CHAPTERS_FETCH_BY_MODULES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
function* watchFetchQuestions() {
  yield takeLatest(constants.QUESTION_FETCH.ACTION, fetchQuestions)
}

function* watchFetchQuestionsTwo() {
  yield takeLatest(constants.QUESTION_FETCH_TWO.ACTION, fetchQuestionsTwo)
}

function* watchFetchQuestionsById() {
  yield takeLatest(constants.QUESTION_FETCH_BY_ID.ACTION, fetchQuestionsById)
}

function* watchFetchQuestionsByCourse() {
  yield takeLatest(constants.QUESTION_FETCH_BY_COURSE.ACTION, fetchQuestionsByCourse)
}

function* watchAddQuestion() {
  yield takeLatest(constants.QUESTION_ADD.ACTION, addQuestion)
}

function* watchEditQuestion() {
  yield takeLatest(constants.QUESTION_EDIT.ACTION, editQuestion)
}

function* watchRemoveQuestion() {
  yield takeLatest(constants.QUESTION_REMOVE.ACTION, removeQuestion)
}

function* watchLinkToClass() {
  yield takeLatest(constants.QUESTION_BULK_LINK_TO_CLASS.ACTION, linkToClass)
}

function* watchNewQuestionCode() {
  yield takeLatest(constants.QUESTION_VERIFY_NEW_CODE.ACTION, verifyNewQuestionCode)
}

function* watchfetchAllFamiliesQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.ACTION, fetchAllFamiliesQuestions);
}

function* watchfetchAllTagsQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.ACTION, fetchAllTagsQuestions);
}

function* watchfetchAllExamsQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.ACTION, fetchAllExamsQuestions);
}

function* watchFetchModulesByFamlies() {
  yield takeLatest(constants.MODULES_FETCH_BY_FAMILIES.ACTION, fetchModulesByFamilies)
}

function* watchFetchChaptersByModules() {
  yield takeLatest(constants.CHAPTERS_FETCH_BY_MODULES.ACTION, fetchChaptersByModules)
}


/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchQuestions),
    fork(watchFetchQuestionsTwo),
    fork(watchFetchQuestionsById),
    fork(watchFetchQuestionsByCourse),
    fork(watchAddQuestion),
    fork(watchEditQuestion),
    fork(watchRemoveQuestion),
    fork(watchLinkToClass),
    fork(watchNewQuestionCode),
    fork(watchfetchAllFamiliesQuestions),
    fork(watchfetchAllTagsQuestions),
    fork(watchfetchAllExamsQuestions),
    fork(watchFetchModulesByFamlies),
    fork(watchFetchChaptersByModules)
  ])
}
