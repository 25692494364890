import { all, fork, put, takeLatest } from 'redux-saga/effects'

import { constants } from '../modules/filters'

function* addFilters(action) {
  try {
    yield put({ type: constants.SET_FILTERS.SUCCESS, filtros: action.filters })
  } catch (e) {
    yield put({
      type: constants.SET_FILTERS.FAILED,
      error: e.message,
    })
  }
}

function* removeFilters() {
  try {
    yield put({ type: constants.REMOVE_FILTERS.SUCCESS })
  } catch (e) {
    yield put({
      type: constants.REMOVE_FILTERS.FAILED,
      error: e.message,
    })
  }
}
/**
 * Saga
 */
function* watchAddFilters() {
  yield takeLatest(constants.SET_FILTERS.ACTION, addFilters)
}

function* watchRemoveFilters() {
  yield takeLatest(constants.REMOVE_FILTERS.ACTION, removeFilters)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchAddFilters),
    fork(watchRemoveFilters),
  ])
}
