import request from '../../shared/request'

export function add(formData) {
  return request(`v1/services/s3`, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  })
}
