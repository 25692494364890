import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAll(currentPage, itemsPerPage, filters = {}) {
  return request('v1/services/questoes/lista/simples', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
      append: ['aulas'],
      rand: Math.random()
    },
    filters: generateFilters(filters)
  })
}

export function fetchAllTwo(currentPage, itemsPerPage, filters = {}) {
  return request('v1/services/questoes/lista', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
    },
    filters: generateFilters(filters)
  })
}

export function fetchById(id) {
  return request(`v1/crud/questao/${id}`, {
    qsParams: {
      append: ['respostas', 'aulas', 'families', 'modules_id', 'chapters', 'tags', 'exams']
    }
  })
}

export function fetchByCourse(id) {
  return request(`v1/services/questoes/${id}/aulas-associadas`)
}

export function add(formData) {
  return request(`v1/crud/questao`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function addAnswer(formData) {
  return request(`v1/crud/questaoresposta`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function edit(formData) {
  return request(`v1/crud/questao/${formData.id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function editAnswer(formData) {
  return request(`v1/crud/questaoresposta/${formData.id_resposta}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/crud/questao/${id}`, {
    method: 'DELETE'
  })
}

export function linkToClass(classId, questions) {
  return request(`v1/services/questions/lecture/${classId}/link_questions`, {
    method: 'POST',
    body: JSON.stringify(questions)
  })
}

export function verifyCodeNewQuestion(cod) {
  return request(`v1/services/questoes/code/${cod}`, {
    method: 'POST',
  })
}

export function fetchAllFamiliesQuestions() {
  return request('v1/services/family/all');
};

export function fetchAllTagsQuestions() {
  return request('v1/tags/all');
};

export function fetchAllExamsQuestions() {
  return request('v1/exams-type');
};

export function fetchModulesByFamilies(families) {
  return request(`v1/modules/all?families=${families}`);
}

export function fetchChaptersByModules(modules) {
  return request(`v1/chapter/all?modules=${modules}`);
}
