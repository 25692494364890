import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import { notification } from "antd";
import * as api from "../api/classes";
import { constants } from "../modules/classes";
import { actions as classActions } from "../modules/classes";

function* fetchClasses(action) {
  try {
    const payload = yield call(
      api.fetchAll,
      action.currentPage,
      action.itemsPerPage,
      action.filters
    );
    yield put({ type: constants.CLASS_FETCH.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchClassesSimple(action) {
  try {
    const payload = yield call(api.fetchAllSimple);
    yield put({ type: constants.CLASS_FETCH_SIMPLE.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_FETCH_SIMPLE.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchClassesById(action) {
  try {
    const payload = yield call(api.fetchById, action.id);
    yield put({ type: constants.CLASS_FETCH_BY_ID.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* addClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: "class_add",
      message: "Adicionando Aula",
    });
    //
    // format videos payload
    action.formData.videos = action.formData.videos
      ? action?.formData?.videos?.map((video) => ({
          video_embed: video.video_embed,
          video_embed_tmp: video.video_embed,
        }))
      : [];

    // const classData = {
    //   ...action.formData,
    //   scope: 'admin',
    //   active: 1
    // }

    let payload = [];

    payload = yield call(api.add, action.formData);
    yield put({ type: constants.CLASS_ADD.SUCCESS, payload });

    // notifications
    notification.close("class_add");
    notification.success({
      message: "Aula adicionada com sucesso",
    });
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("class_add");
    notification.error({
      message: "Problemas ao adicionar o aula",
      description: e.message || e,
    });
    yield put({
      type: constants.CLASS_ADD.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* editClass(action) {
  try {
    //
    // format videos payload
    action.formData.videos = action?.formData?.videos?.map((video) => ({
      video_embed: video.video_embed,
      video_embed_tmp: video.video_embed,
    }));

    const classData = {
      ...action.formData,
      scope: "admin",
      active: 1,
    };
    //
    // notifications
    notification.info({
      key: "class_edit",
      message: "Editando Aula",
    });

    const payload = yield call(api.edit, classData);
    yield put({ type: constants.CLASS_EDIT.SUCCESS, payload });

    // notifications
    notification.close("class_edit");
    notification.success({
      message: "Aula editada com sucesso",
    });
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("class_edit");
    notification.error({
      message: "Problemas ao editar a aula",
      description: e.message || e,
    });
    yield put({
      type: constants.CLASS_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* removeClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: "class_remove",
      message: "Deletando Aula",
    });

    const payload = yield call(api.remove, action.id);
    yield put({ type: constants.CLASS_REMOVE.SUCCESS, payload });

    // notifications
    notification.close("class_remove");
    notification.success({
      message: "Aula deletada com sucesso",
    });
    yield put(classActions.fetchClasses());
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("class_remove");
    notification.error({
      message: "Problemas ao deletar o aula",
      description: e.message || e,
    });
    yield put({
      type: constants.CLASS_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchClassesByCourseId(action) {
  try {
    const payload = yield call(api.fetchByCourseId, action.courseId);
    yield put({ type: constants.CLASS_FETCH_BY_COURSE_ID.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_FETCH_BY_COURSE_ID.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* getClassUsageDetailsById(action) {
  try {
    const payload = yield call(api.getClassUsageDetailsById, action.classId);
    yield put({
      type: constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* cloneClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: "clone_class",
      message: "Duplicando Aula",
    });

    let payload = yield call(api.cloneClass, action.id.id_aula);
    payload.id_aula = action.id.id_caula;
    payload.cod_aula = action.id.cod_aula;
    const clonedClassName = payload.titulo;
    payload.titulo = action.id.titulo;
    payload.questioes = action.id.questoes.length;

    yield put({ type: constants.CLASS_CLONE.SUCCESS, payload });

    // notifications
    notification.close("clone_class");
    notification.success({
      message: `${clonedClassName} - criado com sucesso.`,
    });

    action.next && action.next(payload);
  } catch (e) {
    // notifications
    notification.close("clone_class");
    notification.error({
      message: "Problemas ao duplicar a aula",
      description: e.message || e,
    });
    yield put({
      type: constants.CLASS_CLONE.FAILED,
      error: true,
      errorMessage: e.message || e,
      class: action.id,
    });
  }
}

/**
 * Saga
 */
function* watchFetchClasses() {
  yield takeLatest(constants.CLASS_FETCH.ACTION, fetchClasses);
}
function* watchFetchClassesSimple() {
  yield takeLatest(constants.CLASS_FETCH_SIMPLE.ACTION, fetchClassesSimple);
}

function* watchFetchClassesById() {
  yield takeLatest(constants.CLASS_FETCH_BY_ID.ACTION, fetchClassesById);
}

function* watchAddClass() {
  yield takeLatest(constants.CLASS_ADD.ACTION, addClass);
}

function* watchEditClass() {
  yield takeLatest(constants.CLASS_EDIT.ACTION, editClass);
}

function* watchRemoveClass() {
  yield takeLatest(constants.CLASS_REMOVE.ACTION, removeClass);
}

function* watchFetchClassesByCourseId() {
  yield takeLatest(
    constants.CLASS_FETCH_BY_COURSE_ID.ACTION,
    fetchClassesByCourseId
  );
}
function* watchGetClassUsageDetailsById() {
  yield takeLatest(
    constants.CLASS_FETCH_USAGE_DETAILS_BY_ID.ACTION,
    getClassUsageDetailsById
  );
}
function* watchCloneClass() {
  yield takeLatest(constants.CLASS_CLONE.ACTION, cloneClass);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchClasses),
    fork(watchFetchClassesSimple),
    fork(watchFetchClassesById),
    fork(watchAddClass),
    fork(watchEditClass),
    fork(watchRemoveClass),
    fork(watchFetchClassesByCourseId),
    fork(watchGetClassUsageDetailsById),
    fork(watchCloneClass),
  ]);
}
