export function formValidation(error, touched) {
  if (error && touched) {
    return 'error'
  } else {
    if (!touched) {
      return ''
    } else {
      return 'success'
    }
  }
}