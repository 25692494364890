import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  USERS_FETCH: createSagaAction('USERS_FETCH'),
  USERS_FETCH_BY_ID: createSagaAction('USERS_FETCH_BY_ID'),
  USERS_ADD: createSagaAction('USERS_ADD'),
  USERS_EDIT: createSagaAction('USERS_EDIT'),
  USERS_ACTIVE_TOGGLE: createSagaAction('USERS_ACTIVE_TOGGLE'),
  USERS_REMOVE: createSagaAction('USERS_REMOVE'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchUsers: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.USERS_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchUsersById: (id, next) => ({
    type: constants.USERS_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  addUser: (formData, next) => ({
    type: constants.USERS_ADD.ACTION,
    formData,
    next
  }),
  editUser: (formData, next) => ({
    type: constants.USERS_EDIT.ACTION,
    formData,
    next
  }),
  activeUserToggle: (id, active, next) => ({
    type: constants.USERS_ACTIVE_TOGGLE.ACTION,
    id,
    active,
    next
  }),
  removeUser: (id, next) => ({
    type: constants.USERS_REMOVE.ACTION,
    id,
    next
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // USERS_FETCH
  [constants.USERS_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.USERS_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // USERS_FETCH_BY_ID
  [constants.USERS_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      user: { ...action.payload },
      isLoading: false
    }
  },
  [constants.USERS_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // USERS_ADD
  [constants.USERS_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.USERS_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // USERS_EDIT
  [constants.USERS_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.USERS_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // USERS_ACTIVE_TOGGLE
  [constants.USERS_ACTIVE_TOGGLE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_ACTIVE_TOGGLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.USERS_ACTIVE_TOGGLE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // USERS_REMOVE
  [constants.USERS_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.USERS_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.USERS_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
