import request from '../../shared/request';
import { FormattedSendReportType, FormType } from '../../pages/reportGeneratorCFP/common';


export const createReport = (formData: FormattedSendReportType) => {
  return request(`v1/report/massive/cfp`, {
    method: 'POST',
    body: JSON.stringify(formData),
  });
};

export const fetchScoreInformation = (filters: {
  courseCategoryId: number;
  typeReportId: number;
  categoryReportId: number;
}) => {
  return request(
    `v1/crud/scoreperformanceinformation?get=all&filtro[id_course_category]=${filters.courseCategoryId}&filtro[id_type_report]=${filters.typeReportId}&filtro[id_category_report]=${filters.categoryReportId}
  `);
};

export const fetchGeneralText = (filters: {
  typeReportId: number;
  categoryReportId: number;
}) => {
  return request(`v1/crud/scoreperformancetext?get=all&filtro[id_type_report]=${filters.typeReportId}&filtro[id_category_report]=${filters.categoryReportId}`);
};

export const updateGeneralText = (id: number, content: string) => {
  return request(`v1/crud/scoreperformancetext/${id}`, {
    method: 'POST',
    body: JSON.stringify({
      'content': content
    }),
  })
};

export const fetchScoreInformationById = (id: number) => {
  return request(`v1/crud/scoreperformanceinformation/${id}`);
};

export const createScoreInformation = (formData = {}) => {
  return request(`v1/crud/scoreperformanceinformation`, {
    method: 'POST',
    body: JSON.stringify(formData),
  });
};

export const updateScoreInformation = (id: number, formData: FormType) => {
  return request(`v1/crud/scoreperformanceinformation/${id}`, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
};
