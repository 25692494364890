import auth from "./auth";
import files from "./files";
import users from "./users";
import course from "./course";
import access from "./access";
import labels from "./labels";
import classes from "./classes";
import filters from "./filters";
import payment from "./payment";
import student from "./student";
import discount from "./discount";
import question from "./question";
import saleLink from "./saleLink";
import affiliate from "./affiliate";
import dashboard from "./dashboard";
import courseClass from "./courseClass";
import mentorships from "./mentorships";
import certification from "./certification";
import teacherArea from "./teacherArea";
import massiveDelete from "./massiveDelete";
import crm from "./crm";
import gabarito from "./gabarito";
import lead from "./lead";
import quizQuestions from "./quizQuestions";
import reportGenerator from "./reportGenerator";
import descriptionStudent from "./descriptionStudent";

export default {
  auth,
  files,
  users,
  access,
  course,
  labels,
  classes,
  filters,
  payment,
  student,
  discount,
  question,
  saleLink,
  affiliate,
  dashboard,
  courseClass,
  mentorships,
  certification,
  teacherArea,
  massiveDelete,
  crm,
  gabarito,
  lead,
  quizQuestions,
  reportGenerator,
  descriptionStudent,
};
