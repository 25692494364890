import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/massiveDelete'
import { constants } from '../modules/massiveDelete'


function* deleteAllSelectedValues(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'deleting_values',
      message: 'Apagando valores selecionados'
    })

    const payload = yield call(api.deleteAllSelectedValues, action.formData)
    yield put({ type: constants.DELETE_ALL_SELECTED_VALUES.SUCCESS, payload })

    // notifications
    notification.close('deleting_values')
    notification.success({
      message: 'Valores apagados com sucesso'
    })
    action.next && action.next(payload)
  } catch (e) {
    // notifications
    notification.close('deleting_values')
    notification.error({
      message: 'Problemas ao apagar valores',
      description: e.message || e
    })
    yield put({
      type: constants.DELETE_ALL_SELECTED_VALUES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
function* watchDeleteAllSelectedValues() {
  yield takeLatest(constants.DELETE_ALL_SELECTED_VALUES.ACTION, deleteAllSelectedValues)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchDeleteAllSelectedValues),
  ])
}
