import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  QUESTION_FETCH: createSagaAction('QUESTION_FETCH'),
  QUESTION_FETCH_TWO: createSagaAction('QUESTION_FETCH_TWO'),
  QUESTION_FETCH_BY_ID: createSagaAction('QUESTION_FETCH_BY_ID'),
  QUESTION_FETCH_BY_COURSE: createSagaAction('QUESTION_FETCH_BY_COURSE'),
  QUESTION_ADD: createSagaAction('QUESTION_ADD'),
  QUESTION_EDIT: createSagaAction('QUESTION_EDIT'),
  QUESTION_REMOVE: createSagaAction('QUESTION_REMOVE'),
  QUESTION_BULK_LINK_TO_CLASS: createSagaAction('QUESTION_BULK_LINK_TO_CLASS'),
  QUESTION_VERIFY_NEW_CODE: createSagaAction('QUESTION_VERIFY_NEW_CODE'),
  QUESTIONS_FETCH_All_FAMILIES_QUESTIONS: createSagaAction('QUESTIONS_FETCH_All_FAMILIES_QUESTIONS'),
  QUESTIONS_FETCH_All_TAGS_QUESTIONS: createSagaAction('QUESTIONS_FETCH_All_TAGS_QUESTIONS'),
  QUESTIONS_FETCH_All_EXAMS_QUESTIONS: createSagaAction('QUESTIONS_FETCH_All_EXAMS_QUESTIONS'),
  MODULES_FETCH_BY_FAMILIES: createSagaAction('MODULES_FETCH_BY_FAMILIES'),
  CHAPTERS_FETCH_BY_MODULES: createSagaAction('CHAPTERS_FETCH_BY_MODULES'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchQuestions: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.QUESTION_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchQuestionsTwo: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.QUESTION_FETCH_TWO.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchQuestionsById: (id, next) => ({
    type: constants.QUESTION_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  fetchQuestionsByCourse: (id, next) => ({
    type: constants.QUESTION_FETCH_BY_COURSE.ACTION,
    id,
    next
  }),
  addQuestion: (formData, next) => ({
    type: constants.QUESTION_ADD.ACTION,
    formData,
    next
  }),
  editQuestion: (formData, next) => ({
    type: constants.QUESTION_EDIT.ACTION,
    formData,
    next
  }),
  removeQuestion: (id, next) => ({
    type: constants.QUESTION_REMOVE.ACTION,
    id,
    next
  }),
  linkToClass: (classId, questions, next) => ({
    type: constants.QUESTION_BULK_LINK_TO_CLASS.ACTION,
    classId,
    questions,
    next
  }),
  verifyNewQuestionCode: (code, next) => ({
    type: constants.QUESTION_VERIFY_NEW_CODE.ACTION,
    code,
    next
  }),
  fetchAllFamiliesQuestions: (success, error) => ({
    type: constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.ACTION,
    success,
    error
  }),
  fetchAllTagsQuestions: (success, error) => ({
    type: constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.ACTION,
    success,
    error
  }),
  fetchAllExamsQuestions: (success, error) => ({
    type: constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.ACTION,
    success,
    error
  }),
  fetchModulesByFamilies: (families, next) => ({
    type: constants.MODULES_FETCH_BY_FAMILIES.ACTION,
    families,
    next
  }),
  fetchChaptersByModules: (modules, next) => ({
    type: constants.CHAPTERS_FETCH_BY_MODULES.ACTION,
    modules,
    next
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // QUESTION_FETCH
  [constants.QUESTION_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    const pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination,
      isLoading: false
    }
  },
  [constants.QUESTION_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_FETCH
  [constants.QUESTION_FETCH_TWO.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_FETCH_TWO.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    const pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination,
      isLoading: false
    }
  },
  [constants.QUESTION_FETCH_TWO.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_FETCH_BY_ID
  [constants.QUESTION_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      question: { ...action.payload },
      isLoading: false
    }
  },
  [constants.QUESTION_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_FETCH_BY_COURSE
  [constants.QUESTION_FETCH_BY_COURSE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_FETCH_BY_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      related_questions: { ...action.payload },
      isLoading: false
    }
  },
  [constants.QUESTION_FETCH_BY_COURSE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // QUESTION_ADD
  [constants.QUESTION_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.questionServiceReturn,
      isLoading: false
    }
  },
  [constants.QUESTION_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // QUESTION_EDIT
  [constants.QUESTION_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.questionServiceReturn,
      isLoading: false
    }
  },
  [constants.QUESTION_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_REMOVE
  [constants.QUESTION_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.QUESTION_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_BULK_LINK_TO_CLASS
  [constants.QUESTION_BULK_LINK_TO_CLASS.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.QUESTION_BULK_LINK_TO_CLASS.SUCCESS]: (state) => {
    return {
      ...state,
      // ...action.payload,
      isLoading: false
    }
  },
  [constants.QUESTION_BULK_LINK_TO_CLASS.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // QUESTION_VERIFY_NEW_CODE
  [constants.QUESTION_VERIFY_NEW_CODE.ACTION]: state => {
    return {
      ...state,
      newQuestionCodeLoading: true,
    }
  },
  [constants.QUESTION_VERIFY_NEW_CODE.SUCCESS]: (state, action) => {
    return {
      ...state,
      newQuestionCodeLoading: false,
      ...action.payload,
    }
  },
  [constants.QUESTION_VERIFY_NEW_CODE.FAILED]: (state, action) => {
    return {
      ...state,
      newQuestionCodeLoading: false,
      errorMessage: action.errorMessage,
    }
  },
  // FAMILY
  [constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.FAILED]: (state) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      fetchAllFamiliesQuestions: [...action.payload],
      ...action.payload,
    };
  },
  // TAGS
  [constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.FAILED]: (state) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      fetchAllTagsQuestions: [...action.payload],
      ...action.payload,
    };
  },
  // EXAMS
  [constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.FAILED]: (state) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      fetchAllExamsQuestions: [...action.payload],
      ...action.payload,
    };
  },
  // MODULES_FETCH_BY_FAMILIES
  [constants.MODULES_FETCH_BY_FAMILIES.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.MODULES_FETCH_BY_FAMILIES.SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchAllModulesQuestions: [...action.payload],
      isLoading: false
    }
  },
  [constants.MODULES_FETCH_BY_FAMILIES.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  // CHAPTERS_FETCH_BY_MODULES
  [constants.CHAPTERS_FETCH_BY_MODULES.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.CHAPTERS_FETCH_BY_MODULES.SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchAllChaptersQuestions: [...action.payload],
      isLoading: false
    }
  },
  [constants.CHAPTERS_FETCH_BY_MODULES.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  newQuestionCodeLoading: false,
  family: []
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
