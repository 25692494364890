import request from "../../shared/request";
import { generateFilters } from "../../helpers";

export function fetchAll(currentPage, itemsPerPage, filters = {}) {
  return request("v1/crud/aula", {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
      append: ["questoes"],
    },
    filters: generateFilters(filters),
  });
}

export function fetchAllSimple() {
  return request("v1/services/aulas/lista/simples");
}

export function fetchById(id) {
  return request(`v1/crud/aula/${id}`, {
    qsParams: {
      append: ["questoes", "videos"],
    },
  });
}

export function addPdf(formData) {
  return request(`v1/crud/aula`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function getClassesById(id) {
  return request(`v1/services/aulas/${id}/response`,{
    method: "GET",
  });
}

export function getVideosAulas(id){
  return request(`v1/crud/aula/${id}?append[]=questoes&append[]=videos`,{
    method: "GET",
  });
}

export function getLinks() {
  return request(`v1/services/aulas/links`);
}

export function getBooks() {
  return request(`v1/services/aulas/books`);
}

export function add(formData) {
  return request(`v1/services/aulas/post`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function edit(formData) {
  return request(`v1/services/aulas/${formData.id}`, {
    method: "POST",
    body: JSON.stringify(formData),
  });
}

export function remove(id) {
  return request(`v1/crud/aula/${id}`, {
    method: "DELETE",
  });
}

export function fetchByCourseId(id) {
  return request(`v1/services/cursos/lectures/${id}`, {
    qsParams: {
      append: ["questoes"],
    },
  });
}

export function getClassUsageDetailsById(id) {
  return request(`v1/services/aulas/${id}/cursos-associadas`);
}

export function selectClassType() {
  return request(`v1/services/class-types`);
}

export function cloneClass(id) {
  return request(`v1/services/aulas/clone/${id}`, { method: "PUT" });
}
