export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'info',
      text: 'NEW',
    },
    id_access: 'all',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Educação'],
    id_access: [1,2,3,4,5,6],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Cursos',
    to: '/cursos',
    icon: 'cil-notes',
    id_access: 2,
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Aulas',
    to: '/aulas',
    icon: 'cil-book',
    id_access: 3,
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Questões',
    to: '/questoes',
    icon: 'cil-folder-open',
    id_access: 4,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Alunos',
    to: '/aluno',
    icon: 'cil-user',
    id_access: 5,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Leads',
    to: '/leads',
    icon: 'cil-user',
    id_access: 103,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Turmas',
    to: '/courseclass',
    icon: 'cil-group',
    id_access: 6
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Gabarito CFP',
    to: '/gabarito-cfp',
    icon: 'cil-notes',
    id_access: 102
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Calendário de Provas',
    to: '/scheduled-tests',
    icon: 'cil-calendar',
    id_access: 105
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Operação'],
    id_access: [7,8,9]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Descontos',
    to: '/descontos',
    icon: 'cil-tags',
    id_access: 7
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Pagamentos',
    to: '/pagamentos',
    icon: 'cil-dollar',
    id_access: 8,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Afiliados',
    to: '/afiliados',
    icon: 'cil-user',
    id_access: 9
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['CRM'],
    id_access: [100, 101]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Prospectar',
    to: '/crm-prospections',
    icon: 'cil-dollar',
    id_access: 100
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Agentes de Venda',
    to: '/crm-sales-agents',
    icon: 'cil-group',
    id_access: 101,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Painel Leads Convertidos',
    to: '/crm-leads-panel',
    icon: 'cil-speedometer',
    id_access: 101,
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['App Quiz'],
    id_access: [104],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Questões',
    to: '/quiz-questions',
    icon: 'cil-folder-open',
    id_access: 104
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Gerador de Relatório'],
    id_access: [106],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'CFP Revisão PNG',
    to: '/report-generator-cfp-weekly/png',
    icon: 'cil-file',
    id_access: 106
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'CFP Revisão PDF',
    to: '/report-generator-cfp-weekly/pdf',
    icon: 'cil-file',
    id_access: 106
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'CFP Simulado',
    to: '/report-generator-cfp-simulated/pdf',
    icon: 'cil-file',
    id_access: 106
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Sistema'],
    id_access: [10]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-lock-locked',
    id_access: 10
  }
]
