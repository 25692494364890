import menu from '../components/layout/_nav';
import routes from '../routes';
import config from '../config';

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            'Authorization': 'Bearer ' + user.token ,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept':'application/json'
        };
    } else {
        return {};
    }
}

export function allowedMenus (profile, accesses) {
  const allowedsAccesses = accesses?.map(access => access.id_access) ?? [];
  // Garantindo que usuários antigos professores, continuaram acessando normalmente.
  if (profile?.includes('professor')) {
    allowedsAccesses.push(1);
    allowedsAccesses.push(104);
    allowedsAccesses.push(105);
  }

  const allowedsNav = menu.filter(item => {
    if (item.id_access === 'all') {
      return true;
    } else if (Array.isArray(item.id_access)) {
      let allowedFather = false;

      for (let i of item.id_access) {
        allowedFather = allowedsAccesses.includes(i);
        if (allowedFather) break;
      }

      return allowedFather;
    } else {
      return allowedsAccesses.includes(item.id_access);
    }
  });

  return allowedsNav;
}

export function allowedRoutes (profile, accesses) {
  const allowedAccesses = accesses?.map(access => access.id_access) ?? [];
  if (profile?.includes('professor')) {
    allowedAccesses.push(1);
    allowedAccesses.push(104);
    allowedAccesses.push(105);
  };

  const allowedRoutes = menu
    .filter(item => (item.id === 'all' || allowedAccesses.includes(item.id_access)))
    .map(item => item.to);

  const customRoutes = routes.filter(route => {
    const initialPath = `/${route.path.split('/')[1]}`;
    return (allowedRoutes.includes(initialPath) || route.path === '/dashboard');
  });

  return customRoutes;
}

export function shownMenu (nav) {
  return nav.filter(item => {
    let envHiddenMenu = false;
    try {
      envHiddenMenu = config.hiddenMenu.includes(item.name);
    } catch {
      // Any exception i gonna ignore, cause probabily the enviroment variable was passed incorrect
    }

    return typeof (item.show === 'undefined' || item.show)
    && !envHiddenMenu;
  });
}
