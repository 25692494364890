import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { constants } from '../modules/certification'
import { notification } from 'antd'
import * as api from '../api/certification'


function* fetchAllStudentsCertifications(action) {
  try {
    const payload = yield call(api.fetchAllCertifications, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.CERTIFICATION_GET_ALL.SUCCESS, payload })

    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.CERTIFICATION_GET_ALL.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchCertificationsById(action) {
  try {
    const payload = yield call(api.fetchCertificationsById, action.id)
    yield put({ type: constants.CERTIFICATION_FETCH_BY_ID.SUCCESS, payload })

    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.CERTIFICATION_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* addStudentAvaliation(action) {
  notification.info({
    key: 'certification_add',
    message: 'Salvando certificações'
  })

  try {
    const payload = yield call(api.addStudentCertification, action.payload.id_usuario, action.payload.formData)
    yield put({ type: constants.CERTIFICATION_ADD_AVALIATION.SUCCESS, payload })

    notification.close('certification_add')
    notification.success({
      message: 'Certificações salvas'
    })
    action.next && action.next(payload)
  } catch (e) {
    notification.close('certification_add')
    notification.error({
      message: 'Problemas ao salvar as certificações',
      description: e.message || e
    })
    yield put({
      type: constants.CERTIFICATION_ADD_AVALIATION.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
function* watchFetchUsers() {
  yield takeLatest(constants.CERTIFICATION_GET_ALL.ACTION, fetchAllStudentsCertifications)
}

function* watchAddStudents() {
  yield takeLatest(constants.CERTIFICATION_ADD_AVALIATION.ACTION, addStudentAvaliation)
}

function* watchFetchCertificationsById() {
  yield takeLatest(constants.CERTIFICATION_FETCH_BY_ID.ACTION, fetchCertificationsById)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchUsers),
    fork(watchAddStudents),
    fork(watchFetchCertificationsById)
  ])
}
