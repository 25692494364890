import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  AddContactProps,
  constants,
  FetchHistoric,
  FetchStudentBySalesManProps,
  FetchStudentsProps,
  LinkSelectedStudentsProps,
  LinkStudentsToSalesmanProps
} from '../../redux/modules/crm';

import * as api from '../api/crm';
import { SuccessCallback, ErrorCallback } from '../../shared/models/CallbackType';
import { ITypeContact } from '../../shared/models/crm/ITypeContact';

type ActionProps = {
  type: string;
  success?: SuccessCallback;
  error?: ErrorCallback;
};

type ActionFetchStudentsBySalesmanProps = ActionProps & {
  params: FetchStudentBySalesManProps;
};

type ActionFetchHistoric = ActionProps & {
  params: FetchHistoric;
};

type ActionFetchStudentsProps = ActionProps & {
  params: FetchStudentsProps;
};

type ActionFetchSalesmanProps = ActionProps;

type ActionAddContactProps = ActionProps & {
  formData: AddContactProps;
};

type ActionLinkStudentsToSalesmanProps = ActionProps & {
  formData: LinkStudentsToSalesmanProps;
};

type ActionRemoveLinkByStudentProps = ActionProps & {
  studentId: number;
};

type ActionLinkSelectedStudents = ActionProps & {
  formData: LinkSelectedStudentsProps[];
  eventType: 'store' | 'update';
};

// Functions
function* fetchStudents (action: ActionFetchStudentsProps) {
  try {
    const payload = yield call(api.fetchStudents, action.params);
    yield put({ type: constants.CRM_FETCH_STUDENTS.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.CRM_FETCH_STUDENTS.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* fetchSalesmans (action: ActionFetchSalesmanProps) {
  try {
    const payload = yield call(api.fetchSalesmans);
    yield put({ type: constants.CRM_FETCH_SALESMANS.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {

    const message = e.message || e;

    yield put({
      type: constants.CRM_FETCH_SALESMANS.FAILED,
      error: true,
      message: e.message || e
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};


function* fetchStudentsBySalesman (action: ActionFetchStudentsBySalesmanProps) {
  try {
    const payload = yield call(api.fetchStudentsBySalesman, action.params);
    yield put({ type: constants.CRM_FETCH_STUDENTS_BY_SALESMAN.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.CRM_FETCH_STUDENTS_BY_SALESMAN.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* fetchHistoric (action: ActionFetchHistoric) {
  try {
    const payload = yield call(api.fetchHistoric, action.params);
    yield put({ type: constants.CRM_FETCH_HISTORIC.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {

    const message = e.message || e;

    yield put({
      type: constants.CRM_FETCH_HISTORIC.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* fetchTypeContacts (action: {
  type: string;
  callback?: (payload: ITypeContact) => void;
}) {
  try {
    const payload = yield call(api.fetchTypeContacts);
    yield put({ type: constants.CRM_FETCH_TYPE_CONTACTS.SUCCESS, payload });

    action.callback && action.callback(payload);
  } catch (e) {
    yield put({
      type: constants.CRM_FETCH_TYPE_CONTACTS.FAILED,
      error: true
    });
  }
};

function* findStudent (action) {
  try {
    const payload = yield call(api.findStudent, action.id);
    yield put({ type: constants.CRM_FIND_STUDENT.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {

    const message = e.message || e;

    yield put({
      type: constants.CRM_FIND_STUDENT.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};


function* addContact (action: ActionAddContactProps) {
  try {
    notification.info({
      key: 'contact_add',
      message: 'Adicionando o contato ao histórico'
    });

    const payload = yield call(api.addContact, action.formData);
    yield put({ type: constants.CRM_ADD_CONTACT.SUCCESS, payload });

    notification.close('contact_add')
    notification.success({
      message: 'O contato foi adicionado com sucesso !'
    });
    action.success && action.success(payload);
  } catch (e) {
    notification.close('contact_add');
    const message = e.message || e;

    yield put({
      type: constants.CRM_ADD_CONTACT.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* linkStudentsBySalesman (action: ActionLinkStudentsToSalesmanProps) {
  try {
    notification.info({
      key: 'link_to_salesman',
      message: 'Vinculando alunos ao vendedor'
    });
    const payload = yield call(api.linkStudentsBySalesman, action.formData);
    yield put({ type: constants.CRM_LINK_STUDENS_BY_SALESMAN.SUCCESS, payload });

    notification.close('link_to_salesman')
    notification.success({
      message: 'Alunos vinculados com sucesso !'
    });
    action.success && action.success(payload);
  } catch (e) {
    notification.close('link_to_salesman')

    const message = e.message || e;

    yield put({
      type: constants.CRM_LINK_STUDENS_BY_SALESMAN.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* removeLinkByStudent(action: ActionRemoveLinkByStudentProps) {
  notification.info({
    key: 'remove_link_by_student',
    message: 'Removendo vinculo do vendedor com o contato'
  });

  try {
    const payload = yield call(api.removeLinkByStudent, action.studentId);
    yield put({ type: constants.CRM_REMOVE_LINK_BY_STUDENT.SUCCESS, studentId: action.studentId });

    notification.close('remove_link_by_student')
    notification.success({
      message: 'Vinculo removido'
    });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.CRM_REMOVE_LINK_BY_STUDENT.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

function* linkSelectedStudents(action: ActionLinkSelectedStudents) {
  notification.info({
    key: 'link_to_salesman',
    message: 'Vinculando alunos ao vendedor'
  });

  try {
    const payload = yield call(api.linkSelectedStudents, action.formData, action.eventType);
    yield put({ type: constants.CRM_LINK_SELECTED_STUDENTS.SUCCESS, payload });

    notification.close('link_to_salesman')
    notification.success({
      message: 'Alunos vinculados com sucesso !'
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close('link_to_salesman')

    const message = e.message || e;

    yield put({
      type: constants.CRM_LINK_SELECTED_STUDENTS.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });

    action.error && action.error();
  }
};

/**
 * Saga
*/
function* watchFetchStudents() {
  yield takeLatest(constants.CRM_FETCH_STUDENTS.ACTION, fetchStudents);
};

function* watchFetchSalesman() {
  yield takeLatest(constants.CRM_FETCH_SALESMANS.ACTION, fetchSalesmans);
};

function* watchFetchStudentsBySalesman() {
  yield takeLatest(constants.CRM_FETCH_STUDENTS_BY_SALESMAN.ACTION, fetchStudentsBySalesman);
};

function* watchFetchHistoric() {
  yield takeLatest(constants.CRM_FETCH_HISTORIC.ACTION, fetchHistoric);
};

function* watchFetchTypeContacts() {
  yield takeLatest(constants.CRM_FETCH_TYPE_CONTACTS.ACTION, fetchTypeContacts);
};

function* watchFindStudent() {
  yield takeLatest(constants.CRM_FIND_STUDENT.ACTION, findStudent);
};

function* watchAddContact() {
  yield takeLatest(constants.CRM_ADD_CONTACT.ACTION, addContact);
};

function* watchLinkStudentsToSalesman() {
  yield takeLatest(constants.CRM_LINK_STUDENS_BY_SALESMAN.ACTION, linkStudentsBySalesman);
};

function* watchRemoveLinkByStudent() {
  yield takeLatest(constants.CRM_REMOVE_LINK_BY_STUDENT.ACTION, removeLinkByStudent);
};

function* watchLinkSelectedStudents() {
  yield takeLatest(constants.CRM_LINK_SELECTED_STUDENTS.ACTION, linkSelectedStudents);
}

export function* rootSaga() {
  yield all([
    fork(watchFetchStudents),
    fork(watchFetchStudentsBySalesman),
    fork(watchAddContact),
    fork(watchFetchHistoric),
    fork(watchFetchTypeContacts),
    fork(watchFindStudent),
    fork(watchLinkStudentsToSalesman),
    fork(watchFetchSalesman),
    fork(watchRemoveLinkByStudent),
    fork(watchLinkSelectedStudents)
  ]);
};
