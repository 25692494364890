import request from '../../shared/request';
import { generateFilters } from '../../helpers';
import { IGabaritoModule } from '../../shared/models/gabarito/IGabaritoModule';
import { FormDataAnswers, FormDataProps } from '../modules/gabarito';

export const fetchAll = (_filters = {}) => {
  const filters = JSON.stringify({}) !== JSON.stringify(_filters)
    ? generateFilters(_filters, 'service')
    : null;

  return request('v1/services/gabaritos', {
    filters
  });
};

export const findById = (id: number): Promise<IGabaritoModule> => (
  request(`v1/services/gabaritos/${id}`)
);

export const store = (formData: FormDataProps): Promise<boolean> => (
  request('v1/services/gabaritos', {
    method: 'POST',
    body: JSON.stringify(formData)
  })
);

export const update = (formData: FormDataProps): Promise<boolean> => (
  request(`v1/services/gabaritos/${formData.id}`, {
    method: 'PUT',
    body: JSON.stringify(formData)
  })
);

export const sendEmail = (gabaritoId: number, emailType: string): Promise<boolean> => (
  request('v1/services/gabaritos/send-email', {
    method: 'POST',
    body: JSON.stringify({
      gabaritoId,
      emailType
    })
  })
);


export const updateQuestionAnswers = (gabaritoId: number, formData: FormDataAnswers[]): Promise<boolean> => (
  request(`v1/services/gabaritos/filled/${gabaritoId}`, {
    method: 'PUT',
    body: JSON.stringify({questions: formData})
  })
);

export const deleteGabarito = (gabaritoId: number): Promise<boolean> => (
  request(`v1/services/gabaritos/${gabaritoId}`, {
    method: 'DELETE',
  })
);

export const changedNulled = (questionId: number): Promise<IGabaritoModule> => (
  request(`v1/services/gabaritos/question-toggle-nulled/${questionId}`, {
    method: 'PUT',
  })
);
