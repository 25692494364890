import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const propTypes = {
  errors: PropTypes.array.isRequired,
};

const ApiErrors = ({ errors }) => {
  return (
    <ul className="api-errors">
        {
          errors.map((error, index) => {
            return (<li key={index}>{error}</li>)
          })
        }
    </ul>
  );
};

ApiErrors.propTypes = propTypes;

export default ApiErrors;
