import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import * as api from '../api/labels'
import { constants } from '../modules/labels'

function* fetchLabels(action) {
  try {
    const payload = yield call(api.fetchAll, action)
    yield put({ type: constants.LABELS_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.LABELS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

/**
 * Saga
 */
 function* watchFetchLabels() {
  yield takeLatest(constants.LABELS_FETCH.ACTION, fetchLabels)
}

/**
 * Export the root saga by forking all available sagas.
 */
 export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchLabels),
  ])
}
