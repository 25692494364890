import request from '../../shared/request'
import { generateFilters } from '../../helpers'


export function updateNFs(id_order, nf_numero, nf_url) {
  return request('v1/services/orders/nf/'+id_order+'/update', {
    method: 'POST',
    body: JSON.stringify({
      "nf_numero": nf_numero,
      "nf_url": nf_url
      })
  })
}

export function fetchOrders(currentPage, itemsPerPage, filters) {
  return request('v1/services/payments', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters || {})
  })
}


export function fetchByIdCurso(id_curso) {
  return request(`v1/services/turmas/findAll`, {
    qsParams: {
      id_curso: id_curso
    }
  })
}


export function cancelPayment(id_order) {
  return request(`v1/services/orders/cancel/${id_order}`, {
    method: "PUT",
    body: JSON.stringify({
      id_order
    })
  })
}


export function changePaymentMethod(id_order, data) {
  return request(`v1/services/orders/${id_order}`, {
    method: "PUT",
    body: JSON.stringify(data)
  })
}


export function syncContaAzul(data) {
  return request(`v1/contaazul/sync`, {
    method: "POST",
    body: JSON.stringify(data)
  })
}