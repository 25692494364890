import { createSagaAction } from "../../shared/sagas";
import { createReducer } from "../../shared/reducers";
import { IScorePerformance } from "../../shared/models/reportCFP/IScorePerformance";
import { FormType, FormattedSendReportType, TypeReportType } from "../../pages/reportGeneratorCFP/common";
import { IGeneralText } from "../../shared/models/reportCFP/IGeneralText";


export const constants = {
  REPORT_GENERATE_CREATE: createSagaAction('REPORT_GENERATE_CREATE'),
  REPORT_GENERATOR_FETCH_SCORE_INFORMATION: createSagaAction('REPORT_GENERATOR_FETCH_SCORE_INFORMATION'),
  REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID: createSagaAction('REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID'),
  REPORT_GENERATOR_CREATE_SCORE_INFORMATION: createSagaAction('REPORT_GENERATOR_CREATE_SCORE_INFORMATION'),
  REPORT_GENERATOR_UPDATE_SCORE_INFORMATION: createSagaAction('REPORT_GENERATOR_UPDATE_SCORE_INFORMATION'),
  REPORT_GENERATOR_CURRENT_TAB: 'REPORT_GENERATOR_CURRENT_TAB',
  REPORT_GENERATOR_FETCH_GENERAL_TEXT: createSagaAction('REPORT_GENERATOR_FETCH_GENERAL_TEXT'),
  REPORT_GENERATOR_UPDATE_GENERAL_TEXT: createSagaAction('REPORT_GENERATOR_UPDATE_GENERAL_TEXT'),
};

export type StateType = {
  isCreatingReport: boolean;
  isFetchingScoreInformation: boolean;
  isCreatingScoreInformation: boolean;
  isFetchingReportGeneralText: boolean;
  isUpdatingReportGeneralText: boolean;
  report?: {};
  scoreInformations: Array<IScorePerformance>;
  scoreInformation?: IScorePerformance;
  reportGeneralText?: IGeneralText;
  currentTab?: TypeReportType;
};

export const actions = {
  createReport: (
    formData: FormattedSendReportType,
    next?: (error?: unknown, payload?:unknown) => void
  ) => ({
    type: constants.REPORT_GENERATE_CREATE.ACTION,
    formData,
    next
  }),
  fetchScoreInformation: (filters: {
    courseCategoryId: number;
    typeReportId: number;
    categoryReportId: number;
  }) => ({
    type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.ACTION,
    filters
  }),
  fetchScoreInformationById: (id: number, next?: (error?: unknown, values?: unknown) => void) => ({
    type: constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.ACTION,
    id,
    next
  }),
  fetchReportGeneralText: (id_type_report: number, id_category_report: number, next?: (error?: unknown, values?: unknown) => void) => ({
    type: constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.ACTION,
    filters: { typeReportId: id_type_report, categoryReportId: id_category_report },
    next
  }),
  updateReportGeneralText: (
    id: number,
    formData: FormType,
    next?: (error?: unknown, values?: unknown) => void
  ) => ({
    type: constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.ACTION,
    id,
    formData,
    next
  }),
  createScoreInformation: (formData: FormType) => ({
    type: constants.REPORT_GENERATOR_CREATE_SCORE_INFORMATION.ACTION,
    formData,
  }),
  updateScoreInformation: (
    id: number,
    formData: FormType,
    next?: (error?: unknown, values?: unknown) => void
  ) => ({
    type: constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.ACTION,
    id,
    formData,
    next
  }),
  setCurrentTab: (currentTab: TypeReportType) => ({
    type: constants.REPORT_GENERATOR_CURRENT_TAB,
    currentTab,
  }),
};

export const ACTION_HANDLERS = {
  // REPORT_GENERATE_CREATE
  [constants.REPORT_GENERATE_CREATE.ACTION]: (state: StateType) => {
    return {...state};
  },
  [constants.REPORT_GENERATE_CREATE.SUCCESS]: (state: StateType, action: {
    payload: any
  }) => {
    return {...state};
  },
  [constants.REPORT_GENERATE_CREATE.FAILED]: (state: StateType) => {
    return { ...state };
  },
  // REPORT_GENERATOR_FETCH_SCORE_INFORMATION
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.ACTION]: (state: StateType) => ({
    ...state,
    isFetchingScoreInformation: true,
    error: false
  }),
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isFetchingScoreInformation: false
  }),
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION.SUCCESS]: (state: StateType, action: {
    payload: IScorePerformance[]
  }) => ({
    ...state,
    error:false,
    isFetchingScoreInformation: false,
    scoreInformations: action.payload
  }),
  // REPORT_GENERATOR_FETCH_GENERAL_TEXT
  [constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.ACTION]: (state: StateType) => ({
    ...state,
    isFetchingGeneralText: true,
    error: false
  }),
  [constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isFetchingGeneralText: false
  }),
  [constants.REPORT_GENERATOR_FETCH_GENERAL_TEXT.SUCCESS]: (state: StateType, action: {
    payload: IGeneralText[]
  }) => ({
    ...state,
    error:false,
    isFetchingGeneralText: false,
    reportGeneralText: action.payload
  }),
  //REPORT_GENERATOR_UPDATE_GENERAL_TEXT
  [constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.ACTION]: (state: StateType) => ({
    ...state,
    error: false,
    isUpdatingReportGeneralText: true,
  }),
  [constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isUpdatingReportGeneralText: false,
  }),
  [constants.REPORT_GENERATOR_UPDATE_GENERAL_TEXT.SUCCESS]: (state: StateType) => ({
    ...state,
    error: false,
    isUpdatingReportGeneralText: false
  }),
  // REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.ACTION]: (state: StateType) => ({
    ...state,
    isFetchingScoreInformation: true,
    error: false
  }),
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isFetchingScoreInformation: false
  }),
  [constants.REPORT_GENERATOR_FETCH_SCORE_INFORMATION_BY_ID.SUCCESS]: (state: StateType, action: {
    payload: IScorePerformance,
  }) => ({
    ...state,
    error:false,
    isFetchingScoreInformation: false,
    scoreInformation: action.payload,
  }),
  //REPORT_GENERATOR_CREATE_SCORE_INFORMATION
  [constants.REPORT_GENERATOR_CREATE_SCORE_INFORMATION.ACTION]: (state: StateType) => ({
    ...state,
    error: false,
    isCreatingScoreInformation: true,
  }),
  [constants.REPORT_GENERATOR_CREATE_SCORE_INFORMATION.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isCreatingScoreInformation: false,
  }),
  [constants.REPORT_GENERATOR_CREATE_SCORE_INFORMATION.SUCCESS]: (state: StateType) => ({
    ...state,
    error: false,
    isCreatingScoreInformation: false
  }),
  //REPORT_GENERATOR_UPDATE_SCORE_INFORMATION
  [constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.ACTION]: (state: StateType) => ({
    ...state,
    error: false,
    isFetchingScoreInformation: true,
  }),
  [constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.FAILED]: (state: StateType) => ({
    ...state,
    error: true,
    isFetchingScoreInformation: false,
  }),
  [constants.REPORT_GENERATOR_UPDATE_SCORE_INFORMATION.SUCCESS]: (state: StateType) => ({
    ...state,
    error: false,
    isFetchingScoreInformation: false
  }),
  [constants.REPORT_GENERATOR_CURRENT_TAB]: (state: StateType, action: {
    currentTab: TypeReportType
  }) => {
    return {
      ...state,
      currentTab: action.currentTab,
    };
  }
};

export const initialState: StateType = {
  isCreatingReport: false,
  isFetchingScoreInformation: false,
  isCreatingScoreInformation: false,
  isFetchingReportGeneralText: false,
  isUpdatingReportGeneralText: false,
  report: undefined,
  scoreInformations: [],
  scoreInformation: undefined,
  reportGeneralText: undefined,
  currentTab: undefined,
};

export default createReducer(
  initialState,
  (state: StateType, action: any) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : { ...state, isLoading: false };
  }
);
