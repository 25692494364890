export function generateFilters(filters, conditions) {
  let qsFilter = '&'
  Object.keys(filters).forEach(filterKey => {

    if (conditions === 'service') {
      return qsFilter += filters[filterKey] ? `${filterKey}=${filters[filterKey]}&` : ''
    }

    if (conditions === 'allor') {
      qsFilter += filters[filterKey] ? `filtroOr[${filterKey}]=${filters[filterKey]}&` : ''
    } else {
      if (filters[filterKey] === 'null') {
        qsFilter += `filtro[${filterKey}]=${filters[filterKey]}&`
        qsFilter += `filtroOp[${filterKey}]=is`
      }
      else {
        qsFilter += filters[filterKey] ? `filtro[${filterKey}]=${filters[filterKey]}&` : ''
      }
    }
  })

  return qsFilter
}
