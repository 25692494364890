import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAllCertifications(currentPage, itemsPerPage, filters) {
  return request('v1/services/certification', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
    },
    filters: generateFilters(filters, 'student')
  })
}

export function fetchCertificationsById(id) {
  return request(`v1/services/certification/${id}`)
}

export function addStudentCertification(id, formData) {
  return request(`v1/services/certification`, {
    method: 'POST',
    body: JSON.stringify({ certifications: formData, id_usuario: id}),
  })
}
