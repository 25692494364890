import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import * as api from "../api/saleLink";
import { constants } from "../modules/saleLink";

function* fetchAllSaleLink(action) {
  try {
    const payload = yield call(api.fetchAllSaleLink);
    yield put({ type: constants.FECH_ALL_SALE_LINK.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.FECH_ALL_SALE_LINK.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchSalesDetails(action) {
  try {
    const payload = yield call(api.fetchSalesDetails);
    yield put({ type: constants.FECH_SALES_DETAILS.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.FECH_SALES_DETAILS.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

/**
 * Saga
 */
function* watchFetchAllSaleLink() {
  yield takeLatest(constants.FECH_ALL_SALE_LINK.ACTION, fetchAllSaleLink);
}
function* watchFetchSalesDetails() {
  yield takeLatest(constants.FECH_SALES_DETAILS.ACTION, fetchSalesDetails);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([fork(watchFetchAllSaleLink), fork(watchFetchSalesDetails)]);
}
