import _ from 'lodash'
import { createSelector } from 'reselect'

export const courseClasses = state => _.sortBy(_.get(state, 'courseClass.data', []), 'nome')
export const courseClassesToComboboxStudent = state => {
  return _.sortBy(_.get(state, 'courseClass.data', []), 'nome')
}
export const courseClassDetails = state => _.get(state, 'courseClass.courseClassDetails', [])
export const courseClassesAlunos = state => _.get(state, 'courseClass.courseClassDetails.alunos', [])
export const pagination = state => _.get(state, 'courseClass.pagination', {})
export const getUserById = (state) => id =>
  createSelector(
    [courseClasses],
    courseClass => {
      return _.find(courseClass, { id: parseInt(id) })
    }
  )(state)
export const isLoading = state => _.get(state, 'courseClass.isLoading', false)
export const error = state => _.get(state, 'courseClass.error', [])
export const errorMessage = state => _.get(state, 'courseClass.errorMessage', [])
