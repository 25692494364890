import request from '../../shared/request'

export function editTeacherClassesList(formData) {
  return request(`v1/services/professor/materias`, {
    method: 'POST',
    body: JSON.stringify(formData.aulas)
  })
}

export function getTeacherAvailability(id) {
  return request(`v1/services/professor/disponibilidade/${id}`)
}

export function editTeacherAvailability(formData) {
  return request(`v1/services/professor/disponibilidade/${formData.id}`, {
    method: 'POST',
    body: JSON.stringify(formData.horarios)
  })
}

export function getClassesByTeacherId() {
  return request(`v1/services/professor/materias`)
}

export function acceptMentorship(id) {
  return request(`v1/services/professor/mentoria/atender/${id}`, {
    method: 'POST',
  })
}

export function cancelMentorship(id) {
  return request(`v1/services/professor/mentoria/cancelar/${id}`, {
    method: 'POST',
  })
}

export function getMyMentorship() {
  return request(`v1/services/professor/mentoria/listar`)
}

export function getAllMentorship() {
  return request(`v1/services/professor/mentoria/listar/todas`)
}
