import { createSagaAction } from '../../shared/sagas';
import { createReducer } from '../../shared/reducers'
import { ILeadState } from '../../shared/models/lead/ILeadState';
import { ILead } from '../../shared/models/lead/ILead';
import { ITypeLead } from '../../shared/models/lead/ITypeLead';

import { FilterProps, PaginationProps } from '../../pages/lead/List/types';
import { IPagination } from '../../shared/models/IPagination';
import { IPaginationData } from '../../shared/models/lead/IPaginationData';
import { IConvertedLead, IConvertedTotals } from '../../shared/models/lead/IConvertedLead';

// ------------------------------------
// Types
// ------------------------------------

export type SuccessCallback = ((payload: unknown) => void) | null;
export type ErrorCallback = (() => void) | null;

export type FetchAllProps = {
  filters: FilterProps;
  pagination: PaginationProps;
};

export type TotalConvertedType = {
  start_date?: string;
  end_date?: string;
  typeId: number;
};

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  LEAD_FETCH_ALL: createSagaAction('LEAD_FETCH_ALL'),
  LEAD_FETCH_TYPE_LEAD: createSagaAction('LEAD_FETCH_TYPE_LEAD'),
  LEAD_FETCH_CONVERTED_LEAD: createSagaAction('LEAD_FETCH_CONVERTED_LEAD'),
  LEAD_FETCH_TOTALS_CONVERTED: createSagaAction('LEAD_FETCH_TOTALS_CONVERTED'),
  LEAD_FETCH_AVAILABLE_TYPES: createSagaAction('LEAD_FETCH_AVAILABLE_TYPES'),
  LEAD_STORE: createSagaAction('LEAD_STORE'),

};

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchAll: (params: FetchAllProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
    type: constants.LEAD_FETCH_ALL.ACTION,
    params,
    success,
    error,
  }),
  fetchConvertedLeads: (filters: {
    search?: string;
  } = {}, pagination: {
    currentPage?: number;
    itemsPerPage?: number
  } = {}) => ({
    type: constants.LEAD_FETCH_CONVERTED_LEAD.ACTION,
    filters,
    pagination
  }),
  fetchTotalsConverted: (filters: TotalConvertedType) => ({
    type: constants.LEAD_FETCH_TOTALS_CONVERTED.ACTION,
    filters
  }),
  fetchAvailableTypesLeads: () => ({
    type: constants.LEAD_FETCH_AVAILABLE_TYPES.ACTION
  }),
  store: (formData: {}, callback? : SuccessCallback) => ({
    type: constants.LEAD_STORE.ACTION,
    formData,
    callback
  }),
  fetchTypeLeads:(callback?: SuccessCallback) => ({
    type: constants.LEAD_FETCH_TYPE_LEAD.ACTION,
    callback
  })
};


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // LEAD_FETCH_ALL
  [constants.LEAD_FETCH_ALL.ACTION]: (state: ILeadState) => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.LEAD_FETCH_ALL.FAILED]: (state: ILeadState) => {
    return { ...state, error: false, isLoading: false }
  },
  [constants.LEAD_FETCH_ALL.SUCCESS]: (state: ILeadState, action: { payload: IPaginationData }) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      leads: [...action.payload.data],
      pagination: {
        current_page: action.payload?.current_page,
        last_page: action.payload?.last_page
      }
    }
  },
  // LEAD_FETCH_AVAILABLE_TYPES
  [constants.LEAD_FETCH_AVAILABLE_TYPES.ACTION]: (state: ILeadState) => {
    return { ...state, error: false }
  },
  [constants.LEAD_FETCH_AVAILABLE_TYPES.FAILED]: (state: ILeadState) => {
    return { ...state, error: false }
  },
  [constants.LEAD_FETCH_AVAILABLE_TYPES.SUCCESS]: (state: ILeadState, action: { payload }) => {
    return {
      ...state,
      error: false,
      availableTypeLeads: action.payload
    }
  },

  // LEAD_FETCH_CONVERTED_LEAD
  [constants.LEAD_FETCH_CONVERTED_LEAD.ACTION]: (state: ILeadState) => {
    return { ...state, error: false, isFetchingDataPanel: true }
  },
  [constants.LEAD_FETCH_CONVERTED_LEAD.FAILED]: (state: ILeadState) => {
    return { ...state, error: false, isFetchingDataPanel: false }
  },
  [constants.LEAD_FETCH_CONVERTED_LEAD.SUCCESS]: (state: ILeadState, action: { payload: IConvertedLead }) => {
    return {
      ...state,
      error: false,
      isFetchingDataPanel: false,
      convertedLeads: {
        data: action.payload?.data ?? [],
        current_page: action.payload?.current_page,
        last_page:  action.payload?.last_page
      }
    }
  },

  // LEAD_FETCH_TOTALS_CONVERTED
  [constants.LEAD_FETCH_TOTALS_CONVERTED.ACTION]: (state: ILeadState) => {
    return { ...state, error: false, isFetchingTotalsPanel: true }
  },
  [constants.LEAD_FETCH_TOTALS_CONVERTED.FAILED]: (state: ILeadState) => {
    return { ...state, error: false, isFetchingTotalsPanel: false }
  },
  [constants.LEAD_FETCH_TOTALS_CONVERTED.SUCCESS]: (state: ILeadState, action: { payload: IConvertedTotals }) => {
    return {
      ...state,
      error: false,
      isFetchingTotalsPanel: false,
      convertedTotals: {
        total: action.payload.total,
        totalWeek: action.payload.totalWeek,
        totalMonth: action.payload.totalMonth,
        totalToday: action.payload.totalToday,
        totalPercent: action.payload.totalPercent,
        monthPercent: action.payload.monthPercent,
        weekPercent: action.payload.weekPercent,
        todayPercent: action.payload.todayPercent
      }
    }
  },

  // LEAD_STORE
  [constants.LEAD_STORE.ACTION]: (state: ILeadState) => {
    return {
      ...state,
      error: false,
      isSaving: true
    }
  },
  [constants.LEAD_STORE.SUCCESS]: (state: ILeadState) => {
    return {
      ...state,
      error: false,
      isSaving: false,
    }
  },
  [constants.LEAD_STORE.FAILED]: (state: ILeadState) => {
    return {
      ...state,
      error: true,
      isSaving: false,
    }
  },

  // LEAD_FETCH_TYPE_LEAD
  [constants.LEAD_FETCH_TYPE_LEAD.ACTION]: (state: ILeadState) => {
    return {
      ...state,
      error: false
    }
  },
  [constants.LEAD_FETCH_TYPE_LEAD.SUCCESS]: (state: ILeadState, action: {
    payload: ITypeLead[]
  }) => {
    return {
      ...state,
      error: false,
      typeLeads: [ ...action.payload ]
    }
  },
  [constants.LEAD_FETCH_TYPE_LEAD.FAILED]: (state: ILeadState) => {
    return {
      ...state,
      error: true
    }
  },
};

export const initialState: ILeadState = {
  error: false,
  message: '',
  isLoading: false,
  isSaving: false,
  isFetchingDataPanel: false,
  isFetchingTotalsPanel: false,
  leads: [],
  convertedLeads: {
    data: [],
    current_page: 0,
    last_page: 0,
  },
  convertedTotals: {
    totalWeek: 0,
    totalMonth: 0,
    totalToday: 0,
    total: 0,
    monthPercent: 0,
    todayPercent: 0,
    totalPercent: 0,
    weekPercent: 0,
  },
  typeLeads: [],
  availableTypeLeads: [],
  lead: {} as ILead,
  pagination: {} as IPagination
};


export default createReducer(initialState, (state: ILeadState, action: any) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, isLoading: false }
});
