import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import { notification } from "antd";
import * as api from "../api/course";
import { constants } from "../modules/course";
import { actions as courseActions } from "../modules/course";

function* fetchLessonsById(action) {
  try {
    const payload = yield call(
      api.fetchLessonsById,
      action.idCourse,
      action.filters
    );

    yield put({
      type: constants.COURSE_FETCH_LESSONS_BY_ID.SUCCESS,
      payload
    });

    action.next && action.next(undefined, {
      payload
    });
  } catch(e) {
    yield put({
      type: constants.COURSE_FETCH_LESSONS_BY_ID.FAILED
    });
    action.next && action.next({
      e
    });
  }
}

function* fetchAllCourses(action) {
  try {
    const payload = yield call(api.fetchAllCourses, action.filters ?? {});
    yield put({
      type: constants.COURSES_FETCH_ALL.SUCCESS,
      payload,
      test: !!(action?.report_cfp_test)
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSES_FETCH_ALL.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchCourses(action) {
  try {
    const payload = yield call(
      api.fetchAll,
      action.currentPage,
      action.itemsPerPage,
      action.filters
    );
    payload.data.map(
      (item) => (item.presencial = item.presencial ? true : false)
    );
    payload.data.map(
      (item) => (item.bl_exibir_no_site = item.bl_exibir_no_site ? true : false)
    );
    yield put({ type: constants.COURSES_FETCH.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSES_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchCoursesById(action) {
  try {
    const payload = yield call(api.fetchById, action.id);
    yield put({ type: constants.COURSES_FETCH_BY_ID.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSES_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* addCourse(action) {
  try {
    const courseData = {
      ...action.formData,
    };

    //
    // notifications
    notification.info({
      key: "course_add",
      message: "Adicionando Curso",
    });

    const payload = yield call(api.add, courseData);
    yield put({ type: constants.COURSES_ADD.SUCCESS, payload });

    // notifications
    notification.close("course_add");
    notification.success({
      message: "Curso adicionado com sucesso",
    });
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("course_add");
    notification.error({
      message: "Problemas ao adicionar o curso",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_ADD.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* editCourse(action) {
  try {
    const courseData = {
      ...action.formData,
      scope: "admin",
      active: 1,
    };

    //
    // notifications
    notification.info({
      key: "course_edit",
      message: "Editando Curso",
    });

    const payload = yield call(api.edit, courseData);
    yield put({ type: constants.COURSES_EDIT.SUCCESS, payload });

    // notifications
    notification.close("course_edit");
    notification.success({
      message: "Curso editado com sucesso",
    });
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("course_edit");
    notification.error({
      message: "Problemas ao editar o curso",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* removeCourse(action) {
  try {
    //
    // notifications
    notification.info({
      key: "course_remove",
      message: "Deletando Curso",
    });

    const payload = yield call(api.remove, action.id);
    yield put({ type: constants.COURSES_REMOVE.SUCCESS, payload });

    // notifications
    notification.close("course_remove");
    notification.success({
      message: "Curso deletado com sucesso",
    });
    yield put(courseActions.fetchCourses());
    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("course_remove");
    notification.error({
      message: "Problemas ao deletar o curso",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* enroll(action) {
  try {
    //
    // notifications
    notification.info({
      key: "course_enroll",
      message: "Matriculando",
    });

    const payload = yield call(api.enroll, action.enrollments);
    yield put({ type: constants.COURSES_ENROLL.SUCCESS, payload });

    // notifications
    notification.close("course_enroll");
    notification.success({
      message: "Matrículas efetuadas com sucesso",
    });

    action.next && action.next(payload);
  } catch (e) {
    // notifications
    notification.close("course_enroll");
    notification.error({
      message: "Problemas ao efetuar as matrículas",
      description: e.message || e,
    });

    yield put({
      type: constants.COURSES_ENROLL.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* unEnroll(action) {
  try {
    //
    // notifications
    notification.info({
      key: "course_enroll",
      message: "Desmatriculando",
    });

    const payload = yield call(api.unEnroll, action.courseId, action.studentId);
    yield put({ type: constants.COURSES_UNENROLL.SUCCESS, payload });

    // notifications
    notification.close("course_enroll");
    notification.success({
      message: "Desmatrícula efetuada com sucesso",
    });

    action.next && action.next(payload);
  } catch (e) {
    // notifications
    notification.close("course_enroll");
    notification.error({
      message: "Problemas ao efetuar a desmatrícula",
      description: e.message || e,
    });

    yield put({
      type: constants.COURSES_UNENROLL.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* linkToCourseToClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: "classes_link",
      message: "Adicionando aulas",
    });

    const payload = yield call(
      api.linkCourseToClass,
      action.courseId,
      action.classes
    );

    yield put({ type: constants.COURSES_BULK_LINK_CLASS.SUCCESS, payload });

    // notifications
    notification.close("classes_link");
    notification.success({
      message: "Aulas adicionadas com sucesso",
    });

    action.next && action.next();
  } catch (e) {
    // notifications
    notification.close("classes_link");
    notification.error({
      message: "Problemas ao adicionar as aulas",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_BULK_LINK_CLASS.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* printBook(action) {
  try {
    //
    // notifications
    notification.info({
      key: "print_book",
      message: "Imprimindo livro",
    });

    let payload = yield call(api.printBook, action.course.id_curso);
    payload.id_curso = action.course.id_curso;
    payload.nome = action.course.nome;

    yield put({ type: constants.COURSES_PRINT_BOOK.SUCCESS, payload });

    // notifications
    notification.close("print_book");
    notification.success({
      message: "Livro impresso com sucesso.",
    });
    action.next && action.next(payload);
  } catch (e) {
    // notifications
    notification.close("print_book");
    notification.error({
      message: "Problemas ao imprimir o livro",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_PRINT_BOOK.FAILED,
      error: true,
      errorMessage: e.message || e,
      course: action.course,
    });
  }
}

function* cloneCourse(action) {
  try {
    //
    // notifications
    notification.info({
      key: "clone_course",
      message: "Duplicando Curso",
    });

    let payload = yield call(api.cloneCourse, action.course.id_curso);
    payload.id_curso = action.course.id_curso;
    const clonedCourseName = payload.nome;
    payload.nome = action.course.nome;

    yield put({ type: constants.COURSES_CLONE.SUCCESS, payload });

    // notifications
    notification.close("clone_course");
    notification.success({
      message: `${clonedCourseName} - criado com sucesso.`,
    });

    action.next && action.next(payload);
  } catch (e) {
    // notifications
    notification.close("clone_course");
    notification.error({
      message: "Problemas ao duplicar o curso",
      description: e.message || e,
    });
    yield put({
      type: constants.COURSES_CLONE.FAILED,
      error: true,
      errorMessage: e.message || e,
      course: action.course,
    });
  }
}

function* fetchAllFamilies(action) {
  try {
    const payload = yield call(api.fetchCourseFamilies);
    yield put({ type: constants.COURSES_FETCH_FAMILIES.SUCCESS, payload });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSES_FETCH_FAMILIES.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

/**
 * Saga
 */

function* watchFetchLessonsById() {
  yield takeLatest(constants.COURSE_FETCH_LESSONS_BY_ID.ACTION, fetchLessonsById)
}

function* watchFetchAllCourses() {
  yield takeLatest(constants.COURSES_FETCH_ALL.ACTION, fetchAllCourses);
}

function* watchFetchCourses() {
  yield takeLatest(constants.COURSES_FETCH.ACTION, fetchCourses);
}

function* watchFetchCoursesById() {
  yield takeLatest(constants.COURSES_FETCH_BY_ID.ACTION, fetchCoursesById);
}

function* watchAddCourse() {
  yield takeLatest(constants.COURSES_ADD.ACTION, addCourse);
}

function* watchEditCourse() {
  yield takeLatest(constants.COURSES_EDIT.ACTION, editCourse);
}

function* watchRemoveCourse() {
  yield takeLatest(constants.COURSES_REMOVE.ACTION, removeCourse);
}

function* watchEnroll() {
  yield takeLatest(constants.COURSES_ENROLL.ACTION, enroll);
}

function* watchUnEnroll() {
  yield takeLatest(constants.COURSES_UNENROLL.ACTION, unEnroll);
}

function* watchLinkToCourseToClass() {
  yield takeLatest(
    constants.COURSES_BULK_LINK_CLASS.ACTION,
    linkToCourseToClass
  );
}

function* watchPrintBook() {
  yield takeLatest(constants.COURSES_PRINT_BOOK.ACTION, printBook);
}

function* watchCloneCourse() {
  yield takeLatest(constants.COURSES_CLONE.ACTION, cloneCourse);
}

function* watchFetchAllFamilies() {
  yield takeLatest(constants.COURSES_FETCH_FAMILIES.ACTION, fetchAllFamilies);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchLessonsById),
    fork(watchFetchAllCourses),
    fork(watchFetchCourses),
    fork(watchFetchCoursesById),
    fork(watchAddCourse),
    fork(watchEditCourse),
    fork(watchRemoveCourse),
    fork(watchEnroll),
    fork(watchUnEnroll),
    fork(watchLinkToCourseToClass),
    fork(watchPrintBook),
    fork(watchCloneCourse),
    fork(watchFetchAllFamilies),
  ]);
}
