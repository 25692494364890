import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import { notification } from "antd";

import * as api from "../api/gabarito";
import {
  constants,
  ErrorCallback,
  FormDataProps,
  FormDataAnswers,
  SuccessCallback,
} from "../modules/gabarito";
import { IGabarito } from "../../shared/models/gabarito/IGabarito";
import { IGabaritoModule } from "../../shared/models/gabarito/IGabaritoModule";

// Types
type Callbacks = {
  success: SuccessCallback;
  error: ErrorCallback;
};

type FetchAllActionProps = Callbacks & {
  type: string;
  filters: {};
};

type FindByIdActionProps = Callbacks & {
  type: string;
  id: number;
};

type FormProps = Callbacks & {
  type: string;
  formData: FormDataProps;
};

type FormAnswer = {
  type: string;
  withoutFeedback: boolean;
  formData: FormDataAnswers[];
  gabaritoId: number;
  success: SuccessCallback;
  error: ErrorCallback;
};

type SendEmailProps = Callbacks & {
  type: string;
  gabaritoId: number;
  emailType: string;
};

type ActionChangedNulledProps = Callbacks & {
  type: string;
  questionId: number;
};

// functions
function* fetchAll(action: FetchAllActionProps) {
  try {
    const payload: IGabarito[] = yield call(api.fetchAll, action.filters);
    yield put({ type: constants.GABARITO_FETCH_ALL.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_FETCH_ALL.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });

    action.error && action.error();
  }
}

function* findById(action: FindByIdActionProps) {
  try {
    const payload: IGabaritoModule = yield call(api.findById, action.id);
    yield put({ type: constants.GABARITO_FIND_BY_ID.SUCCESS, payload });

    action.success && action.success(payload);
  } catch (e) {
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_FIND_BY_ID.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });

    action.error && action.error();
  }
}

function* store(action: FormProps) {
  notification.info({
    key: "gabarito_add",
    message: "Criando novo gabarito..",
  });

  try {
    const payload = yield call(api.store, action.formData);

    yield put({ type: constants.GABARITO_STORE.SUCCESS, payload });

    notification.close("gabarito_add");
    notification.success({
      message: "O gabarito foi criado com sucesso !",
    });
    action.success && action.success(payload);
  } catch (e) {
    notification.close("gabarito_add");
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_STORE.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });
    action.error && action.error();
  }
}

function* update(action: FormProps) {
  notification.info({
    key: "gabarito_edit",
    message: "Atualizando o gabarito..",
  });

  try {
    const payload = yield call(api.update, action.formData);
    yield put({ type: constants.GABARITO_UPDATE.SUCCESS, payload });

    notification.close("gabarito_edit");
    notification.success({
      message: 'Os dados do gabarito foram atualizados com sucesso !'
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close("gabarito_edit");
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_UPDATE.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });
    action.error && action.error();
  }
}

function* sendEmail(action: SendEmailProps) {
  notification.info({
    key: "gabarito_send_email",
    message: "Enviando resultados por email",
  });

  try {
    const payload = yield call(api.sendEmail, action.gabaritoId, action.emailType);
    yield put({ type: constants.GABARITO_SEND_EMAIL.SUCCESS, payload });

    notification.close("gabarito_send_email");
    notification.success({
      message: "Resultados enviados com sucesso !",
    });
    action.success && action.success(payload);
  } catch (e) {
    notification.close("gabarito_send_email");
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_SEND_EMAIL.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });

    action.error && action.error();
  }
}

function* updateAnswers(action: FormAnswer) {
  if (!action.withoutFeedback) {
    notification.info({
      key: "gabarito_edit",
      message: "Atualizando o gabarito..",
    });
  }

  try {
    const payload = yield call(
      api.updateQuestionAnswers,
      action.gabaritoId,
      action.formData
    );
    yield put({ type: constants.GABARITO_UPDATE.SUCCESS, payload });

    if (!action.withoutFeedback) {
      notification.close("gabarito_edit");
      notification.success({
        message: "As respostas do gabarito foram atualizadas com sucesso!",
      });
    }

    action.success && action.success(payload);
  } catch (e) {
    notification.close("gabarito_edit");
    const message = e.message || e;

    yield put({
      type: constants.GABARITO_UPDATE.FAILED,
      error: true,
      message,
    });

    notification.error({
      message,
    });
    action.error && action.error();
  }
}

function* deleteGabarito(action) {
  notification.info({
    key: "gabarito_delete",
    message: "Deletando gabarito...",
  });

  try {
    const payload = yield call(api.deleteGabarito, action.gabaritoId);
    yield put({ type: constants.GABARITO_DELETE.SUCCESS, gabaritoId: action.gabaritoId })


    notification.success({
      key: "gabarito_delete",
      message: "Gabarito deletado com sucesso!"
    })

    action.success && action.success(payload);
  } catch(e) {
    yield put({ type: constants.GABARITO_DELETE.FAILED });
    notification.error({
      key: 'gabarito_delete',
      message: e.message || e,
    })

    action.error && action.error(e.message);
  }
};

function* changedNulled(action: ActionChangedNulledProps) {
  notification.info({
    key: "gabarito_changed_nulled",
    message: "Anulando a questão...",
  });

  try {
    const payload = yield call(api.changedNulled, action.questionId);
    yield put({ type: constants.GABARITO_CHANGED_NULLED.SUCCESS, gabarito: payload });

    notification.close("gabarito_changed_nulled");
    notification.success({
      key: "gabarito_changed_nulled",
      message: "Questão anulada com sucesso!"
    })

    action.success && action.success(payload);
  } catch(e) {
    yield put({ type: constants.GABARITO_CHANGED_NULLED.FAILED });

    notification.close("gabarito_changed_nulled");
    notification.error({
      key: 'gabarito_changed_nulled',
      message: e.message || e,
    })

    action.error && action.error();
  }
};

/**
 * Saga
 */
function* watchFetchAll() {
  yield takeLatest(constants.GABARITO_FETCH_ALL.ACTION, fetchAll);
}

function* watchFindById() {
  yield takeLatest(constants.GABARITO_FIND_BY_ID.ACTION, findById);
}

function* watchStore() {
  yield takeLatest(constants.GABARITO_STORE.ACTION, store);
}

function* watchUpdate() {
  yield takeLatest(constants.GABARITO_UPDATE.ACTION, update);
}

function* watchSendEmail() {
  yield takeLatest(constants.GABARITO_SEND_EMAIL.ACTION, sendEmail);
}

function* watchUpdateAnswers() {
  yield takeLatest(constants.GABARITO_UPDATE_ANSWERS.ACTION, updateAnswers);
}

function* watchDeleteGabarito() {
  yield takeLatest(constants.GABARITO_DELETE.ACTION, deleteGabarito);
}

function* watchChangedNulled() {
  yield takeLatest(constants.GABARITO_CHANGED_NULLED.ACTION, changedNulled);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchFetchAll),
    fork(watchFindById),
    fork(watchStore),
    fork(watchUpdate),
    fork(watchSendEmail),
    fork(watchUpdateAnswers),
    fork(watchDeleteGabarito),
    fork(watchChangedNulled)
  ]);
}
