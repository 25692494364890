import request from "../../shared/request";

export function fetchAll() {
  return request("v1/services/orders/stats", {});
}

export function fetchSalesByCourse(filters) {
  return request("v1/services/dashboard/sales_by_course", {
    qsParams: {
      ...filters
    }
  });
}

export function fetchLastSales() {
  return request("v1/services/dashboard/last_sales", {});
}

export function fetchCourseSalesByMonth() {
  return request("v1/services/dashboard/course_sales_by_month", {});
}

export function fetchCourseSalesByDay() {
  return request("v1/services/dashboard/sales_by_day", {});
}

export function fetchSalesPerformance(filters) {
  return request("v1/services/dashboard/sales_performance", {
    qsParams: {
      ...filters,
    },
  });
}

export function fetchAllCourses() {
  return request("v1/services/cursos/findAll");
}

export function fetchCourseClassByCourseTag(tag_curso) {
  return request(`v1/services/turmas/findAll`, {
    qsParams: {
      tag_curso,
    },
  });
}
